import styled from 'styled-components';
import { COLORS } from './../../constants/styles';

export const FlexRowStyled = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  margin: ${({ margin }) => margin || '0'} !important;
  padding: ${({ padding }) => padding || '10px 0px'} !important;
  width: 100%;
  box-sizing: border-box;
  ${({ borderRight }) => borderRight && `border-right: 1px solid ${COLORS.borderColor}`};
  ${({ borderedTop }) => borderedTop && `border-top: 1px solid ${COLORS.borderColor}`};
  ${({ background }) => background && `background: ${COLORS.backgroundColor}`};
  ${({ fullHeight }) => fullHeight && `height: 100%`};
`;

export const PageContentContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
  padding: ${({ padding }) => padding || '0px 20px 40px 0px'};
  margin-right: -20px;
`;

export const StyledPage = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 55px);
  margin: 0px;
  padding: 20px;
  overflow: hidden;
  background: ${COLORS.backgroundColor};
  z-index: 1;
`;

export const StyledFullPageLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ contentContainerSize }) => (contentContainerSize ? 'calc(100vh - 115px)' : '100vh')};
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const StyledPageWithSidebar = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 55px);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const StyledBorderedForm = styled.div`
  border: 1px solid ${COLORS.borderColor};
  padding: 10px;
  border-radius: ${COLORS.borderRadius};
`;

export const EntryStyledCard = styled.div`
  border-radius: ${COLORS.borderRadius};
  border: 1px solid ${COLORS.borderColor};
  background: #fff;
  transition: 0.3s all;
`;

export const EntryStyledCardTitle = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${COLORS.borderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GradientBorder = styled.div`
  height: 5px;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: ${COLORS.gradientBaseColor};
  background: ${COLORS.primaryGradient};
`;

export const EntryCardButtonRow = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${COLORS.borderColor};
`;

export const StyledCard = styled.div`
  ${({ grayBackground }) =>
    !grayBackground && '-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15)'};
  ${({ grayBackground }) =>
    !grayBackground && '-moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15)'};
  ${({ grayBackground }) => !grayBackground && 'box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15)'};
  border-radius: ${COLORS.borderRadius};
  background: #fff;
  ${({ clickable }) => clickable && 'cursor: pointer'};
  transition: 0.3s all;

  &:hover {
    ${({ clickable }) => clickable && 'box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4)'};
  }
`;

export const StyledCardTitle = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${COLORS.borderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardContent = styled.div`
  padding: 10px;
  ${({ grayBackground }) => grayBackground && `background: ${COLORS.backgroundColor}`};
`;

export const WarningContainer = styled.div`
  padding: 10px;
  border: 1px solid ${COLORS.warningColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DrawerLoaderContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const FullTableContainer = styled.div`
  max-width: 90%;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: ${COLORS.borderRadius};
`;

export const CardNoTitle = styled.div`
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: ${COLORS.borderRadius};
  padding: 20px;
  margin: 20px 0px;
`;

export const GridRow = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns || '1fr 1fr'};
  margin: ${({ margin }) => margin || '0'} !important;
  padding: ${({ padding }) => padding || '10px 0px'} !important;
  width: 100%;
  box-sizing: border-box;
  ${({ justifyItems }) => `justify-items: ${justifyItems}`};
  ${({ alignItems }) => `align-items: ${alignItems}`};
`;
