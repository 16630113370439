import styled from 'styled-components';

import { COLORS } from './../../constants/styles';

export const DayGridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`;

export const DayAmountContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid ${COLORS.borderColor};
  border-right: 0;
  border-top: 0;
  ${({ amount, goalDailyHours }) => amount >= goalDailyHours && `color: ${COLORS.primaryColor};`}
`;

export const DateContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid ${COLORS.borderColor};
  background: ${({ shouldHaveBackground }) =>
    shouldHaveBackground ? COLORS.primaryColor : COLORS.backgroundColor};
  ${({ isSelectedDay }) => isSelectedDay && `background: ${COLORS.secondaryOrange}`};
  border-right: 0;
  padding: 3px 0px;
`;

export const EntryContainer = styled.div`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin: 0px 10px 20px 0px;
  box-sizing: border-box;
  border-radius: ${COLORS.borderRadius};
  background: #fff;
`;

export const EntryGridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-column-gap: 20px;
`;

export const PseudoCardHeader = styled.div`
  border-radius: ${COLORS.borderRadius};
  background: #fff;
  padding: 10px;
  margin: 20px 0px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
`;

export const WorkEntriesContentContainer = styled.div`
  display: flex;
`;

export const WorkEntriesSidebar = styled.div`
  width: 300px;
  margin: -10px 0px;
  border-right: 1px solid ${COLORS.borderColor};
`;

export const WorkEntriesContainer = styled.div`
  /* padding: 0px 15px 0px; */
  /* margin: -10px -11px -13px 0px; */
  /* background: ${COLORS.backgroundColor}; */
`;
