import styled, { keyframes, css } from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';

import { COLORS } from './../../constants/styles';

const shake = keyframes`
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  85% { transform: rotate(5deg); }
  95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

export const TimerContainer = styled.div`
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 500;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const StyledTimerAvatar = styled.div`
  background: ${({ paused }) => (paused ? '#fff' : COLORS.primaryColor)};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.3s all;
  position: relative;
  ${({ paused }) =>
    !paused &&
    css`
      animation: ${shake} 2s infinite;
    `}

  &:hover {
    background: ${({ paused }) => (paused ? '#f5f5f5' : '#3ba494')};
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
  }
`;

export const StyledCloseCircle = styled(CloseCircleFilled)`
  opacity: 0 !important;
  transition: 0.3s opacity;
  z-index: 1500;

  ${StyledTimerAvatar}:hover & {
    opacity: 1 !important;
  }

  position: absolute;
  top: -8px;
  right: -8px;
`;
