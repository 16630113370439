//Actions
export const FETCH_USERS_INFO = 'USERS/FETCH_USERS_INFO';
export const STORE_USERS_INFO = 'USERS/STORE_USERS_INFO';
export const STOP_USERS_LOADING = 'USERS/STOP_USERS_LOADING';
export const START_USERS_LOADING = 'USERS/START_USERS_LOADING';
export const ADD_USER_TO_ORG = 'USERS/ADD_USER_TO_ORG';
export const UPDATE_USER = 'USERS/UPDATE_USER';
export const DELETE_USER = 'USERS/DELETE_USER';

export const CREATE_RATE = 'USERS/CREATE_RATE';
export const UPDATE_RATE = 'USERS/UPDATE_RATE';
export const START_RATES_LOADING = 'USERS/START_RATES_LOADING';
export const STOP_RATES_LOADING = 'USERS/STOP_RATES_LOADING';

export const FETCH_USERS_FROM_QBO = 'USERS/FETCH_USERS_FROM_QBO';
export const STOP_QUICKBOOKS_LOADING = 'USERS/STOP_QUICKBOOKS_LOADING';
export const START_QUICKBOOKS_LOADING = 'USERS/START_QUICKBOOKS_LOADING';
export const STORE_STAGED_QBO = 'USERS/STORE_STAGED_QBO';
export const BULK_CREATE_FROM_QBO = 'USERS/BULK_CREATE_FROM_QBO';
export const FETCH_ITEMS_FROM_QBO = 'USERS/FETCH_ITEMS_FROM_QBO';
export const STORE_ITEMS_FROM_QBO = 'USERS/STORE_ITEMS_FROM_QBO';
export const STOP_QUICKBOOKS_ITEMS_LOADING = 'USERS/STOP_QUICKBOOKS_ITEMS_LOADING';
export const START_QUICKBOOKS_ITEMS_LOADING = 'USERS/START_QUICKBOOKS_ITEMS_LOADING';

//Reducer
const initialState = {
  users: [
    // {
    //   id: '',
    //   first_name: '',
    //   last_name: '',
    //   role_id: '',
    //   organization_id: '',
    //   link_to_photo: '',
    //   type: '',
    //   qbo_id: '',
    //   rates: [],
    //   goal_daily_hours: 8,
    //   linked_qbo_item: null
    // }
  ],
  stagedQboEmployees: [],
  qboItems: [],
  loading: false,
  ratesLoading: false,
  qboUsersLoading: false,
  qboItemsLoading: false
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_USERS_INFO:
      return {
        ...state,
        users: action.payload.users,
        loading: false,
        ratesLoading: false
      };

    case STORE_ITEMS_FROM_QBO:
      return {
        ...state,
        qboItems: action.payload.items,
        qboItemsLoading: false
      };

    case STOP_USERS_LOADING:
      return {
        ...state,
        loading: false
      };

    case START_USERS_LOADING:
      return {
        ...state,
        loading: true
      };

    case STOP_QUICKBOOKS_ITEMS_LOADING:
      return {
        ...state,
        qboItemsLoading: false
      };

    case START_QUICKBOOKS_ITEMS_LOADING:
      return {
        ...state,
        qboItemsLoading: true
      };

    case START_RATES_LOADING:
      return {
        ...state,
        ratesLoading: true
      };

    case STOP_RATES_LOADING:
      return {
        ...state,
        ratesLoading: false
      };

    case STOP_QUICKBOOKS_LOADING:
      return {
        ...state,
        qboUsersLoading: false
      };

    case START_QUICKBOOKS_LOADING:
      return {
        ...state,
        qboUsersLoading: true
      };

    case STORE_STAGED_QBO:
      return {
        ...state,
        stagedQboEmployees: action.payload.stagedEmployees,
        qboItems: action.payload.items,
        qboUsersLoading: false
      };

    default:
      return state;
  }
};

//Action Creators
export const fetchUsersInfoAction = (payload) => ({
  type: FETCH_USERS_INFO,
  payload
});

export const storeUsersInfo = (payload) => ({
  type: STORE_USERS_INFO,
  payload
});

export const stopUsersLoading = (payload) => ({
  type: STOP_USERS_LOADING,
  payload
});

export const startUsersLoading = (payload) => ({
  type: START_USERS_LOADING,
  payload
});

export const addUserToOrgAction = (payload) => ({
  type: ADD_USER_TO_ORG,
  payload
});

export const updateUserAction = (payload) => ({
  type: UPDATE_USER,
  payload
});

export const deleteUserAction = (payload) => ({
  type: DELETE_USER,
  payload
});

export const createRateAction = (payload) => ({
  type: CREATE_RATE,
  payload
});

export const updateRateAction = (payload) => ({
  type: UPDATE_RATE,
  payload
});

export const stopRatesLoading = (payload) => ({
  type: STOP_RATES_LOADING,
  payload
});

export const startRatesLoading = (payload) => ({
  type: START_RATES_LOADING,
  payload
});

// QUICKBOOKS
export const stopQboUsersLoading = (payload) => ({
  type: STOP_QUICKBOOKS_LOADING,
  payload
});

export const startQboUsersLoading = (payload) => ({
  type: START_QUICKBOOKS_LOADING,
  payload
});

export const fetchUsersFromQboAction = (payload) => ({
  type: FETCH_USERS_FROM_QBO,
  payload
});

export const storeStagedQboUsers = (payload) => ({
  type: STORE_STAGED_QBO,
  payload
});

export const bulkCreateFromQboAction = (payload) => ({
  type: BULK_CREATE_FROM_QBO,
  payload
});

export const fetchItemsFromQboAction = (payload) => ({
  type: FETCH_ITEMS_FROM_QBO,
  payload
});

export const storeItemsFromQbo = (payload) => ({
  type: STORE_ITEMS_FROM_QBO,
  payload
});

export const stopQboItemsLoading = (payload) => ({
  type: STOP_QUICKBOOKS_ITEMS_LOADING,
  payload
});

export const startQboItemsLoading = (payload) => ({
  type: START_QUICKBOOKS_ITEMS_LOADING,
  payload
});

export default reducer;
