import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import {
  PrimaryButton,
  SecondaryButton,
  UniversalSelect,
  ButtonRow,
  UniversalInput
} from './../../../components';

const EditCompanyPopover = ({
  currentName,
  organizationId,
  updateCompany,
  currentDefaultInvoiceApprover,
  users
}) => {
  const [editCompanyPopoverVisible, setEditCompanyPopoverVisible] = useState(false);
  const [name, setName] = useState(currentName);
  const [approverId, setApproverId] = useState(currentDefaultInvoiceApprover);

  useEffect(() => {
    if (editCompanyPopoverVisible) {
      setName(currentName);
      setApproverId(currentDefaultInvoiceApprover);
    }
  }, [currentName, editCompanyPopoverVisible, currentDefaultInvoiceApprover]);

  const cancelAdd = () => {
    setEditCompanyPopoverVisible(false);
    setName('');
    setApproverId(null);
  };

  const editCompany = () => {
    updateCompany({
      data: { name, default_invoice_approver: approverId },
      organizationId: organizationId
    });
    cancelAdd();
  };

  const notDifferent = name === currentName && approverId === currentDefaultInvoiceApprover;

  return (
    <Popover
      trigger="click"
      visible={editCompanyPopoverVisible}
      onVisibleChange={(visible) => setEditCompanyPopoverVisible(visible)}
      title={`Edit ${currentName}`}
      placement="bottomRight"
      overlayStyle={{ minWidth: '300px' }}
      content={
        <div>
          <UniversalInput
            title="Name*"
            inputValue={name}
            onChange={(e) => setName(e.target.value)}
          />

          <UniversalSelect
            title="Invoice Approver"
            selectValue={approverId}
            onChange={(id) => setApproverId(id)}
            options={
              users.length &&
              users
                .filter((user) => user.role_id <= 2)
                .map((user) => ({
                  key: user.id,
                  value: user.id,
                  name: `${user.first_name} ${user.last_name}`
                }))
            }
            placeholder="Select Approver"
          />

          <ButtonRow>
            <SecondaryButton onClick={cancelAdd} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={editCompany} disabled={notDifferent}>
              Edit
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <PrimaryButton icon={<EditOutlined />}>Edit</PrimaryButton>
    </Popover>
  );
};

export default EditCompanyPopover;
