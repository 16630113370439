import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { findPayPeriodDates } from './../../utils/moment-utils.js';
import {
  fetchMyTimeInfoAction,
  deleteEntryAction,
  editEntryAction,
  updateMyTimeDateRangeAction,
  updateMyTimeSelectedDateAction
} from './../../ducks/user';
import {
  ContentContainer,
  FlexRow,
  TitleH4,
  PageContentContainer,
  UniversalDatePicker,
  AvatarCard
} from './../../components';
import PayPeriodSummary from './payPeriodSummary';
import DailyEntries from './dailyEntries';
import ClientBreakdown from './clientBreakdown';

const UserTime = ({
  clients: { clients, allEntities, allProjects },
  user: {
    myTimeInfo: { clientInfo, daysInRange, countByUser, entries },
    myTimeLoading,
    first_name,
    last_name,
    rates,
    goal_daily_hours
  },
  fetchMyTimeInfo,
  deleteEntry,
  editEntry,
  updateReduxSelectedDate,
  updateReduxDateRange
}) => {
  const [dateRange, setDateRange] = useState(findPayPeriodDates(moment()));
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    if (dateRange.length > 0) {
      fetchMyTimeInfo({
        startDate: moment(dateRange[0]).startOf('day').utc().format(),
        endDate: moment(dateRange[dateRange.length - 1])
          .endOf('day')
          .utc()
          .format()
      });
    }
  }, [dateRange, fetchMyTimeInfo]);

  useEffect(() => {
    const exists = !!dateRange.filter(
      (date) => moment(selectedDate).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY')
    ).length;

    if (!exists) {
      const range = findPayPeriodDates(selectedDate);
      setDateRange(range);
      updateReduxDateRange({ dateRange: range });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const updateSelectedDate = (date) => {
    setSelectedDate(date);
    updateReduxSelectedDate({ date });
  };

  const disabledDates = (current) => current >= moment().endOf('day');

  return (
    <ContentContainer loading={myTimeLoading} loadingTip="Loading Data...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>{`${first_name} ${last_name} Work Entries`}</TitleH4>

        <UniversalDatePicker
          disabledDates={disabledDates}
          value={selectedDate}
          onChange={updateSelectedDate}
          noTitle
          style={{ width: '300px' }}
        />
      </FlexRow>

      <PageContentContainer>
        <AvatarCard
          style={{ marginTop: '20px' }}
          name={`Summary for ${moment(dateRange[0]).format('MM/DD/YYYY')} - ${moment(
            dateRange[dateRange.length - 1]
          ).format('MM/DD/YYYY')}`}
        >
          <PayPeriodSummary
            countByUser={countByUser}
            datesProp={dateRange}
            entriesProp={entries}
            clients={clients}
            rates={rates}
            selectedDate={selectedDate}
            goalDailyHours={goal_daily_hours}
            setSelectedDate={updateSelectedDate}
          />
        </AvatarCard>

        <AvatarCard
          style={{ marginTop: '20px' }}
          grayBackground={true}
          name={`Work Entries for ${moment(selectedDate).format('MM/DD/YYYY')}`}
        >
          <DailyEntries
            startDate={moment(dateRange[0]).startOf('day').utc().format()}
            endDate={moment(dateRange[dateRange.length - 1])}
            allEntities={allEntities}
            allProjects={allProjects}
            entriesProp={entries}
            clients={clients}
            selectedDate={selectedDate}
            deleteEntry={deleteEntry}
            editEntry={editEntry}
          />
        </AvatarCard>

        <AvatarCard
          style={{ marginTop: '20px', marginBottom: '40px' }}
          name="Hours by Client, Entity, and Project"
        >
          <ClientBreakdown clientInfo={clientInfo} daysInRange={daysInRange} />
        </AvatarCard>
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ user, clients }) => ({
  user,
  clients
});

const mapDispatchToProps = (dispatch) => ({
  fetchMyTimeInfo: (payload) => dispatch(fetchMyTimeInfoAction(payload)),
  deleteEntry: (payload) => dispatch(deleteEntryAction(payload)),
  editEntry: (payload) => dispatch(editEntryAction(payload)),
  updateReduxSelectedDate: (payload) => dispatch(updateMyTimeSelectedDateAction(payload)),
  updateReduxDateRange: (payload) => dispatch(updateMyTimeDateRangeAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTime);
