import styled from 'styled-components';

import { COLORS } from './../../../constants/styles';

export const QboServiceContainer = styled.div`
  border: 1px solid ${COLORS.borderColor};
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
`;
