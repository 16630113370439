//Actions
export const FETCH_CLIENTS_BY_ORG = 'CLIENTS/FETCH_CLIENTS_BY_ORG';
export const STORE_CLIENTS_INFO = 'CLIENTS/STORE_CLIENTS_INFO';
export const STOP_CLIENTS_LOADING = 'CLIENTS/STOP_CLIENTS_LOADING';
export const START_CLIENTS_LOADING = 'CLIENTS/START_CLIENTS_LOADING';
export const ADD_CLIENT_TO_ORG = 'CLIENTS/ADD_CLIENT_TO_ORG';
export const UPDATE_CLIENT = 'CLIENTS/UPDATE_CLIENT';
export const DELETE_CLIENT = 'CLIENTS/DELETE_CLIENT';

export const STOP_ENTITIES_LOADING = 'CLIENTS/STOP_ENTITIES_LOADING';
export const START_ENTITIES_LOADING = 'CLIENTS/START_ENTITIES_LOADING';
export const CREATE_ENTITY = 'CLIENTS/CREATE_ENTITY';
export const STORE_ENTITIES_BY_CLIENT_ID = 'CLIENTS/STORE_ENTITIES_BY_CLIENT_ID';
export const DELETE_ENTITY = 'CLIENTS/DELETE_ENTITY';
export const UPDATE_ENTITY = 'CLIENTS/UPDATE_ENTITY';

export const STOP_PROJECTS_LOADING = 'CLIENTS/STOP_PROJECTS_LOADING';
export const START_PROJECTS_LOADING = 'CLIENTS/START_PROJECTS_LOADING';
export const CREATE_PROJECT = 'CLIENTS/CREATE_PROJECT';
export const DELETE_PROJECT = 'CLIENTS/DELETE_PROJECT';
export const UPDATE_PROJECT = 'CLIENTS/UPDATE_PROJECT';
export const STORE_PROJECTS_BY_ENTITY_ID = 'CLIENTS/STORE_PROJECTS_BY_ENTITY_ID';
export const UPDATE_PROJECT_CUSTOM_RATES = 'CLIENTS/UPDATE_PROJECT_CUSTOM_RATES';

export const STOP_MONTHLY_FEES_LOADING = 'CLIENTS/STOP_MONTHLY_FEES_LOADING';
export const START_MONTHLY_FEES_LOADING = 'CLIENTS/START_MONTHLY_FEES_LOADING';
export const UPDATE_MONTHLY_FEE = 'CLIENTS/UPDATE_MONTHLY_FEE';
export const CREATE_MONTHLY_FEE = 'CLIENTS/CREATE_MONTHLY_FEE';

export const FETCH_CLIENTS_FROM_QBO = 'CLIENTS/FETCH_CLIENTS_FROM_QBO';
export const STOP_QUICKBOOKS_LOADING = 'CLIENTS/STOP_QUICKBOOKS_LOADING';
export const START_QUICKBOOKS_LOADING = 'CLIENTS/START_QUICKBOOKS_LOADING';
export const STORE_STAGED_QBO = 'CLIENTS/STORE_STAGED_QBO';
export const BULK_CREATE_FROM_QBO = 'CLIENTS/BULK_CREATE_FROM_QBO';

//Reducer
const initialState = {
  clients: [
    {
      id: null,
      organization_id: null,
      name: '',
      email: '',
      monthly_fee: false,
      monthly_fees: [
        {
          id: null,
          amount: null,
          client_id: null,
          date_began: '',
          date_ended: ''
        }
      ],
      entities: [
        {
          id: null,
          client_id: null,
          qbo_customer_id: null,
          name: '',
          projects: [
            {
              id: null,
              entity_id: null,
              name: '',
              billing_type: '',
              fixed_fee_amount: null,
              retired: false,
              uses_custom_rates: false,
              custom_rates: []
            }
          ]
        }
      ]
    }
  ],
  count: 0,
  stagedQboEntities: [],
  allEntities: [],
  allProjects: [],
  loading: false,
  entitiesLoading: false,
  projectsLoading: false,
  monthlyFeesLoading: false,
  qboClientsLoading: false
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // CLIENTS
    case START_CLIENTS_LOADING:
      return {
        ...state,
        loading: true
      };

    case STORE_CLIENTS_INFO:
      const { count, clients } = action.payload;
      const allEntities = clients.reduce((tot, curr) => [...tot, ...curr.entities], []);

      return {
        ...state,
        clients,
        count,
        loading: false,
        entitiesLoading: false,
        projectsLoading: false,
        monthlyFeesLoading: false,
        qboClientsLoading: false,
        allEntities: allEntities,
        allProjects: allEntities.reduce((tot, curr) => [...tot, ...curr.projects], [])
      };

    case STOP_CLIENTS_LOADING:
      return {
        ...state,
        loading: false
      };

    // ENTITIES
    case STOP_ENTITIES_LOADING:
      return {
        ...state,
        entitiesLoading: false
      };

    case START_ENTITIES_LOADING:
      return {
        ...state,
        entitiesLoading: true
      };

    case STORE_ENTITIES_BY_CLIENT_ID:
      const { entities, clientId } = action.payload;
      const clientIndex = state.clients.findIndex((client) => client.id === clientId);
      const newClients = [
        ...state.clients.slice(0, clientIndex),
        { ...state.clients[clientIndex], entities: [...entities] },
        ...state.clients.slice(clientIndex + 1)
      ];
      const newEntities = newClients.reduce((tot, curr) => [...tot, ...curr.entities], []);
      return {
        ...state,
        clients: newClients,
        allEntities: newEntities,
        allProjects: newEntities.reduce((tot, curr) => [...tot, ...curr.projects], []),
        entitiesLoading: false,
        projectsLoading: false,
        monthlyFeesLoading: false,
        qboClientsLoading: false
      };

    // PROJECTS
    case STOP_PROJECTS_LOADING:
      return {
        ...state,
        projectsLoading: false
      };

    case START_PROJECTS_LOADING:
      return {
        ...state,
        projectsLoading: true
      };

    // MONTHLY FEES
    case STOP_MONTHLY_FEES_LOADING:
      return {
        ...state,
        monthlyFeesLoading: false
      };

    case START_MONTHLY_FEES_LOADING:
      return {
        ...state,
        monthlyFeesLoading: true
      };

    // MONTHLY FEES
    case STOP_QUICKBOOKS_LOADING:
      return {
        ...state,
        qboClientsLoading: false
      };

    case START_QUICKBOOKS_LOADING:
      return {
        ...state,
        qboClientsLoading: true
      };

    case STORE_STAGED_QBO:
      return {
        ...state,
        stagedQboEntities: action.payload.stagedEntities,
        qboClientsLoading: false
      };

    default:
      return state;
  }
};

//Action Creators
// CLIENTS
export const stopClientsLoading = (payload) => ({
  type: STOP_CLIENTS_LOADING,
  payload
});

export const startClientsLoading = (payload) => ({
  type: START_CLIENTS_LOADING,
  payload
});

export const fetchClientsByOrgAction = (payload) => ({
  type: FETCH_CLIENTS_BY_ORG,
  payload
});

export const storeClientsInfo = (payload) => ({
  type: STORE_CLIENTS_INFO,
  payload
});

export const addClientToOrgAction = (payload) => ({
  type: ADD_CLIENT_TO_ORG,
  payload
});

export const updateClientAction = (payload) => ({
  type: UPDATE_CLIENT,
  payload
});

export const deleteClientAction = (payload) => ({
  type: DELETE_CLIENT,
  payload
});

// ENTITIES
export const stopEntitiesLoading = (payload) => ({
  type: STOP_ENTITIES_LOADING,
  payload
});

export const startEntitiesLoading = (payload) => ({
  type: START_ENTITIES_LOADING,
  payload
});

export const createEntityAction = (payload) => ({
  type: CREATE_ENTITY,
  payload
});

export const storeEntitiesByClientIdAction = (payload) => ({
  type: STORE_ENTITIES_BY_CLIENT_ID,
  payload
});

export const deleteEntityAction = (payload) => ({
  type: DELETE_ENTITY,
  payload
});

export const updateEntityAction = (payload) => ({
  type: UPDATE_ENTITY,
  payload
});

// PROJECTS
export const stopProjectsLoading = (payload) => ({
  type: STOP_PROJECTS_LOADING,
  payload
});

export const startProjectsLoading = (payload) => ({
  type: START_PROJECTS_LOADING,
  payload
});

export const createProjectAction = (payload) => ({
  type: CREATE_PROJECT,
  payload
});

export const deleteProjectAction = (payload) => ({
  type: DELETE_PROJECT,
  payload
});

export const updateProjectAction = (payload) => ({
  type: UPDATE_PROJECT,
  payload
});

export const updateProjectCustomRatesAction = (payload) => ({
  type: UPDATE_PROJECT_CUSTOM_RATES,
  payload
});

export const storeProjectsByEntityIdAction = (payload) => ({
  type: STORE_PROJECTS_BY_ENTITY_ID,
  payload
});

// MONTHLY FEES
export const stopMonthlyFeesLoading = (payload) => ({
  type: STOP_MONTHLY_FEES_LOADING,
  payload
});

export const startMonthlyFeesLoading = (payload) => ({
  type: START_MONTHLY_FEES_LOADING,
  payload
});

export const updateMonthlyFeeAction = (payload) => ({
  type: UPDATE_MONTHLY_FEE,
  payload
});

export const createMonthlyFeeAction = (payload) => ({
  type: CREATE_MONTHLY_FEE,
  payload
});

// QUICKBOOKS
export const stopQuickbooksLoading = (payload) => ({
  type: STOP_QUICKBOOKS_LOADING,
  payload
});

export const startQuickbooksLoading = (payload) => ({
  type: START_QUICKBOOKS_LOADING,
  payload
});

export const fetchClientsFromQboAction = (payload) => ({
  type: FETCH_CLIENTS_FROM_QBO,
  payload
});

export const storeStagedQboEntities = (payload) => ({
  type: STORE_STAGED_QBO,
  payload
});

export const bulkCreateFromQboAction = (payload) => ({
  type: BULK_CREATE_FROM_QBO,
  payload
});

export default reducer;
