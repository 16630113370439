import { all } from 'redux-saga/effects';

import UserSagas from './user/sagas';
import UsersSagas from './users/sagas';
import CompanySagas from './company/sagas';
import ClientsSagas from './clients/sagas';
import EntriesSagas from './entries/sagas';
import InvoicesSagas from './invoices/sagas';
import ReportsSagas from './reports/sagas';

function* rootSaga() {
  yield all([
    ...UserSagas,
    ...CompanySagas,
    ...ClientsSagas,
    ...EntriesSagas,
    ...UsersSagas,
    ...InvoicesSagas,
    ...ReportsSagas
  ]);
}

export default rootSaga;
