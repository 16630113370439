import React from 'react';
import { Result } from 'antd';
import { useHistory } from 'react-router-dom';

import { Page } from './../../components/Layouts';
import { PrimaryButton } from './../../components/Buttons';

const NotFound = () => {
  const history = useHistory();

  return (
    <Page>
      <Result
        status="404"
        title="Not Found"
        subTitle="Sorry, we can't find that page."
        extra={<PrimaryButton onClick={() => history.push('/')}>Back Home</PrimaryButton>}
      />
    </Page>
  );
};

export default NotFound;
