import React from 'react';
import { Modal } from 'antd';

import { WarningMessage } from './../';

export const DeleteConfirmationModal = ({
  deleteFn,
  cancelFn,
  warningMessage,
  emphasize,
  ...rest
}) => {
  return (
    <Modal title="Are you sure?" onOk={deleteFn} okText="Confirm" onCancel={cancelFn} {...rest}>
      {emphasize ? <WarningMessage message={warningMessage} /> : warningMessage}
    </Modal>
  );
};
