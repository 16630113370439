import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { ImportOutlined } from '@ant-design/icons';

import { quickbooksColumns } from './constants';
import {
  FlexRow,
  UniversalDrawer,
  PrimaryButton,
  SecondaryButton,
  DrawerLoader
} from './../../../components';

const QuickbooksDrawer = ({
  onClose,
  stagedEmployeesProp,
  loading,
  fetchQboUsers,
  bulkCreateQboUsers,
  visible,
  qboItems,
  ...rest
}) => {
  const [stagedEmployees, setStagedEmployees] = useState([]);

  useEffect(() => {
    if (visible && !stagedEmployeesProp.length) {
      fetchQboUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, fetchQboUsers]);

  useEffect(() => {
    if (stagedEmployeesProp.length) {
      setStagedEmployees(stagedEmployeesProp);
    }
  }, [stagedEmployeesProp]);

  const cancel = () => {
    onClose();
    setStagedEmployees(stagedEmployeesProp);
  };

  const removeEmployee = (index) => {
    setStagedEmployees([...stagedEmployees.slice(0, index), ...stagedEmployees.slice(index + 1)]);
  };

  const updateAttribute = (value, prop, index) => {
    setStagedEmployees([
      ...stagedEmployees.slice(0, index),
      { ...stagedEmployees[index], [prop]: value },
      ...stagedEmployees.slice(index + 1)
    ]);
  };

  const importEmployees = () => {
    bulkCreateQboUsers({
      newEmployees: stagedEmployees
    });
    cancel();
  };

  const hasNoEmpty =
    stagedEmployees.length &&
    stagedEmployees.reduce((total, curr) => {
      return (
        total * !!curr.first_name * !!curr.last_name * !!curr.email * !!curr.role_id * !!curr.type
      );
    }, 1);

  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailsAreGood = stagedEmployees.reduce((total, curr) => {
    return total * !!emailRegex.test(curr.email);
  }, 1);

  return (
    <UniversalDrawer
      width="100%"
      onClose={cancel}
      title="Import Employees from Quickbooks"
      destroyOnClose
      visible={visible}
      closable
      footer={
        !loading && (
          <FlexRow justifyContent="flex-end" alignItems="center">
            <SecondaryButton onClick={cancel} style={{ marginRight: '20px' }}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={importEmployees}
              disabled={!hasNoEmpty || !emailsAreGood}
              icon={<ImportOutlined />}
            >
              Import
            </PrimaryButton>
          </FlexRow>
        )
      }
      {...rest}
    >
      {loading ? (
        <DrawerLoader tip="Loading Data from Quickbooks..." />
      ) : (
        <Table
          scroll={{ y: ((window.innerHeight - 200) / 50 - 1) * 50 + 30 }}
          pagination={false}
          size="small"
          dataSource={stagedEmployees}
          rowKey="qbo_id"
          columns={quickbooksColumns({ updateAttribute, qboItems, removeEmployee })}
          style={{ width: '100%' }}
        />
      )}
    </UniversalDrawer>
  );
};

export default QuickbooksDrawer;
