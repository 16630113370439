import React, { useState, useEffect } from 'react';
import { Tooltip, Popover } from 'antd';
import { FolderAddOutlined } from '@ant-design/icons';

import {
  SecondaryButton,
  PrimaryButton,
  ButtonRow,
  UniversalInput,
  UniversalInputNumber,
  UniversalRadioButton
} from './../../../components';
import { pojectPopoverRadioOptions } from './constants';

const AddProjectPopover = ({ client, record, createProject }) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [fixedFeeAmount, setFixedFeeAmount] = useState(null);

  useEffect(() => {
    if (visible) {
      setName('');
      setType('');
      setFixedFeeAmount(null);
    }
  }, [visible, setName, setType, setFixedFeeAmount]);

  useEffect(() => {
    if (type !== 'Fixed') {
      setFixedFeeAmount(null);
    }
  }, [type]);

  const cancelAdd = () => {
    setVisible(false);
    setName('');
    setType('');
    setType(null);
  };

  const addProject = () => {
    createProject({
      name,
      client_id: client.id,
      entity_id: record.id,
      billing_type: type,
      fixed_fee_amount: fixedFeeAmount ? Number(fixedFeeAmount.toFixed(2)) : 0
    });
    cancelAdd();
  };

  const hasEmptyField = !name || !type || (type === 'Fixed' && !fixedFeeAmount);

  return (
    <Popover
      trigger="click"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      title="Add Project"
      placement="bottomRight"
      content={
        <div>
          <UniversalInput
            title="Project Name*"
            inputValue={name}
            onChange={(e) => setName(e.target.value)}
          />
          <UniversalRadioButton
            title="Billing Type*"
            radioValue={type}
            onChange={(e) => setType(e.target.value)}
            options={pojectPopoverRadioOptions({ monthlyCheck: !client.monthly_fee })}
          />

          {type === 'Fixed' && (
            <UniversalInputNumber
              title="Amount*"
              inputValue={fixedFeeAmount}
              onChange={setFixedFeeAmount}
              currency={true}
            />
          )}

          <ButtonRow>
            <SecondaryButton onClick={cancelAdd} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={hasEmptyField} onClick={addProject}>
              Add
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <Tooltip title="Add Project">
        <SecondaryButton style={{ marginRight: '15px' }} icon={<FolderAddOutlined />} />
      </Tooltip>
    </Popover>
  );
};

export default AddProjectPopover;
