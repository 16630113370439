import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { ToolOutlined, UserOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { FlexRow, PrimaryButton } from './../';
import { Header } from './styled-components';
import {
  adminMenu,
  userMenu,
  invoicingSubMenu,
  adminReportsSubMenu
} from './../../constants/menus';
import logo from './logo.png';

const { SubMenu } = Menu;

const AppHeader = ({ user }) => {
  const history = useHistory();
  const [location, setLocation] = useState(history.location.pathname);

  useEffect(() => {
    setLocation(history.location.pathname);
  }, [history.location.pathname]);

  const onClick = (e) => history.push(e.key);

  return (
    <Header>
      <div
        onClick={() => history.push('/user/time')}
        style={{ cursor: 'pointer', display: 'flex' }}
      >
        <img src={logo} height={50} alt="logo" />
      </div>

      {!user.userId ? (
        <a href={`${process.env.REACT_APP_API_URL}/auth/google/login`}>
          <PrimaryButton>Login</PrimaryButton>
        </a>
      ) : (
        <Menu
          selectedKeys={[location.split('/')[1], location]}
          style={{
            borderBottom: 'none',
            minWidth: '600px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
          mode="horizontal"
        >
          {user.role_id <= 2 && (
            <SubMenu
              key="admin"
              title={
                <span className="submenu-title-wrapper">
                  <ToolOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
                  Admin
                </span>
              }
            >
              {adminMenu.map((item) => {
                if (item.key === 'invoices') {
                  return (
                    <SubMenu key={item.key} icon={item.icon} title={item.value}>
                      {invoicingSubMenu.map((subItem) => (
                        <Menu.Item onClick={onClick} key={`/admin/invoices/${subItem.key}`}>
                          {subItem.icon}
                          <span style={{ marginLeft: '8px' }}>{subItem.value}</span>
                        </Menu.Item>
                      ))}
                      {location.split('/')[1] === 'admin' &&
                        location.split('/')[2] === 'invoices' &&
                        location.split('/')[3] === 'edit' && (
                          <Menu.Item
                            onClick={onClick}
                            key={`/admin/invoices/edit/${location.split('/')[4]}`}
                          >
                            <EditOutlined /> Edit
                          </Menu.Item>
                        )}
                    </SubMenu>
                  );
                } else if (item.key === 'reports') {
                  return (
                    <SubMenu key={item.key} icon={item.icon} title={item.value}>
                      {adminReportsSubMenu.map((subItem) => (
                        <Menu.Item onClick={onClick} key={`/admin/reports/${subItem.key}`}>
                          {subItem.icon}
                          <span style={{ marginLeft: '8px' }}>{subItem.value}</span>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Menu.Item onClick={onClick} key={`/admin/${item.key}`}>
                      {item.icon}
                      <span style={{ marginLeft: '8px' }}>{item.value}</span>
                    </Menu.Item>
                  );
                }
              })}
            </SubMenu>
          )}

          <SubMenu
            key="user"
            title={
              <span className="submenu-title-wrapper">
                <UserOutlined style={{ fontSize: '20px', marginRight: '8px' }} />
                {`${user.first_name} ${user.last_name}`}
              </span>
            }
          >
            {userMenu.map((item) => (
              <Menu.Item onClick={onClick} key={`/user/${item.key}`}>
                {item.icon}
                <span style={{ marginLeft: '8px' }}>{item.value}</span>
              </Menu.Item>
            ))}

            <Menu.Item key={`/user/logout`}>
              <a href={`${process.env.REACT_APP_API_URL}/auth/google/logout`}>
                <LogoutOutlined style={{ marginRight: '8px' }} /> Logout
              </a>
            </Menu.Item>
          </SubMenu>
        </Menu>
      )}
    </Header>
  );
};

export default AppHeader;
