import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Dropdown, Menu, Input, Tooltip } from 'antd';
import { UpOutlined } from '@ant-design/icons';

import { fetchInvoiceAction, updateInvoiceAction } from './../../../ducks/invoices';
import {
  InvoiceHeader,
  InvoiceContainer,
  InvoiceInfoContainer,
  InvoiceInfoContentContainer,
  InvoiceEntriesContainer
} from './styled-components';
import {
  fetchUsersInfoAction,
  fetchItemsFromQboAction,
  updateUserAction
} from './../../../ducks/users';
import {
  TitleH4,
  TitledData,
  Text,
  PageContentContainer,
  SecondaryButton,
  PrimaryButton,
  ContentContainer,
  FlexRow,
  UniversalSelect,
  UniversalDatePicker
} from './../../../components';
import { currencyFormatter } from './../../../utils/formatters';
import returnInvoiceColumns from './invoiceColumns';
import EditInvoiceModal from './editInvoiceModal';

const EditInvoice = ({
  company: { link_to_logo, qbo_linked },
  users: { users, loading: usersLoading, qboItems, qboItemsLoading },
  clients: { allEntities },
  invoices: {
    loading: invoicesLoading,
    editingInvoice: {
      ready_for_approval,
      entity_id,
      id,
      due_date,
      approved_by,
      entries: entriesProp,
      invoice_date,
      note_to_editor,
      sent_to_qbo
    }
  },
  fetchInvoice,
  fetchUsers,
  fetchQboItems,
  updateUser,
  updateInvoice
}) => {
  const { invoiceId } = useParams();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState(moment());
  const [invoiceDueDate, setInvoiceDueDate] = useState(moment());
  const [approvedBy, setApprovedBy] = useState(null);
  const [entity, setEntity] = useState(null);
  const [usableEntries, setUsableEntries] = useState([]);
  const [editInvoiceModalVisible, setEditInvoiceModalVisible] = useState(false);
  const [entryBeingEdited, setEntryBeingEdited] = useState({});
  const [noteToEditor, setNoteToEditor] = useState('');
  const history = useHistory();

  const onUpdate = ({ readyForApproval, shouldRedirect, sendToQbo }) => {
    updateInvoice({
      invoiceId,
      data: {
        ready_for_approval: readyForApproval,
        approved_by: approvedBy,
        entries: usableEntries,
        total_time: totalTime,
        total_amount: totalAmount,
        due_date: invoiceDueDate,
        invoice_date: invoiceDate,
        note_to_editor: noteToEditor,
        sendToQbo,
        entity,
        qbo_linked
      },
      history,
      shouldRedirect,
      ready_for_approval
    });
  };

  useEffect(() => {
    if (invoiceId) {
      fetchInvoice({ invoiceId });
      fetchUsers();
      fetchQboItems();
    }
  }, [invoiceId, fetchInvoice, fetchUsers, fetchQboItems]);

  const resetData = (shouldRedirect) => {
    if (shouldRedirect) {
      ready_for_approval
        ? history.push('/admin/invoices/awaiting')
        : history.push('/admin/invoices/create');
    } else {
      const createUsableEntries = (entries) => {
        if (entries.length > 0) {
          return entries.map((entry) => {
            const userInfo = users.find((user) => user.id === entry.user_id);
            const linkedItem = userInfo && userInfo.linked_qbo_item;
            const { project, time_spent } = entry;

            let rate = entry.rate_invoiced_at;

            if (!rate) {
              const projectRate =
                project.custom_rates && project.custom_rates.find((r) => r.id === entry.user_id);
              const userBillingRate =
                userInfo &&
                userInfo.rates.find((r) => {
                  return (
                    r.type === 'Billing' &&
                    moment(entry.date) >= moment(r.date_began) &&
                    (moment(entry.date) <= moment(r.date_ended) || !r.date_ended)
                  );
                });

              rate = project.uses_custom_rates
                ? projectRate
                  ? projectRate.rate
                  : 0
                : userBillingRate
                ? userBillingRate.amount
                : 0;
            }

            return {
              id: entry.id,
              date: entry.date,
              description: entry.description,
              project,
              userInfo,
              linkedItem,
              rate_invoiced_at: rate,
              time_spent,
              entry_total: Number((rate * time_spent).toFixed(2)),
              shouldDelete: false,
              will_not_charge: false,
              removeFromInvoice: false
            };
          });
        } else {
          return entries;
        }
      };

      if (id && users.length) {
        const goodEntries = createUsableEntries(entriesProp);
        setInvoiceDate(invoice_date ? moment(invoice_date) : moment());
        setInvoiceDueDate(due_date ? moment(due_date) : moment());
        setApprovedBy(approved_by);
        setNoteToEditor(note_to_editor);
        setEntity(allEntities.find((ent) => ent.id === entity_id));
        setUsableEntries(goodEntries);
      }
    }
  };

  useEffect(() => {
    resetData();
    //eslint-disable-next-line
  }, [id, allEntities, entriesProp, users]);

  useEffect(() => {
    setTotalAmount(
      Number(
        usableEntries
          .filter(
            (entry) => !entry.shouldDelete && !entry.will_not_charge && !entry.removeFromInvoice
          )
          .reduce((tot, curr) => tot + Number(curr.entry_total), 0)
          .toFixed(2)
      )
    );
    setTotalTime(
      Number(
        usableEntries
          .filter(
            (entry) => !entry.shouldDelete && !entry.will_not_charge && !entry.removeFromInvoice
          )
          .reduce((tot, curr) => tot + Number(curr.time_spent), 0)
          .toFixed(2)
      )
    );
  }, [usableEntries]);

  const updateEntry = ({ id, description, rate, timeSpent }) => {
    const idx = usableEntries.findIndex((entry) => entry.id === id);
    const rate_invoiced_at = Number(rate.toFixed(2));
    const time_spent = Number(timeSpent.toFixed(2));

    setUsableEntries([
      ...usableEntries.slice(0, idx),
      {
        ...usableEntries[idx],
        description,
        rate_invoiced_at,
        time_spent,
        entry_total: Number((rate_invoiced_at * time_spent).toFixed(2))
      },
      ...usableEntries.slice(idx + 1)
    ]);
  };

  const updateEntryByProp = ({ prop, value, id }) => {
    const idx = usableEntries.findIndex((entry) => entry.id === id);

    setUsableEntries([
      ...usableEntries.slice(0, idx),
      {
        ...usableEntries[idx],
        [prop]: value
      },
      ...usableEntries.slice(idx + 1)
    ]);
  };

  const invoiceDateDisabledDates = (current) => current >= moment().endOf('day');

  const disabled =
    !usableEntries.reduce((tot, curr) => {
      return tot * !!curr.linkedItem;
    }, 1) || !approvedBy;

  const entityLinkedToQBO = entity && entity.qbo_customer_id;
  const companyLinkedToQbo = qbo_linked;

  return (
    <ContentContainer
      loading={invoicesLoading || usersLoading || qboItemsLoading}
      loadingTip="Loading Invoice Data..."
    >
      <PageContentContainer>
        <InvoiceContainer>
          <InvoiceHeader>
            <FlexRow justifyContent="space-between" alignItems="center" padding="0px" margin="0px">
              <img style={{ height: '50px' }} src={link_to_logo} alt="company logo" />
              <TitleH4>{`Invoice #${id}`}</TitleH4>
            </FlexRow>
          </InvoiceHeader>

          <FlexRow padding="20px 20px 0px">
            <UniversalSelect
              title="Invoice Approver"
              disabled={ready_for_approval}
              onChange={(id) => setApprovedBy(id)}
              selectValue={approvedBy}
              placeholder="Select Approver"
              options={
                users.length &&
                users
                  .filter((user) => user.role_id <= 2)
                  .map((user) => ({
                    key: user.id,
                    value: user.id,
                    name: `${user.first_name} ${user.last_name}`
                  }))
              }
            />
          </FlexRow>

          <InvoiceInfoContainer>
            <InvoiceInfoContentContainer>
              <TitledData title="Invoice To Entity" value={entity && entity.name} />

              <UniversalDatePicker
                title="Invoice Date"
                value={invoiceDate}
                disabledDates={invoiceDateDisabledDates}
                onChange={(date) => date && setInvoiceDate(date)}
                disabled={sent_to_qbo}
                style={{ width: '300px' }}
              />

              <UniversalDatePicker
                title="Invoice Due Date"
                value={invoiceDueDate}
                onChange={(date) => date && setInvoiceDueDate(date)}
                disabled={sent_to_qbo}
                style={{ width: '300px' }}
              />
            </InvoiceInfoContentContainer>

            <InvoiceInfoContentContainer>
              <TitledData title="Total Amount" value={currencyFormatter.format(totalAmount || 0)} />
              <TitledData title="Total Hours" value={totalTime} />
            </InvoiceInfoContentContainer>
          </InvoiceInfoContainer>

          <div style={{ margin: '0px', padding: '20px' }}>
            <TitledData
              title={ready_for_approval ? 'Note to Editor' : 'Note from Approver'}
              value={
                ready_for_approval ? (
                  <Input.TextArea
                    value={noteToEditor}
                    onChange={(e) => setNoteToEditor(e.target.value)}
                    style={{ width: '100%' }}
                    disabled={sent_to_qbo}
                  />
                ) : (
                  noteToEditor || 'None'
                )
              }
            />
          </div>

          <InvoiceEntriesContainer>
            <Table
              pagination={false}
              rowKey={(record) => record.id}
              columns={returnInvoiceColumns({
                qboItems,
                qbo_linked,
                updateUser,
                setEntryBeingEdited,
                setEditInvoiceModalVisible,
                updateEntryByProp,
                sent_to_qbo
              })}
              dataSource={usableEntries.filter(
                (entry) => !entry.shouldDelete && !entry.will_not_charge && !entry.removeFromInvoice
              )}
              size="small"
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={7}>
                    <FlexRow padding="0px 20px 0px 0px" margin="0px" justifyContent="flex-end">
                      <Text strong={true}>Total</Text>
                    </FlexRow>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2}>
                    <Text strong={true}>{currencyFormatter.format(totalAmount || 0)}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </InvoiceEntriesContainer>

          <FlexRow justifyContent="flex-end" padding="10px 20px">
            {!sent_to_qbo ? (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => resetData(true)} key="cancel-1">
                      Cancel and Exit
                    </Menu.Item>
                    <Menu.Item onClick={() => resetData(false)} key="cancel-2">
                      Reset Changes
                    </Menu.Item>
                  </Menu>
                }
              >
                <SecondaryButton style={{ marginRight: '20px' }}>
                  Cancel <UpOutlined />
                </SecondaryButton>
              </Dropdown>
            ) : (
              <SecondaryButton onClick={() => history.push('/admin/invoices/sent')}>
                Exit
              </SecondaryButton>
            )}

            {!sent_to_qbo ? (
              <Dropdown
                overlay={
                  <Menu>
                    {ready_for_approval && (
                      <Menu.Item
                        disabled={disabled || !entityLinkedToQBO || !companyLinkedToQbo}
                        key="save-0"
                        onClick={() =>
                          onUpdate({
                            readyForApproval: true,
                            shouldRedirect: true,
                            sendToQbo: true
                          })
                        }
                      >
                        {disabled || !entityLinkedToQBO || !companyLinkedToQbo ? (
                          <Tooltip
                            title={
                              disabled
                                ? 'Please check that all employees are linked to a Quickbooks Service.'
                                : !entityLinkedToQBO
                                ? 'Please go to Clients page -> Manage Entities and provide a Quickbooks ID for this entity.'
                                : 'Please go to the Settings page and link your account to Quickbooks.'
                            }
                          >
                            Approve and Send to Quickbooks
                          </Tooltip>
                        ) : (
                          'Approve and Send to Quickbooks'
                        )}
                      </Menu.Item>
                    )}
                    {!ready_for_approval ? (
                      <Menu.Item
                        disabled={disabled}
                        onClick={() =>
                          onUpdate({
                            readyForApproval: true,
                            shouldRedirect: true,
                            sendToQbo: false
                          })
                        }
                        key="save-1"
                      >
                        {disabled ? (
                          <Tooltip title="Check that all employees are linked to a Quickbooks Service">
                            Send to Approval
                          </Tooltip>
                        ) : (
                          'Send to Approval'
                        )}
                      </Menu.Item>
                    ) : (
                      <Menu.Item
                        onClick={() =>
                          onUpdate({
                            readyForApproval: false,
                            shouldRedirect: true,
                            sendToQbo: false
                          })
                        }
                        key="save-1"
                      >
                        Send Back to Editing
                      </Menu.Item>
                    )}
                    <Menu.Item
                      onClick={() =>
                        onUpdate({
                          readyForApproval: ready_for_approval ? true : false,
                          shouldRedirect: true,
                          sendToQbo: false
                        })
                      }
                      key="save-2"
                    >
                      Save and Exit
                    </Menu.Item>
                    <Menu.Item
                      onClick={() =>
                        onUpdate({
                          readyForApproval: ready_for_approval ? true : false,
                          shouldRedirect: false,
                          sendToQbo: false
                        })
                      }
                      key="save-3"
                    >
                      Save
                    </Menu.Item>
                  </Menu>
                }
              >
                <PrimaryButton>
                  Save <UpOutlined />
                </PrimaryButton>
              </Dropdown>
            ) : null}
          </FlexRow>
        </InvoiceContainer>
      </PageContentContainer>

      <EditInvoiceModal
        visible={editInvoiceModalVisible}
        setVisibility={setEditInvoiceModalVisible}
        id={entryBeingEdited.id}
        currentDescription={entryBeingEdited.description}
        currentRate={entryBeingEdited.rate_invoiced_at}
        currentTimeSpent={entryBeingEdited.time_spent}
        updateEntry={updateEntry}
      />
    </ContentContainer>
  );
};

const mapStateToProps = ({ company, clients, invoices, users }) => ({
  company,
  clients,
  invoices,
  users
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoice: (payload) => dispatch(fetchInvoiceAction(payload)),
  fetchUsers: (payload) => dispatch(fetchUsersInfoAction(payload)),
  fetchQboItems: (payload) => dispatch(fetchItemsFromQboAction(payload)),
  updateUser: (payload) => dispatch(updateUserAction(payload)),
  updateInvoice: (payload) => dispatch(updateInvoiceAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
