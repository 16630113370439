import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Result } from 'antd';

import Entry from './entry';

const DailyEntries = ({
  entriesProp,
  clients,
  selectedDate,
  allProjects,
  allEntities,
  startDate,
  endDate,
  editEntry,
  deleteEntry,
  adminPage,
  userId
}) => {
  const [entries, setEntries] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState('');

  useEffect(() => {
    setEntries(
      entriesProp.filter(
        (entry) =>
          moment(entry.date) >= moment(selectedDate).startOf('day') &&
          moment(entry.date) <= moment(selectedDate).endOf('day')
      )
    );
  }, [entriesProp, setEntries, selectedDate]);

  useEffect(() => {
    setClientInfo(
      clients
        .map((client) => {
          const clientEntries = entries
            .filter((entry) => entry.client_id === client.id)
            .map((entry) => ({
              ...entry,
              entityName: allEntities.find((entity) => entity.id === entry.entity_id).name,
              projectName: allProjects.find((project) => project.id === entry.project_id).name
            }))
            .sort((a, b) => {
              if (a.entityName.localeCompare(b.entityName))
                return a.entityName.localeCompare(b.entityName);
              return a.projectName.localeCompare(b.projectName);
            });
          return {
            ...client,
            entries: clientEntries,
            totalTime: clientEntries.reduce((tot, curr) => tot + curr.time_spent, 0).toFixed(2),
            entities: client.entities
              .map((entity) => {
                const entityEntries = clientEntries.filter(
                  (entry) => entry.entity_id === entity.id
                );
                return {
                  ...entity,
                  entries: entityEntries,
                  totalTime: entityEntries
                    .reduce((tot, curr) => tot + curr.time_spent, 0)
                    .toFixed(2),
                  projects: entity.projects
                    .map((project) => {
                      const projectEntries = entityEntries.filter(
                        (entry) => entry.project_id === project.id
                      );
                      return {
                        ...project,
                        entries: projectEntries,
                        totalTime: projectEntries
                          .reduce((tot, curr) => tot + curr.time_spent, 0)
                          .toFixed(2)
                      };
                    })
                    .filter((project) => project.entries.length > 0)
                    .sort((a, b) => a.name.localeCompare(b.name))
                };
              })
              .filter((entity) => entity.entries.length > 0)
              .sort((a, b) => a.name.localeCompare(b.name))
          };
        })
        .filter((client) => client.entries.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [entries, clients, allEntities, allProjects]);

  useEffect(() => {
    const projectKeys = clientInfo
      .map((client) =>
        client.entities.map((entity) =>
          entity.projects.map((project) => `${client.id}-${entity.id}-${project.id}`)
        )
      )
      .reduce((tot, curr) => [...tot, ...curr], [])
      .reduce((tot, curr) => [...tot, ...curr], []);

    setSelectedKeys(projectKeys);
  }, [clientInfo]);

  return clientInfo.length ? (
    <div>
      {clientInfo.map((client) =>
        client.entries.map((entry, index) => {
          if (
            selectedKeys.indexOf(`${entry.client_id}-${entry.entity_id}-${entry.project_id}`) !== -1
          ) {
            return (
              <Entry
                allProjects={allProjects}
                key={`entry-${index}`}
                data={entry}
                clientsProp={clients}
                startDate={startDate}
                endDate={endDate}
                editEntry={editEntry}
                deleteEntry={deleteEntry}
                adminPage={adminPage}
                userId={userId}
              />
            );
          } else return null;
        })
      )}
    </div>
  ) : (
    <Result status="warning" title="We can't find anything for you today!" />
  );
};

export default DailyEntries;
