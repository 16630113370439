import React from 'react';
import moment from 'moment';
import { Tooltip, Input, Select, Divider } from 'antd';
import {
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
  DiffOutlined,
  DollarOutlined,
  ImportOutlined,
  QuestionCircleFilled,
  PlusOutlined
} from '@ant-design/icons';

import { SecondaryButton, LinkButton } from './../../../components';
import { currencyFormatter } from './../../../utils/formatters';
import EditEntityPopover from './editEntityPopover';
import AddProjectPopover from './addProjectPopover';
import EditProjectPopover from './editProjectPopover';
import EditMonthlyFeePopover from './editMonthlyFeePopover';

const { Option } = Select;

export const cardDropdownMenu = ({
  company,
  client,
  openClientModal,
  openEntityDrawer,
  openMonthlyFeesDrawer,
  openDeleteModal
}) => [
  {
    title: 'Edit Details',
    icon: <EditOutlined />,
    onClickFn: () => openClientModal(client)
  },
  {
    title: 'Manage Entities',
    icon: <DiffOutlined />,
    onClickFn: () => openEntityDrawer(client)
  },
  {
    title: 'Manage Monthly Fees',
    icon: <DollarOutlined />,
    onClickFn: () => openMonthlyFeesDrawer(client)
  },
  {
    title: 'Delete',
    icon: <DeleteOutlined />,
    onClickFn: () => openDeleteModal({ clientId: client.id })
  }
];

export const addButtonMenu = ({ company, setQuickbooksDrawerOpen, openAddNewClientModal }) => [
  {
    title: !company.qbo_linked ? (
      <Tooltip title="Link company on settings page.">Import QBO Entities</Tooltip>
    ) : (
      'Import QBO Entities'
    ),
    icon: <ImportOutlined />,
    onClickFn: () => setQuickbooksDrawerOpen(true),
    disabled: !company.qbo_linked
  },
  {
    title: 'Add Manually',
    icon: <UserAddOutlined />,
    onClickFn: openAddNewClientModal
  }
];

export const clientCardInfo = ({ client }) => [
  {
    title: 'Email',
    value: client.email || 'No Email'
  },
  {
    title: 'Monthly Fee Enabled',
    value: client.monthly_fee ? 'Yes' : 'No'
  },
  {
    title: 'Monthly Fee',
    value: client.monthly_fee
      ? currencyFormatter.format(client.monthly_fees.find((fee) => !fee.date_ended).amount)
      : 'N/A'
  },
  {
    title: 'Entities',
    value: client.entities.length
  }
];

export const entityDrawerColumns = ({
  openDelete,
  company,
  client,
  updateEntity,
  createProject
}) => [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: 'Quickbooks Id',
    key: 'qbo_customer_id',
    dataIndex: 'qbo_customer_id',
    render: (text) => text && text.split('-')[1]
  },
  {
    title: 'Projects',
    key: 'projects',
    render: (text, record) => record.projects.length
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <div>
        <Tooltip title={`Delete ${record.name}`}>
          <SecondaryButton
            icon={<DeleteOutlined />}
            onClick={() => {
              openDelete({
                obj: { entityId: record.id, clientId: client.id },
                type: 'ENTITY',
                message:
                  'Deleting this entity will delete all associated projects. This will cause issues with reporting and invoicing. It is strongly recommended you do not delete this.'
              });
            }}
            style={{ marginRight: '15px' }}
          />
        </Tooltip>

        <EditEntityPopover
          qboLinked={company.qbo_linked}
          companyQboId={company.qboDetails && company.qboDetails.qbo_company_id}
          client={client}
          updateEntity={updateEntity}
          record={record}
        />

        <AddProjectPopover createProject={createProject} client={client} record={record} />
      </div>
    )
  }
];

export const entityDrawerProjectColumns = ({
  openDelete,
  client,
  updateProject,
  openCustomRatesModal
}) => [
  {
    title: 'Project Name',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: 'Billing Type',
    key: 'billing_type',
    dataIndex: 'billing_type',
    render: (text) => (text === 'No_Charge' ? 'Non-Billable' : `${text} Fee`)
  },
  {
    title: 'Fixed Fee Amount',
    key: 'fixed_fee_amount',
    dataIndex: 'fixed_fee_amount',
    render: (text) => (text ? currencyFormatter.format(text) : 'N/A')
  },
  {
    title: 'Retired',
    key: 'retired',
    dataIndex: 'retired',
    render: (text) => (text ? 'Yes' : 'No')
  },
  {
    title: 'Custom Rates',
    key: 'uses_custom_rates',
    dataIndex: 'uses_custom_rates',
    render: (text, record) => (record.billing_type === 'Hourly' ? (text ? 'Yes' : 'No') : 'N/A')
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <div>
        <Tooltip title={`Delete ${record.name}`}>
          <SecondaryButton
            icon={<DeleteOutlined />}
            onClick={() =>
              openDelete({
                obj: { projectId: record.id, clientId: client.id },
                type: 'PROJECT',
                message:
                  'Deleting this project will cause issues with reporting and invoicing. It is strongly recommended you do not delete this.'
              })
            }
            style={{ marginRight: '15px' }}
          />
        </Tooltip>

        <EditProjectPopover record={record} client={client} updateProject={updateProject} />

        {record.billing_type === 'Hourly' && (
          <Tooltip title="Manage Custom Rates">
            <SecondaryButton
              onClick={() => openCustomRatesModal(record)}
              icon={<DollarOutlined />}
            />
          </Tooltip>
        )}
      </div>
    )
  }
];

export const monthlyFeesColumns = ({ client, updateMonthlyFee }) => [
  {
    title: 'Date Began',
    key: 'date_began',
    dataIndex: 'date_began',
    render: (date) => moment(date).format('MM-DD-YYYY, hh:mm:ss a')
  },
  {
    title: 'Date Ended',
    key: 'date_ended',
    dataIndex: 'date_ended',
    render: (date) => (!!date ? moment(date).format('MM-DD-YYYY, hh:mm:ss a') : 'Current')
  },
  {
    title: 'Monthly Fee',
    key: 'amount',
    dataIndex: 'amount',
    render: (amount) => currencyFormatter.format(amount)
  },
  {
    title: () => (
      <div>
        Edit{' '}
        <Tooltip placement="topRight" title="Cannot edit current fee if monthly fees are disabled.">
          <QuestionCircleFilled style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
    ),
    key: 'edit',
    render: (text, record, index) => (
      <EditMonthlyFeePopover
        currentAmount={record.amount}
        recordId={record.id}
        updateMonthlyFee={updateMonthlyFee}
        disabled={index === 0 && !client.monthly_fee}
      />
    )
  }
];

export const quickbooksDrawerColumns = ({
  setStagedEntities,
  stagedEntities,
  newClient,
  setNewClient,
  addClient,
  allClients,
  removeEntity
}) => [
  {
    title: 'Quickbooks Id',
    dataIndex: 'qbo_customer_id',
    key: 'qbo_customer_id',
    width: 200
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => {
      return (
        <Input
          onChange={(e) =>
            setStagedEntities([
              ...stagedEntities.slice(0, index),
              { ...stagedEntities[index], display_name: e.target.value },
              ...stagedEntities.slice(index + 1)
            ])
          }
          value={record.display_name}
        />
      );
    }
  },
  {
    title: 'Belongs to Client',
    dataIndex: 'client_id',
    key: 'client_id',
    render: (text, record, index) => (
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '100%' }}
        onChange={(id) => {
          setStagedEntities([
            ...stagedEntities.slice(0, index),
            { ...stagedEntities[index], client_id: id },
            ...stagedEntities.slice(index + 1)
          ]);
        }}
        value={record.client_id}
        placeholder="Select Client"
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input
                style={{ flex: 'auto' }}
                value={newClient}
                onChange={(e) => setNewClient(e.target.value)}
              />
              <LinkButton disabled={!newClient} onClick={addClient} icon={<PlusOutlined />}>
                Add Client
              </LinkButton>
            </div>
          </div>
        )}
      >
        {allClients.map((client) => (
          <Option key={client.id}>{client.name}</Option>
        ))}
      </Select>
    )
  },
  {
    title: 'Remove',
    dataIndex: 'remove',
    key: 'remove',
    width: 100,
    align: 'center',
    render: (text, record, index) => (
      <SecondaryButton onClick={() => removeEntity(index)} icon={<DeleteOutlined />} />
    )
  }
];

export const pojectPopoverRadioOptions = ({ monthlyCheck }) => [
  {
    value: 'Monthly',
    name: 'Monthly Fee',
    shouldHide: monthlyCheck
  },
  {
    value: 'Hourly',
    name: 'Hourly Fee'
  },
  {
    value: 'Fixed',
    name: 'Fixed Fee'
  },
  {
    value: 'No_Charge',
    name: 'Non-Billable'
  }
];
