import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Tabs } from 'antd';
import moment from 'moment';

import { FlexRow, UniversalDrawer } from './../../../components';
import { updateRateAction, createRateAction } from '../../../ducks/users';
import AddRatePopover from './addRatePopover';
import { employeeRateColumns } from './constants';

const { TabPane } = Tabs;

const RatesDrawer = ({ employee: selectedEmployee, createRate, updateRate, company, ...rest }) => {
  const [employee, setEmployee] = useState(selectedEmployee);
  const [billingRates, setBillingRates] = useState([]);
  const [paymentRates, setPaymentRates] = useState([]);

  useEffect(() => {
    setEmployee(selectedEmployee);
    if (selectedEmployee.rates) {
      setBillingRates(
        selectedEmployee.rates
          .filter((rate) => rate.type === 'Billing')
          .sort((a, b) => moment(b.date_began).diff(a.date_began))
      );
      setPaymentRates(
        selectedEmployee.rates
          .filter((rate) => rate.type === 'Payment')
          .sort((a, b) => moment(b.date_began).diff(a.date_began))
      );
    }
  }, [selectedEmployee]);

  const columns = employeeRateColumns({ employee, updateRate, company });

  return (
    <UniversalDrawer
      title={
        <FlexRow padding="0" alignItems="center" justifyContent="space-between">
          <div>{`${employee.first_name} ${employee.last_name} Rates`}</div>
          <AddRatePopover
            billingRates={billingRates}
            paymentRates={paymentRates}
            employee={employee}
            createRate={createRate}
          />
        </FlexRow>
      }
      {...rest}
    >
      <Tabs tabPosition="top">
        <TabPane tab="Billing Rates" key="1">
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={billingRates}
          />
        </TabPane>
        <TabPane tab="Payment Rates" key="2">
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={paymentRates}
          />
        </TabPane>
      </Tabs>
    </UniversalDrawer>
  );
};

const mapStateToProps = ({ company }) => ({
  company
});

const mapDispatchToProps = (dispatch) => ({
  createRate: (payload) => dispatch(createRateAction(payload)),
  updateRate: (payload) => dispatch(updateRateAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(RatesDrawer);
