//Actions
export const STOP_ENTRIES_LOADING = 'ENTRIES/STOP_ENTRIES_LOADING';
export const START_ENTRIES_LOADING = 'ENTRIES/START_ENTRIES_LOADING';
export const CREATE_ENTRY = 'ENTRIES/CREATE_ENTRY';
export const STORE_ENTRIES = 'ENTRIES/STORE_ENTRIES';

//Reducer
const initialState = {
  loading: false
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case START_ENTRIES_LOADING:
      return {
        ...state,
        loading: true
      };

    case STOP_ENTRIES_LOADING:
      return {
        ...state,
        loading: false
      };

    case STORE_ENTRIES:
      return {
        ...state
      };

    default:
      return state;
  }
};

//Action Creators
export const stopEntriesLoading = (payload) => ({
  type: STOP_ENTRIES_LOADING,
  payload
});

export const startEntriesLoading = (payload) => ({
  type: START_ENTRIES_LOADING,
  payload
});

export const createEntryAction = (payload) => ({
  type: CREATE_ENTRY,
  payload
});

export const storeEntries = (payload) => ({
  type: STORE_ENTRIES,
  payload
});

export default reducer;
