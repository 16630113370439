import { combineReducers } from 'redux';

import user from './user';
import users from './users';
import company from './company';
import clients from './clients';
import entries from './entries';
import invoices from './invoices';
import reports from './reports';

const appReducer = combineReducers({
  user,
  users,
  company,
  clients,
  entries,
  invoices,
  reports
});

export default appReducer;
