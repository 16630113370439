import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Table, Tag, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  fetchClientsByOrgAction,
  addClientToOrgAction,
  updateClientAction,
  deleteClientAction,
  bulkCreateFromQboAction
} from './../../../ducks/clients';
import { fetchQboCredentialsAction } from '../../../ducks/company';
import {
  DeleteConfirmationModal,
  ContentContainer,
  FlexRow,
  TitleH4,
  PrimaryButton,
  UniversalDropdown,
  PageContentContainer,
  CardNoTitle
} from './../../../components';
import ClientModal from './clientModal';
import QuickbooksDrawer from './quickbooksDrawer';
import EntityDrawer from './entityDrawer';
import MonthlyFeesDrawer from './monthlyFeesDrawer';
import { addButtonMenu, cardDropdownMenu } from './constants';
import { currencyFormatter } from '../../../utils/formatters';

const Clients = ({
  company,
  clients: { clients: clientsProp, loading, stagedQboEntities, qboClientsLoading },
  fetchClientsByOrg,
  addClientToOrg,
  updateClient,
  deleteClient,
  bulkCreateFromQbo,
  fetchQboDetails
}) => {
  const [clientModalOpen, setClientModalOpen] = useState(false);
  const [quickbooksDrawerOpen, setQuickbooksDrawerOpen] = useState(false);
  const [entityDrawerOpen, setEntityDrawerOpen] = useState(false);
  const [monthlyFeesDrawerOpen, setMonthlyFeesDrawerOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ id: null });
  const [addingNewClient, setAddingNewClient] = useState(false);
  const [clients, setClients] = useState(clientsProp.sort((a, b) => a.name.localeCompare(b.name)));
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [clientToBeDeleted, setClientToBeDeleted] = useState({});

  useEffect(() => {
    if (company.orgId) {
      fetchClientsByOrg();
      fetchQboDetails();
    }
  }, [company.orgId, fetchClientsByOrg, fetchQboDetails]);

  useEffect(() => {
    if (selectedClient.id) {
      setSelectedClient(clientsProp.find((client) => client.id === selectedClient.id));
    }
    setClients(clientsProp.sort((a, b) => a.name.localeCompare(b.name)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsProp]);

  const openClientModal = (client) => {
    setClientModalOpen(true);
    setSelectedClient(client);
  };

  const closeAllModalsAndDrawers = () => {
    setClientModalOpen(false);
    setSelectedClient({ id: null });
    setAddingNewClient(false);
    setEntityDrawerOpen(false);
    setMonthlyFeesDrawerOpen(false);
    setQuickbooksDrawerOpen(false);
    setDeleteModalVisible(false);
    setClientToBeDeleted({});
  };

  const openAddNewClientModal = () => {
    setClientModalOpen(true);
    setAddingNewClient(true);
  };

  const openEntityDrawer = (client) => {
    setEntityDrawerOpen(true);
    setSelectedClient(client);
  };

  const openMonthlyFeesDrawer = (client) => {
    setMonthlyFeesDrawerOpen(true);
    setSelectedClient(client);
  };

  const openDeleteModal = (clientObj) => {
    setDeleteModalVisible(true);
    setClientToBeDeleted(clientObj);
  };

  const onDeleteClient = () => {
    deleteClient(clientToBeDeleted);
    setDeleteModalVisible(false);
    setClientToBeDeleted({});
  };

  const filterClients = (e) => {
    setClients(
      clientsProp.filter((client) =>
        client.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Monthly Fee Enabled',
      dataIndex: 'monthly_fee',
      render: (_, { monthly_fee }) => {
        return monthly_fee ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
      }
    },
    {
      title: 'Monthly Fee',
      dataIndex: 'monthly_fees',
      render: (_, client) => {
        return client.monthly_fee ? (
          <Tag color="green">
            {currencyFormatter.format(client.monthly_fees.find((fee) => !fee.date_ended).amount)}
          </Tag>
        ) : (
          <Tag>N/A</Tag>
        );
      }
    },
    {
      title: 'Entities',
      dataIndex: 'entities',
      render: (_, { entities }) => {
        return entities.length;
      }
    },
    {
      title: '',
      dataIndex: 'unique',
      render: (_, client) => {
        return (
          <UniversalDropdown
            baseItem={<Button type="link">Manage</Button>}
            menu={cardDropdownMenu({
              company,
              client,
              openClientModal,
              openEntityDrawer,
              openMonthlyFeesDrawer,
              openDeleteModal
            })}
          />
        );
      }
    }
  ];

  return (
    <ContentContainer loading={loading} loadingTip="Loading Clients...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>{`${company.name} Clients`}</TitleH4>

        <div>
          <Input.Search
            placeholder="Search Clients"
            onChange={filterClients}
            style={{ width: 300, marginRight: '10px' }}
          />

          <UniversalDropdown
            baseItem={
              <PrimaryButton>
                Add <DownOutlined />
              </PrimaryButton>
            }
            menu={addButtonMenu({ company, setQuickbooksDrawerOpen, openAddNewClientModal })}
          />
        </div>
      </FlexRow>

      <PageContentContainer>
        <CardNoTitle>
          <Table
            bordered
            size="small"
            pagination={false}
            columns={columns}
            dataSource={clients && clients.length ? clients : []}
          />
        </CardNoTitle>
      </PageContentContainer>

      <DeleteConfirmationModal
        visible={deleteModalVisible}
        emphasize={true}
        deleteFn={onDeleteClient}
        cancelFn={closeAllModalsAndDrawers}
        warningMessage="Deleting this client will remove them from all reports. All entities and projects associated with them will also be deleted. It is highly recommended you do not delete clients."
      />

      <ClientModal
        client={selectedClient}
        visible={clientModalOpen}
        onCancel={closeAllModalsAndDrawers}
        addingNewClient={addingNewClient}
        addClientToOrg={addClientToOrg}
        updateClient={updateClient}
      />

      <QuickbooksDrawer
        visible={quickbooksDrawerOpen}
        onClose={closeAllModalsAndDrawers}
        stagedEntitiesProp={stagedQboEntities}
        loading={qboClientsLoading}
        clientsProp={clientsProp}
        bulkCreateFromQbo={bulkCreateFromQbo}
      />

      <EntityDrawer
        visible={entityDrawerOpen}
        client={selectedClient}
        closable={false}
        onClose={closeAllModalsAndDrawers}
      />

      <MonthlyFeesDrawer
        visible={monthlyFeesDrawerOpen}
        client={selectedClient}
        closable={false}
        onClose={closeAllModalsAndDrawers}
      />
    </ContentContainer>
  );
};

const mapStateToProps = ({ company, clients }) => ({
  company,
  clients
});

const mapDispatchToProps = (dispatch) => ({
  fetchQboDetails: (payload) => dispatch(fetchQboCredentialsAction(payload)),
  fetchClientsByOrg: (payload) => dispatch(fetchClientsByOrgAction(payload)),
  addClientToOrg: (payload) => dispatch(addClientToOrgAction(payload)),
  updateClient: (payload) => dispatch(updateClientAction(payload)),
  deleteClient: (payload) => dispatch(deleteClientAction(payload)),
  bulkCreateFromQbo: (payload) => dispatch(bulkCreateFromQboAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
