import React, { useState } from 'react';
import { Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { PrimaryButton, SecondaryButton, ButtonRow, UniversalInput } from './../../../components';

const AddEntityPopover = ({ createEntity, clients, client, qboLinked, companyQboId }) => {
  const [entityPopoverVisible, setEntityPopoverVisible] = useState(false);
  const [newEntityName, setNewEntityName] = useState('');
  const [newEntityQBOId, setNewEntityQBOId] = useState(null);

  const cancelAdd = () => {
    setEntityPopoverVisible(false);
    setNewEntityName('');
    setNewEntityQBOId(null);
  };

  const addEntity = () => {
    createEntity({
      name: newEntityName,
      client_id: client.id,
      qbo_customer_id: newEntityQBOId && `${companyQboId}-${newEntityQBOId}`
    });
    cancelAdd();
  };

  return (
    <Popover
      trigger="click"
      visible={entityPopoverVisible}
      onVisibleChange={(visible) => setEntityPopoverVisible(visible)}
      title="Add Entity"
      placement="bottomRight"
      overlayStyle={{ minWidth: '300px' }}
      content={
        <div>
          <UniversalInput
            title="Name*"
            inputValue={newEntityName}
            onChange={(e) => setNewEntityName(e.target.value)}
          />

          {qboLinked && (
            <UniversalInput
              title="Quckbooks Id"
              inputValue={newEntityQBOId}
              onChange={(e) => setNewEntityQBOId(e.target.value)}
            />
          )}

          <ButtonRow>
            <SecondaryButton onClick={cancelAdd} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={addEntity} disabled={!newEntityName}>
              Add
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <PrimaryButton loading={clients.entitiesLoading} icon={<PlusOutlined />}>
        Add Entity
      </PrimaryButton>
    </Popover>
  );
};

export default AddEntityPopover;
