import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// import Sidebar from './Sidebar';
import { PageWithSidebar, Page } from './Layouts';
import TimerModule from './Timer';

export const AuthenticatedRoute = ({ requiredAuthLevel, userAuthLevel, component: C, ...rest }) => {
  const loggedIn = !!userAuthLevel;
  const allowed = userAuthLevel && userAuthLevel <= requiredAuthLevel;

  return (
    <Route
      {...rest}
      render={(props) =>
        allowed ? (
          <PageWithSidebar>
            {/* <Sidebar /> */}
            <Page>
              <C {...props} />
            </Page>
            <TimerModule />
          </PageWithSidebar>
        ) : loggedIn ? (
          <Redirect to="/notFound" />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
