import React from 'react';
import { Spin } from 'antd';

import { FullPageLoadingContainer } from './../Layouts';
import { DrawerLoaderContainer } from './../Layouts/styled-components';

export const FullPageLoader = ({ contentContainerSize, tip }) => (
  <FullPageLoadingContainer contentContainerSize={contentContainerSize}>
    <Spin tip={tip} />
  </FullPageLoadingContainer>
);

export const DrawerLoader = ({ tip }) => (
  <DrawerLoaderContainer>
    <Spin tip={tip} />
  </DrawerLoaderContainer>
);
