import { takeLatest, call, put } from 'redux-saga/effects';

import {
  FETCH_USERS_INFO,
  storeUsersInfo,
  stopUsersLoading,
  startUsersLoading,
  DELETE_USER,
  UPDATE_USER,
  ADD_USER_TO_ORG,
  startRatesLoading,
  stopRatesLoading,
  CREATE_RATE,
  UPDATE_RATE,
  stopQboUsersLoading,
  startQboUsersLoading,
  FETCH_USERS_FROM_QBO,
  storeStagedQboUsers,
  BULK_CREATE_FROM_QBO,
  FETCH_ITEMS_FROM_QBO,
  storeItemsFromQbo,
  stopQboItemsLoading,
  startQboItemsLoading
} from './index';
import Api from './../../utils/api-wrapper';

export function* fetchUsersInfo() {
  try {
    yield put(startUsersLoading());
    const { users } = yield call(Api.fetchUsersByOrg, {});
    yield put(storeUsersInfo({ users }));
  } catch (err) {
    yield put(stopUsersLoading());
    console.log(err);
  }
}

export function* addUserToOrg({ payload }) {
  try {
    yield put(startUsersLoading());
    yield call(Api.createUser, { data: { ...payload } });
    yield call(fetchUsersInfo, {});
  } catch (err) {
    yield put(stopUsersLoading());
    console.log(err);
  }
}

export function* updateUser({ payload: { data, userId } }) {
  try {
    yield put(startUsersLoading());
    yield call(Api.updateUser, { data, userId });
    yield call(fetchUsersInfo, {});
  } catch (err) {
    yield put(stopUsersLoading());
    console.log(err);
  }
}

export function* deleteUser({ payload: { userId } }) {
  try {
    yield put(startUsersLoading());
    yield call(Api.deleteUser, { userId });
    yield call(fetchUsersInfo, {});
  } catch (err) {
    yield put(stopUsersLoading());
    console.log(err);
  }
}

export function* createRate({ payload }) {
  try {
    yield put(startRatesLoading());
    yield call(Api.createRate, { data: { ...payload } });
    yield call(fetchUsersInfo, {});
  } catch (err) {
    yield put(stopRatesLoading());
    console.log(err);
  }
}

export function* updateRate({ payload: { data, rateId } }) {
  try {
    yield put(startRatesLoading());
    yield call(Api.updateRate, { data, rateId });
    yield call(fetchUsersInfo, {});
  } catch (err) {
    yield put(stopRatesLoading());
    console.log(err);
  }
}

export function* fetchFromQuickbooks() {
  try {
    yield put(startQboUsersLoading());
    const { employees } = yield call(Api.fetchUsersFromQbo, {});
    const { items } = yield call(Api.fetchItemsFromQbo, {});
    yield put(storeStagedQboUsers({ stagedEmployees: employees, items }));
  } catch (err) {
    yield put(stopQboUsersLoading());
    console.log(err);
  }
}

export function* bulkCreateFromQuickbooks({ payload: { newEmployees } }) {
  try {
    yield put(startQboUsersLoading());
    yield call(Api.bulkCreateUsers, {
      data: { newEmployees }
    });
    yield put(storeStagedQboUsers({ stagedEmployees: [] }));
    yield call(fetchUsersInfo, {});
  } catch (err) {
    yield put(stopQboUsersLoading());
    console.log(err);
  }
}

export function* fetchItemsFromQuickbooks() {
  try {
    yield put(startQboItemsLoading());
    const { items } = yield call(Api.fetchItemsFromQbo, {});
    yield put(storeItemsFromQbo({ items }));
  } catch (err) {
    yield put(stopQboItemsLoading());
    console.log(err);
  }
}

export const usersSagas = [
  takeLatest(FETCH_USERS_INFO, fetchUsersInfo),
  takeLatest(ADD_USER_TO_ORG, addUserToOrg),
  takeLatest(UPDATE_USER, updateUser),
  takeLatest(DELETE_USER, deleteUser),
  takeLatest(CREATE_RATE, createRate),
  takeLatest(UPDATE_RATE, updateRate),
  takeLatest(FETCH_USERS_FROM_QBO, fetchFromQuickbooks),
  takeLatest(BULK_CREATE_FROM_QBO, bulkCreateFromQuickbooks),
  takeLatest(FETCH_ITEMS_FROM_QBO, fetchItemsFromQuickbooks)
];

export default usersSagas;
