import React, { useState, useEffect } from 'react';
import { Popover, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { PrimaryButton, SecondaryButton, ButtonRow, UniversalInput } from './../../../components';

const EditEntityPopover = ({ client, record, updateEntity, qboLinked, companyQboId }) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');
  const [qboId, setQboId] = useState(null);

  useEffect(() => {
    if (visible) {
      setName(record.name);
      setQboId(record.qbo_customer_id && record.qbo_customer_id.split('-')[1]);
    }
  }, [visible, record.name, record.qbo_customer_id, setName, setQboId]);

  const cancelEdit = () => {
    setVisible(false);
    setName('');
    setQboId(null);
  };

  const callEditEntity = () => {
    updateEntity({
      data: {
        name,
        qbo_customer_id: qboId && `${companyQboId}-${qboId}`
      },
      entityId: record.id,
      clientId: client.id
    });
    cancelEdit();
  };

  const isDifferent =
    qboId !== (record.qbo_customer_id && record.qbo_customer_id.split('-')[1]) ||
    name !== record.name;

  return (
    <Popover
      trigger="click"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      title="Edit Entity"
      placement="bottomRight"
      overlayStyle={{ minWidth: '300px' }}
      content={
        <div>
          <UniversalInput title="Name*" value={name} onChange={(e) => setName(e.target.value)} />

          {qboLinked && (
            <UniversalInput
              title="Quckbooks Id"
              value={qboId}
              onChange={(e) => setQboId(e.target.value)}
            />
          )}

          <ButtonRow>
            <SecondaryButton onClick={cancelEdit} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={!name || !isDifferent} onClick={callEditEntity}>
              Edit
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <Tooltip title={`Edit ${record.name}`}>
        <SecondaryButton style={{ marginRight: '15px' }} icon={<EditOutlined />} />
      </Tooltip>
    </Popover>
  );
};

export default EditEntityPopover;
