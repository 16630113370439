import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Result } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import {
  PrimaryButton,
  ContentContainer,
  TitleH4,
  TitledData,
  PageContentContainer,
  AvatarCard
} from './../../../components';
import { fetchQboCredentialsAction, updateOrgAction } from '../../../ducks/company';
import { fetchUsersInfoAction } from '../../../ducks/users';
import EditCompanyPopover from './editCompanyPopover';
import { LogoContainer } from './styled-components';

const AdminSettings = ({ company, fetchQboDetails, updateCompany, fetchUsersInfo, users }) => {
  useEffect(() => {
    if (company.orgId) {
      fetchQboDetails({ organizationId: company.orgId });
      fetchUsersInfo({ organizationId: company.orgId });
    }
  }, [company.orgId, fetchQboDetails, fetchUsersInfo]);

  return (
    <ContentContainer loading={company.loading || users.loading} loadingTip="Loading Settings...">
      <TitleH4>{`${company.name} Settings`}</TitleH4>

      <PageContentContainer>
        <AvatarCard
          action={
            <EditCompanyPopover
              updateCompany={updateCompany}
              currentName={company.name}
              organizationId={company.orgId}
              currentDefaultInvoiceApprover={company.default_invoice_approver}
              users={users.users}
            />
          }
          style={{ marginTop: '20px' }}
          name={`${company.name} Information`}
        >
          <TitledData title="Name" value={company.name} />
          <TitledData
            title="Default Invoice Approver"
            value={
              company.default_invoice_approver
                ? users.users &&
                  users.users.length &&
                  `${
                    users.users.find((user) => user.id === company.default_invoice_approver)
                      .first_name
                  } ${
                    users.users.find((user) => user.id === company.default_invoice_approver)
                      .last_name
                  } `
                : 'None'
            }
          />
          <TitledData
            title="Logo"
            value={
              !!company.link_to_logo ? (
                <LogoContainer>
                  <img src={company.link_to_logo} alt="company-logo" />
                </LogoContainer>
              ) : (
                <LogoContainer>
                  <Result
                    status="404"
                    title="Ooops..."
                    subTitle="Looks like we don't have a logo for you! Please contact customer support."
                  />
                </LogoContainer>
              )
            }
          />
        </AvatarCard>

        <AvatarCard
          action={
            <a href={`${process.env.REACT_APP_API_URL}/auth/qbo/authOrg`}>
              <PrimaryButton icon={<LinkOutlined />}>
                {company.qbo_linked ? 'Re-Link' : 'Link'}
              </PrimaryButton>
            </a>
          }
          style={{ marginTop: '20px' }}
          name="Quickbooks Online Information"
        >
          <TitledData title="Linked To QBO" value={`${company.qbo_linked ? 'Yes' : 'No'}`} />
          <TitledData
            title="QBO Company Id"
            value={`${company.qboDetails.qbo_company_id || 'N/A'}`}
            code
          />
          <TitledData
            title="QBO Access Token"
            value={`${company.qboDetails.qbo_access_token || 'N/A'}`}
            style={{ marginBottom: '20px' }}
            code
          />
        </AvatarCard>
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ company, users }) => ({
  company,
  users
});

const mapDispatchToProps = (dispatch) => ({
  fetchQboDetails: (payload) => dispatch(fetchQboCredentialsAction(payload)),
  updateCompany: (payload) => dispatch(updateOrgAction(payload)),
  fetchUsersInfo: (payload) => dispatch(fetchUsersInfoAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
