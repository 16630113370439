import React from 'react';
import { Popconfirm } from 'antd';
import { StyledTimerAvatar, StyledCloseCircle } from './styled-components';

const TimerAvatar = ({ paused, noDelete, icon, deleteFn, ...rest }) => {
  return (
    <StyledTimerAvatar paused={paused} {...rest}>
      {!noDelete && (
        <Popconfirm
          placement="left"
          title="Are you sure?"
          onConfirm={e => {
            e.stopPropagation();
            deleteFn && deleteFn();
          }}
          onCancel={e => e.stopPropagation()}
          okText="Yes"
          cancelText="No"
        >
          <StyledCloseCircle onClick={e => e.stopPropagation()} />
        </Popconfirm>
      )}

      {icon}
    </StyledTimerAvatar>
  );
};

export default TimerAvatar;
