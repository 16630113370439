//Actions
export const STOP_INVOICES_LOADING = 'INVOICES/STOP_INVOICES_LOADING';
export const START_INVOICES_LOADING = 'INVOICES/START_INVOICES_LOADING';
export const CREATE_INVOICES = 'INVOICES/CREATE_INVOICES';
export const FETCH_NEW_INVOICE_INFO = 'INVOICES/FETCH_NEW_INVOICE_INFO';
export const STORE_NEW_INVOICE_INFO = 'INVOICES/STORE_NEW_INVOICE_INFO';
export const FETCH_IN_PROGRESS_INVOICES = 'INVOICES/FETCH_IN_PROGRESS_INVOICES';
export const STORE_IN_PROGRESS_INVOICES = 'INVOICES/STORE_IN_PROGRESS_INVOICES';
export const FETCH_INVOICES_AWAITING_APPROVAL = 'INVOICES/FETCH_INVOICES_AWAITING_APPROVAL';
export const STORE_INVOICES_AWAITING_APPROVAL = 'INVOICES/STORE_INVOICES_AWAITING_APPROVAL';
export const STORE_INVOICES_SENT_TO_QBO = 'INVOICES/STORE_INVOICES_SENT_TO_QBO';
export const FETCH_INVOICES_SENT_TO_QBO = 'INVOICES/FETCH_INVOICES_SENT_TO_QBO';
export const FETCH_INVOICE = 'INVOICES/FETCH_INVOICE';
export const STORE_INVOICE = 'INVOICES/STORE_INVOICE';
export const UPDATE_INVOICE = 'INVOICES/UPDATE_INVOICE';
export const DELETE_INVOICE = 'INVOICES/DELETE_INVOICE';

//Reducer
const initialState = {
  inProgressInvoices: [],
  invoicesAwaitingApproval: [],
  invoicesSentToQbo: [],
  newInvoicesInfo: {
    countByEntity: [],
    countByClient: []
  },
  editingInvoice: {
    sent_to_qbo: false,
    ready_for_approval: false,
    approved_by: null,
    approved_at: null,
    entry_ids: [],
    entity_id: null,
    organization_id: null,
    total_time: 0,
    total_amount: 0,
    start_date: null,
    end_date: null,
    entries: []
  },
  loading: false
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case START_INVOICES_LOADING:
      return {
        ...state,
        loading: true
      };

    case STOP_INVOICES_LOADING:
      return {
        ...state,
        loading: false
      };

    case STORE_NEW_INVOICE_INFO:
      return {
        ...state,
        newInvoicesInfo: {
          countByClient: action.payload.countByClient,
          countByEntity: action.payload.countByEntity
        },
        loading: false
      };

    case STORE_INVOICES_AWAITING_APPROVAL:
      return {
        ...state,
        invoicesAwaitingApproval: action.payload.invoices,
        loading: false
      };

    case STORE_INVOICES_SENT_TO_QBO:
      return {
        ...state,
        invoicesSentToQbo: action.payload.invoices,
        loading: false
      };

    case STORE_INVOICE:
      return {
        ...state,
        editingInvoice: action.payload.invoiceInfo,
        loading: false
      };

    case STORE_IN_PROGRESS_INVOICES:
      return {
        ...state,
        loading: false,
        inProgressInvoices: action.payload.newInvoices
      };

    default:
      return state;
  }
};

//Action Creators
export const stopInvoicesLoading = (payload) => ({
  type: STOP_INVOICES_LOADING,
  payload
});

export const startInvoicesLoading = (payload) => ({
  type: START_INVOICES_LOADING,
  payload
});

export const createInvoicesAction = (payload) => ({
  type: CREATE_INVOICES,
  payload
});

export const fetchNewInvoiceInfoAction = (payload) => ({
  type: FETCH_NEW_INVOICE_INFO,
  payload
});

export const fetchInProgressInvoicesAction = (payload) => ({
  type: FETCH_IN_PROGRESS_INVOICES,
  payload
});

export const storeNewInvoiceInfo = (payload) => ({
  type: STORE_NEW_INVOICE_INFO,
  payload
});

export const storeInProgressInvoices = (payload) => ({
  type: STORE_IN_PROGRESS_INVOICES,
  payload
});

export const fetchInvoiceAction = (payload) => ({
  type: FETCH_INVOICE,
  payload
});

export const storeInvoiceInfo = (payload) => ({
  type: STORE_INVOICE,
  payload
});

export const updateInvoiceAction = (payload) => ({
  type: UPDATE_INVOICE,
  payload
});

export const storeInvoicesAwaitingApproval = (payload) => ({
  type: STORE_INVOICES_AWAITING_APPROVAL,
  payload
});

export const fetchInvoicesAwaitingApprovalAction = (payload) => ({
  type: FETCH_INVOICES_AWAITING_APPROVAL,
  payload
});

export const storeInvoicesSentToQuickbooks = (payload) => ({
  type: STORE_INVOICES_SENT_TO_QBO,
  payload
});

export const fetchInvoicesSentToQuickbooksAction = (payload) => ({
  type: FETCH_INVOICES_SENT_TO_QBO,
  payload
});

export const deleteInvoiceAction = (payload) => ({
  type: DELETE_INVOICE,
  payload
});

export default reducer;
