import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import dotenv from 'dotenv';

import Routes from './Routes';
import AppHeader from './components/AppHeader';
import { fetchUserInfoAction } from './ducks/user';
import { fetchClientsByOrgAction } from './ducks/clients';
import { FullPageLoader } from './components';
import './table.css';

dotenv.config();

function App({ user, fetchUserInfo, company, clients, fetchClientsByOrg }) {
  const history = useHistory();

  useEffect(() => {
    if (!user.userId) {
      fetchUserInfo();
    } else if (history.location.pathname === '/') {
      history.push('/user/time');
    }
  }, [user.userId, fetchUserInfo, history, history.location.pathname]);

  useEffect(() => {
    if (!!company.orgId && clients.count === 0) {
      fetchClientsByOrg({ organizationId: company.orgId });
    }
  }, [company.orgId, clients.count, fetchClientsByOrg]);

  return user.loading ? (
    <FullPageLoader tip="Loading" />
  ) : (
    <div>
      <AppHeader user={user} company={company} />
      <Routes userAuthLevel={user.role_id} />
    </div>
  );
}

const mapStateToProps = ({ user, company, clients }) => ({
  user,
  company,
  clients
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserInfo: (payload) => dispatch(fetchUserInfoAction(payload)),
  fetchClientsByOrg: (payload) => dispatch(fetchClientsByOrgAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
