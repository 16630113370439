import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { findPayPeriodDates } from './../../../utils/moment-utils.js';
import { fetchUserDetailDataAction } from './../../../ducks/reports';
import { deleteEntryAction, editEntryAction } from './../../../ducks/user';
import { fetchUsersInfoAction } from './../../../ducks/users';
import {
  ContentContainer,
  FlexRow,
  PageContentContainer,
  UniversalDatePicker,
  AvatarCard,
  UniversalSelect
} from './../../../components';
import PayPeriodSummary from './../../userTime/payPeriodSummary';
import DailyEntries from './../../userTime/dailyEntries';
import ClientBreakdown from './../../userTime/clientBreakdown';

const UserTime = ({
  company,
  clients: { clients, allEntities, allProjects },
  users: { users, loading: userLoading },
  fetchUserDetailData,
  reports: {
    loading,
    userDetailData: { clientInfo, daysInRange, countByUser, entries }
  },
  deleteEntry,
  editEntry,
  fetchUsersInfo
}) => {
  const [dateRange, setDateRange] = useState(findPayPeriodDates(moment()));
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    fetchUsersInfo({ organizationId: company.orgId });
  }, [fetchUsersInfo, company.orgId]);

  useEffect(() => {
    if (selectedUserId) {
      fetchUserDetailData({
        userId: selectedUserId,
        startDate: moment(dateRange[0]).startOf('day').utc().format(),
        endDate: moment(dateRange[dateRange.length - 1])
          .endOf('day')
          .utc()
          .format()
      });
    }
  }, [dateRange, fetchUserDetailData, selectedUserId]);

  useEffect(() => {
    if (selectedUserId) {
      setSelectedUser(users.find((user) => user.id === selectedUserId));
    }
  }, [selectedUserId, users]);

  useEffect(() => {
    const exists = !!dateRange.filter(
      (date) => moment(selectedDate).format('MM/DD/YYYY') === moment(date).format('MM/DD/YYYY')
    ).length;

    if (!exists) {
      const range = findPayPeriodDates(selectedDate);
      setDateRange(range);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const updateSelectedDate = (date) => {
    setSelectedDate(date);
  };

  const disabledDates = (current) => current >= moment().endOf('day');

  return (
    <ContentContainer loading={loading || userLoading} loadingTip="Loading User Data...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <UniversalSelect
          title="Details for"
          selectValue={selectedUserId}
          onChange={(id) => setSelectedUserId(id)}
          options={
            users.length &&
            users
              .map((user) => ({
                key: user.id,
                value: user.id,
                name: `${user.first_name} ${user.last_name}`
              }))
              .sort((a, b) => a.name.localeCompare(b.name))
          }
          placeholder="Select User"
        />

        <UniversalDatePicker
          disabledDates={disabledDates}
          value={selectedDate}
          onChange={updateSelectedDate}
          title="Selected Date"
          style={{ width: '300px' }}
        />
      </FlexRow>

      {selectedUserId && entries ? (
        <PageContentContainer padding="0px 20px 80px 0px">
          <AvatarCard
            style={{ marginTop: '20px' }}
            name={`Summary for ${moment(dateRange[0]).format('MM/DD/YYYY')} - ${moment(
              dateRange[dateRange.length - 1]
            ).format('MM/DD/YYYY')}`}
          >
            <PayPeriodSummary
              countByUser={countByUser}
              datesProp={dateRange}
              entriesProp={entries}
              clients={clients}
              rates={selectedUser.rates}
              selectedDate={selectedDate}
              goalDailyHours={selectedUser.goal_daily_hours}
              setSelectedDate={updateSelectedDate}
            />
          </AvatarCard>

          <AvatarCard
            style={{ marginTop: '20px' }}
            name={`Work Entries for ${moment(selectedDate).format('MM/DD/YYYY')}`}
          >
            <DailyEntries
              startDate={moment(dateRange[0]).startOf('day').utc().format()}
              endDate={moment(dateRange[dateRange.length - 1])}
              allEntities={allEntities}
              allProjects={allProjects}
              entriesProp={entries}
              clients={clients}
              selectedDate={selectedDate}
              deleteEntry={deleteEntry}
              editEntry={editEntry}
              adminPage={true}
              userId={selectedUserId}
            />
          </AvatarCard>

          <AvatarCard
            style={{ marginTop: '20px', marginBottom: '40px' }}
            name="Hours by Client, Entity, and Project"
          >
            <ClientBreakdown clientInfo={clientInfo} daysInRange={daysInRange} />
          </AvatarCard>
        </PageContentContainer>
      ) : (
        <PageContentContainer>Please select a user!</PageContentContainer>
      )}
    </ContentContainer>
  );
};

const mapStateToProps = ({ users, clients, reports, company }) => ({
  users,
  clients,
  reports,
  company
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetailData: (payload) => dispatch(fetchUserDetailDataAction(payload)),
  deleteEntry: (payload) => dispatch(deleteEntryAction(payload)),
  editEntry: (payload) => dispatch(editEntryAction(payload)),
  fetchUsersInfo: (payload) => dispatch(fetchUsersInfoAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTime);
