//Actions
export const STORE_ORG_INFO = 'ORG/STORE_ORG_INFO';
export const START_ORG_LOADING = 'ORG/START_ORG_LOADING';
export const STOP_ORG_LOADING = 'ORG/STOP_ORG_LOADING';
export const FETCH_QBO_DETAILS = 'ORG/FETCH_QBO_DETAILS';
export const STORE_QBO_DETAILS = 'ORG/STORE_QBO_DETAILS';
export const UPDATE_ORG = 'ORG/UPDATE_ORG';

//Reducer
const initialState = {
  orgId: '',
  name: '',
  link_to_logo: '',
  theme_color: '',
  loading: false,
  qbo_linked: false,
  qboDetails: {
    qbo_company_id: '',
    qbo_access_token: ''
  }
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case STORE_ORG_INFO:
      const { id, ...rest } = action.payload;
      return {
        ...state,
        orgId: id,
        loading: false,
        ...rest
      };

    case STORE_QBO_DETAILS:
      const { qbo_linked, qbo_company_id, qbo_access_token } = action.payload;
      return {
        ...state,
        qbo_linked,
        qboDetails: {
          qbo_company_id,
          qbo_access_token
        },
        loading: false
      };

    case START_ORG_LOADING:
      return {
        ...state,
        loading: true
      };

    case STOP_ORG_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

//Action Creators
export const storeOrgInfo = (payload) => ({
  type: STORE_ORG_INFO,
  payload
});

export const startOrgLoading = (payload) => ({
  type: START_ORG_LOADING,
  payload
});

export const stopOrgLoading = (payload) => ({
  type: STOP_ORG_LOADING,
  payload
});

export const fetchQboCredentialsAction = (payload) => ({
  type: FETCH_QBO_DETAILS,
  payload
});

export const storeQboCredentialsAction = (payload) => ({
  type: STORE_QBO_DETAILS,
  payload
});

export const updateOrgAction = (payload) => ({
  type: UPDATE_ORG,
  payload
});

export default reducer;
