export const COLORS = {
  primaryColor: '#51C1B0',
  linkcolor: '#51C1B0',
  successColor: '#51C1B0',
  warningColor: '#FEB97F',
  errorColor: '#c21807',
  fontSize: '16px',
  headingColor: 'rgba(0, 0, 0, 0.85)',
  textColor: 'rgba(0, 0, 0, 0.65)',
  textColorSecondary: '#f5a623',
  DisabledColor: 'rgba(0, 0, 0, .25)',
  borderRadius: '4px',
  borderColor: '#d9d9d9',
  boxShadow: '0 9px 32px -12px rgba(0, 0, 0, 0.8)',
  buttonHeight: '36px',
  outlineWidth: '0px',
  secondaryOrange: '#FEB97F',
  backgroundColor: '#f1f2f5',
  gradientBaseColor: 'rgb(75, 209, 189)',
  primaryGradient: 'linear-gradient(90deg, rgba(75, 209, 189, 1) 8%, rgba(81, 193, 176, 1) 100%)'
};
