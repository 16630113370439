import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Clients from './views/admin/clients';
import Employees from './views/admin/employees';
import CreateInvoices from './views/admin/invoicing/createInvoices';
import InvoicesAwaitingApproval from './views/admin/invoicing/invoicesAwaitingApproval';
import PastInvoices from './views/admin/invoicing/pastInvoices';
import EditInvoice from './views/admin/invoicing/editInvoice';
import MonthlyFeeAudit from './views/admin/reporting/monthlyFeeAudit';
import PayrollReport from './views/admin/reporting/payrollReport';
import UserDetail from './views/admin/reporting/userDetail';
import AdminSettings from './views/admin/adminSettings';
import UserSettings from './views/userSettings';
import UserTime from './views/userTime';
import UserReporting from './views/userTime/reporting';
import NotFound from './views/notFound';
import UserNotFound from './views/userNotFound';
import Home from './views/home';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import PriacyPolicy from './views/legal/privacyPolicy';
import TermsOfService from './views/legal/termsOfService';
import { USER, ADMIN } from './constants/roles';

const Routes = ({ userAuthLevel }) => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/userNotFound" exact component={UserNotFound} />
    <Route path="/terms" exact component={TermsOfService} />
    <Route path="/privacy" exact component={PriacyPolicy} />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/clients"
      exact
      component={Clients}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/employees"
      exact
      component={Employees}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/invoices/sent"
      exact
      component={PastInvoices}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/invoices/create"
      exact
      component={CreateInvoices}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/invoices/awaiting"
      exact
      component={InvoicesAwaitingApproval}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/invoices/edit/:invoiceId"
      exact
      component={EditInvoice}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/reports/monthlyFeeAudit"
      exact
      component={MonthlyFeeAudit}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/reports/payroll"
      exact
      component={PayrollReport}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/reports/userDetail"
      exact
      component={UserDetail}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={ADMIN}
      path="/admin/settings"
      exact
      component={AdminSettings}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={USER}
      path="/user/settings"
      exact
      component={UserSettings}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={USER}
      path="/user/time"
      exact
      component={UserTime}
    />
    <AuthenticatedRoute
      userAuthLevel={userAuthLevel}
      requiredAuthLevel={USER}
      path="/user/reports"
      exact
      component={UserReporting}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
