import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { PrimaryButton, SecondaryButton, DashedButton } from './../../components/Buttons';
import {
  TitleH1,
  TitleH2,
  TitleH3,
  TitleH4,
  Text,
  WarningText,
  ErrorText,
  DisabledText,
  MarkedText,
  CodeText,
  UnderlinedText,
  DeletedText,
  StrongText,
  EditableText
} from './../../components/Text';
import { FlexRow, Page } from './../../components/Layouts';

const Home = () => {
  const [editableText, setEditableText] = useState('EditableText');

  return (
    <Page>
      <FlexRow>
        <TitleH1 margin="0px 15px 0px 0px">H1. Primary</TitleH1>
        <TitleH1 margin="0px 15px 0px 0px" type="secondary">
          H1. Secondary
        </TitleH1>
        <TitleH1 margin="0px 15px 0px 0px" type="warning">
          H1. Warning
        </TitleH1>
        <TitleH1 margin="0px 15px 0px 0px" type="danger">
          H1. Error
        </TitleH1>
      </FlexRow>
      <FlexRow>
        <TitleH2 margin="0px 15px 0px 0px">H2. Heading</TitleH2>
      </FlexRow>
      <FlexRow>
        <TitleH3 margin="0px 15px 0px 0px">H3. Heading</TitleH3>
      </FlexRow>
      <FlexRow>
        <TitleH4 margin="0px 15px 0px 0px">H4. Heading</TitleH4>
      </FlexRow>
      <FlexRow>
        <Text margin="0px 15px 0px 0px">Text</Text>
        <WarningText margin="0px 15px 0px 0px">WarningText</WarningText>
        <ErrorText margin="0px 15px 0px 0px">ErrorText</ErrorText>
        <DisabledText margin="0px 15px 0px 0px">DisabledText</DisabledText>
        <MarkedText margin="0px 15px 0px 0px">MarkedText</MarkedText>
        <CodeText margin="0px 15px 0px 0px">CodeText</CodeText>
        <UnderlinedText margin="0px 15px 0px 0px">UnderlinedText</UnderlinedText>
        <DeletedText margin="0px 15px 0px 0px">DeletedText</DeletedText>
        <StrongText margin="0px 15px 0px 0px">StrongText</StrongText>
        <EditableText margin="0px 15px 0px 0px" onChange={setEditableText}>
          {editableText}
        </EditableText>
      </FlexRow>
      <FlexRow>
        <PrimaryButton margin="0px 15px 0px 0px" block>
          Block Button
        </PrimaryButton>
      </FlexRow>
      <FlexRow>
        <PrimaryButton margin="0px 15px 0px 0px">Primary</PrimaryButton>
        <PrimaryButton margin="0px 15px 0px 0px" loading={true}>
          Loading
        </PrimaryButton>
        <PrimaryButton margin="0px 15px 0px 0px" icon={<SearchOutlined />}>
          Icon
        </PrimaryButton>
      </FlexRow>
      <FlexRow>
        <SecondaryButton margin="0px 15px 0px 0px">Secondary</SecondaryButton>
        <SecondaryButton margin="0px 15px 0px 0px" loading={true}>
          Loading
        </SecondaryButton>
        <SecondaryButton margin="0px 15px 0px 0px" icon={<SearchOutlined />}>
          Icon
        </SecondaryButton>
      </FlexRow>
      <FlexRow>
        <DashedButton margin="0px 15px 0px 0px">Dashed</DashedButton>
        <DashedButton margin="0px 15px 0px 0px" loading={true}>
          Loading
        </DashedButton>
        <DashedButton margin="0px 15px 0px 0px" icon={<SearchOutlined />}>
          Icon
        </DashedButton>
      </FlexRow>
    </Page>
  );
};

export default Home;
