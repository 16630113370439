import { takeLatest, call, put } from 'redux-saga/effects';

import Api from './../../utils/api-wrapper';
import {
  startOrgLoading,
  stopOrgLoading,
  FETCH_QBO_DETAILS,
  storeQboCredentialsAction,
  UPDATE_ORG,
  storeOrgInfo
} from './index';

export function* fetchQboDetails() {
  try {
    yield put(startOrgLoading());
    const { org } = yield call(Api.fetchOrgQboData, {});
    yield put(storeQboCredentialsAction({ ...org }));
  } catch (err) {
    yield put(stopOrgLoading());
    console.log(err);
  }
}

export function* updateOrg({ payload: { data } }) {
  try {
    yield put(startOrgLoading());
    yield call(Api.updateOrg, { data });
    const { org } = yield call(Api.fetchOrgData, {});
    yield put(storeOrgInfo({ ...org }));
  } catch (err) {
    yield put(stopOrgLoading());
    console.log(err);
  }
}

export const companySagas = [
  takeLatest(FETCH_QBO_DETAILS, fetchQboDetails),
  takeLatest(UPDATE_ORG, updateOrg)
];

export default companySagas;
