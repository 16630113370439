import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { fetchInvoicesSentToQuickbooksAction } from './../../../ducks/invoices';
import {
  ContentContainer,
  FlexRow,
  PageContentContainer,
  TitleH4,
  CardNoTitle
} from './../../../components';
import { currencyFormatter } from './../../../utils/formatters';
import "./cursor.css";

const PastInvoices = ({
  invoices: { loading, invoicesSentToQbo },
  clients: { allEntities },
  fetchInvoicesSentToQbo
}) => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchInvoicesSentToQbo({});
  }, [fetchInvoicesSentToQbo]);

  useEffect(() => {
    setInvoices(invoicesSentToQbo.length ? invoicesSentToQbo.map(invoice => {
      const entity = allEntities.find((ent) => ent.id === invoice.entity_id);
      return {
        ...invoice,
        name: !!entity ? `${entity.name}` : 'N/A'
      }
    }).sort((a, b) => a.id - b.id) : [])
  }, [invoicesSentToQbo, setInvoices, allEntities])

  useEffect(() => {
    setFilteredInvoices(invoices);
  }, [setFilteredInvoices, invoices])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Total Time',
      dataIndex: 'total_time',
      key: 'total_time',
      sorter: (a, b) => a.total_time - b.total_time
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (text, record) => currencyFormatter.format(record.total_amount),
      sorter: (a, b) => a.total_amount - b.total_amount
    },
    {
      title: 'Number of Entries',
      dataIndex: 'number_of_entries',
      key: 'number_of_entries',
      render: (text, record) => record.entry_ids.length,
      sorter: (a, b) => a.entry_ids.length - b.entry_ids.length
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      render: (text, record) => moment(record.invoice_date).format('MM/DD/YYYY'),
      sorter: (a, b) => moment(a.invoice_date) - moment(b.invoice_date)
    },
    {
      title: 'Date Range',
      dataIndex: 'date_range',
      key: 'date_range',
      render: (text, record) => `${moment(record.start_date).format('MM/DD/YYYY')} - ${moment(
        record.end_date
      ).format('MM/DD/YYYY')}`
    }
  ];

  const filterInvoices = (e) => {
    setFilteredInvoices(
      invoices.filter((invoices) =>
        invoices.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <ContentContainer loading={loading} loadingTip="Loading Invoice Data...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>Invoices Sent To Quickbooks</TitleH4>

        <div>
          <Input.Search
            placeholder="Search by Name"
            onChange={filterInvoices}
            style={{ width: 300, marginRight: '20px' }}
          />
        </div>
      </FlexRow>

      <PageContentContainer>
        <CardNoTitle>
          <Table
            columns={columns}
            pagination={{
              defaultPageSize: 50
            }}
            rowKey={record => record.id}
            dataSource={filteredInvoices}
            onRow={record => ({
              onClick: () => history.push(`/admin/invoices/edit/${record.id}`)
            })}
            rowClassName={() => 'hasPointer'}
          />
        </CardNoTitle>
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ clients, invoices }) => ({
  clients,
  invoices
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoicesSentToQbo: (payload) => dispatch(fetchInvoicesSentToQuickbooksAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PastInvoices);
