import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import { Progress } from 'antd';

import { FlexRow, Text, SecondaryText, TitleH4 } from './../../components';
import { DayGridContainer, DayAmountContainer, DateContainer } from './styled-components';
import { COLORS } from './../../constants/styles';
import { convertDay } from './../../utils/moment-utils';

const PayPeriodSummary = ({
  entriesProp,
  datesProp,
  countByUser,
  selectedDate,
  goalDailyHours,
  setSelectedDate
}) => {
  const [usableData, setUsableData] = useState([]);

  useEffect(() => {
    createData({ entries: entriesProp, dates: datesProp });
  }, [entriesProp, datesProp]);

  const createData = ({ entries, dates }) => {
    const data = dates.map((date) => ({
      day: moment(date).day(),
      date: moment(date).format('MM/DD'),
      total_amount: Number(
        entries
          .filter((ent) => {
            return (
              moment(ent.date) >= moment(date).startOf('day') &&
              moment(ent.date) <= moment(date).endOf('day')
            );
          })
          .reduce((tot, curr) => tot + curr.time_spent, 0)
          .toFixed(2)
      ),
      fullDate: date
    }));

    setUsableData(data);
  };

  const numberOfWorkdays = usableData.filter((point) => point.day >= 1 && point.day <= 5).length;
  const totalTime = countByUser[0] && Number(countByUser[0].total_amount.toFixed(2));
  const goalPayPeriodHours = numberOfWorkdays * goalDailyHours;

  return (
    <Fragment>
      <FlexRow alignItems="center">
        <SecondaryText>Total Hours:</SecondaryText>
        <TitleH4 code>
          {totalTime ? totalTime : '0'}/{goalPayPeriodHours} Hrs
        </TitleH4>
      </FlexRow>

      <Progress
        style={{ margin: '0px 0px 20px' }}
        percent={goalPayPeriodHours ? (totalTime / goalPayPeriodHours) * 100 : 0}
        showInfo={false}
        strokeColor={COLORS.primaryColor}
      />

      <br />
      <SecondaryText>Daily Totals</SecondaryText>
      <FlexRow borderRight padding="0" margin="10px 0px 20px" justifyContent="center">
        {usableData.map((day, index) => (
          <DayGridContainer
            onClick={() => setSelectedDate(moment(day.fullDate))}
            key={`${day.day}-${index}-${day.total_amount}`}
          >
            <DateContainer
              shouldHaveBackground={moment(day.fullDate).startOf('day') <= moment().endOf('day')}
              isSelectedDay={
                moment(day.fullDate).format('MM/DD/YYYY') ===
                moment(selectedDate).format('MM/DD/YYYY')
              }
            >
              <Text
                shouldHaveBackground={moment(day.fullDate).startOf('day') <= moment().endOf('day')}
                style={{ lineHeight: '16px' }}
              >
                {convertDay(day.day)},
              </Text>
              <Text
                shouldHaveBackground={moment(day.fullDate).startOf('day') <= moment().endOf('day')}
                style={{ lineHeight: '16px' }}
              >
                {day.date}
              </Text>
            </DateContainer>
            <DayAmountContainer goalDailyHours={goalDailyHours} amount={day.total_amount}>
              {day.total_amount}
            </DayAmountContainer>
          </DayGridContainer>
        ))}
      </FlexRow>
    </Fragment>
  );
};

export default PayPeriodSummary;
