import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import {
  PrimaryButton,
  SecondaryButton,
  ButtonRow,
  UniversalInputNumber,
  UniversalInput
} from './../../components';

const EditUserPopover = ({
  currentFirstName,
  currentLastName,
  updateSelf,
  currentDailyHoursGoal,
  userId
}) => {
  const [editUserPopoverVisible, setEditUserPopoverVisible] = useState(false);
  const [firstName, setFirstName] = useState(currentFirstName);
  const [lastName, setLastName] = useState(currentLastName);
  const [dailyHoursGoal, setDailyHoursGoal] = useState(currentDailyHoursGoal);

  useEffect(() => {
    if (editUserPopoverVisible) {
      setFirstName(currentFirstName);
      setLastName(currentLastName);
      setDailyHoursGoal(currentDailyHoursGoal);
    }
  }, [
    currentFirstName,
    editUserPopoverVisible,
    currentLastName,
    setLastName,
    currentDailyHoursGoal,
    setDailyHoursGoal
  ]);

  const cancelAdd = () => {
    setEditUserPopoverVisible(false);
    setFirstName('');
    setLastName('');
    setDailyHoursGoal(0);
  };

  const editUser = () => {
    updateSelf({
      data: {
        first_name: firstName,
        last_name: lastName,
        goal_daily_hours: Number(dailyHoursGoal.toFixed(2))
      },
      userId
    });
    cancelAdd();
  };

  const notDifferent =
    firstName === currentFirstName &&
    lastName === currentLastName &&
    dailyHoursGoal === currentDailyHoursGoal;
  const hasEmpty = !firstName || !lastName;

  return (
    <Popover
      trigger="click"
      visible={editUserPopoverVisible}
      onVisibleChange={(visible) => setEditUserPopoverVisible(visible)}
      title={`Edit ${currentFirstName} ${currentLastName}`}
      placement="bottomRight"
      content={
        <div>
          <UniversalInput
            title="First Name*"
            inputValue={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <UniversalInput
            title="Last Name*"
            inputValue={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <UniversalInputNumber
            title="Daily Hours Goal"
            inputValue={dailyHoursGoal}
            onChange={(val) => setDailyHoursGoal(val)}
          />

          <ButtonRow>
            <SecondaryButton onClick={cancelAdd} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={editUser} disabled={notDifferent || hasEmpty}>
              Edit
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <PrimaryButton icon={<EditOutlined />}>Edit</PrimaryButton>
    </Popover>
  );
};

export default EditUserPopover;
