import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import {
  PrimaryButton,
  SecondaryButton,
  ButtonRow,
  UniversalInputNumber
} from './../../../components';

const EditRatePopover = ({ currentAmount, recordId, updateRate }) => {
  const [ratePopoverVisible, setRatePopoverVisible] = useState(false);
  const [amount, setAmount] = useState(currentAmount);

  useEffect(() => {
    if (ratePopoverVisible) {
      setAmount(currentAmount);
    }
  }, [currentAmount, ratePopoverVisible]);

  const cancelAdd = () => {
    setRatePopoverVisible(false);
    setAmount('');
  };

  const editRate = () => {
    const data = {
      data: { amount },
      rateId: recordId
    };
    updateRate(data);
    cancelAdd();
  };

  const notDifferent = amount === currentAmount;

  return (
    <Popover
      trigger="click"
      visible={ratePopoverVisible}
      onVisibleChange={(visible) => setRatePopoverVisible(visible)}
      title="Edit Rate"
      placement="bottomRight"
      content={
        <div>
          <UniversalInputNumber
            title="Amount*"
            inputValue={amount}
            onChange={setAmount}
            currency={true}
          />

          <ButtonRow>
            <SecondaryButton onClick={cancelAdd} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={editRate} disabled={notDifferent}>
              Edit
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <SecondaryButton icon={<EditOutlined />} />
    </Popover>
  );
};

export default EditRatePopover;
