import React from 'react';
import moment from 'moment';
import { Table } from 'antd';

import { convertDay } from './../../utils/moment-utils';

const ClientBreakdown = ({ clientInfo, daysInRange }) => {
  const columns = daysInRange && [
    { title: 'Name', key: 'name', dataIndex: 'name', width: 300, fixed: 'left' },
    ...daysInRange.map((day) => {
      const formattedDay = `${convertDay(moment(day).day())}, ${moment(day).format('MM/DD')}`;
      return {
        title: formattedDay,
        key: formattedDay,
        dataIndex: moment(day).format('MM/DD/YYYY')
      };
    }),
    { title: 'Total', key: 'total', dataIndex: 'time', fixed: 'right' }
  ];
  return (
    <Table
      scroll={{ x: 1260 }}
      bordered
      size="small"
      pagination={false}
      rowKey={(record) => `${record.name}-${record.id}`}
      columns={columns}
      dataSource={clientInfo}
      style={{ margin: '20px 0px' }}
    />
  );
};

export default ClientBreakdown;
