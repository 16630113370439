import React, { useState, useEffect } from 'react';
import { Modal, Table, Switch } from 'antd';
import { connect } from 'react-redux';

import { Text, FlexRow, NonTitledInputNumber } from '../../../components';
import { fetchUsersInfoAction } from './../../../ducks/users';

const CustomRatesModal = ({
  selectedProject: { id, custom_rates, uses_custom_rates, name },
  users: { users, loading },
  fetchUsersInfo,
  company,
  updateProjectCustomRates,
  clientId,
  closeCustomRatesModal,
  visible,
  ...rest
}) => {
  const [customRates, setCustomRates] = useState([]);
  const [usesCustomRates, setUsesCustomRates] = useState(false);

  useEffect(() => {
    if (custom_rates && custom_rates.length > 0) {
      setCustomRates(custom_rates);
      setUsesCustomRates(uses_custom_rates);
    } else if (visible) {
      fetchUsersInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (usesCustomRates && !uses_custom_rates) {
      setCustomRates(
        users.map((user) => ({
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          rate:
            user.rates &&
            user.rates.find((rate) => rate.type === 'Billing' && !rate.date_ended).amount
        }))
      );
    } else if (!usesCustomRates) {
      setCustomRates([]);
    } else if (usesCustomRates && uses_custom_rates) {
      setCustomRates(custom_rates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usesCustomRates]);

  const setRate = ({ id, rate, name }) => {
    const idx = customRates.findIndex((r) => r.id === id);
    setCustomRates([
      ...customRates.slice(0, idx),
      { id, rate, name },
      ...customRates.slice(idx + 1)
    ]);
  };

  const updateRates = () => {
    updateProjectCustomRates({
      data: {
        uses_custom_rates: usesCustomRates,
        custom_rates: customRates
      },
      projectId: id,
      clientId
    });
    closeCustomRatesModal();
  };

  const onCancel = () => {
    setCustomRates([]);
    setUsesCustomRates(false);
    closeCustomRatesModal();
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Custom Billing Rate',
      key: 'billing_rate',
      dataIndex: 'billing_rate',
      render: (text, record) => (
        <NonTitledInputNumber
          inputValue={record.rate}
          currency={true}
          onChange={(val) => setRate({ id: record.id, rate: val, name: record.name })}
        />
      )
    }
  ];

  const isDifferent =
    JSON.stringify(customRates) !== JSON.stringify(custom_rates) ||
    usesCustomRates !== uses_custom_rates;

  return (
    <Modal
      title={`Manage ${name} Custom Rates`}
      onOk={updateRates}
      okText={uses_custom_rates ? 'Edit Rates' : 'Add Rates'}
      onCancel={() => onCancel()}
      destroyOnClose={true}
      okButtonProps={{ disabled: !isDifferent || (!custom_rates && !customRates.length) }}
      visible={visible}
      {...rest}
    >
      <FlexRow justifyContent="space-between" align-items="center">
        <Text>Custom Rates Enabled:</Text>
        <Switch loading={loading} onChange={setUsesCustomRates} checked={usesCustomRates} />
      </FlexRow>

      {usesCustomRates && customRates.length ? (
        <Table
          scroll={{ y: 300 }}
          pagination={false}
          size="small"
          dataSource={customRates}
          rowKey="id"
          columns={columns}
          style={{ margin: '20px 0px' }}
        />
      ) : null}
    </Modal>
  );
};

const mapStateToProps = ({ users, company }) => ({
  users,
  company
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersInfo: (payload) => dispatch(fetchUsersInfoAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomRatesModal);
