import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import TimerAvatar from './timerAvatar';
import { COLORS } from './../../constants/styles';
import { TimerContainer } from './styled-components';
import Timer from './timerCore';

const TimerModule = () => {
  const [timers, setTimers] = useState([]);

  const addTimer = () => {
    setTimers([
      ...timers,
      {
        id: `timer-${new Date().getTime()}-${timers.length}`,
        key: `timer-${new Date().getTime()}-${timers.length}`,
        paused: true
      }
    ]);
  };

  const removeTimer = ({ timerId }) => {
    setTimers(timers.filter(t => t.id !== timerId));
  };

  const toggleTimerPausedState = ({ timerId, pausedState, pausedTimers }) => {
    const usableTimers = !!pausedTimers ? pausedTimers : timers;
    const timerIndex = usableTimers.findIndex(t => t.id === timerId);
    setTimers([
      ...usableTimers.slice(0, timerIndex),
      {
        ...usableTimers[timerIndex],
        paused: pausedState
      },
      ...usableTimers.slice(timerIndex + 1)
    ]);
  };

  const startTimer = ({ timerId }) => {
    const pausedTimers = timers.map(timer => ({ ...timer, paused: true }));
    setTimers(pausedTimers);
    toggleTimerPausedState({ pausedState: false, timerId, pausedTimers });
  };

  return (
    <TimerContainer>
      <Popover
        placement="leftBottom"
        content={timers.length < 5 ? 'Click to add timer' : 'Sorry! 5 Timers is the max.'}
        trigger="hover"
      >
        <TimerAvatar
          noDelete
          paused={true}
          onClick={timers.length < 5 ? addTimer : null}
          icon={<PlusOutlined style={{ fontSize: '26px', color: `${COLORS.primaryColor}` }} />}
        />
      </Popover>

      {timers.map(({ key, id, paused }) => (
        <Timer
          startTimer={startTimer}
          stopTimer={({ timerId }) => toggleTimerPausedState({ timerId, pausedState: true })}
          deleteFn={removeTimer}
          key={key}
          id={id}
          paused={paused}
        />
      ))}
    </TimerContainer>
  );
};

export default TimerModule;
