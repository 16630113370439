import { takeLatest, call, put } from 'redux-saga/effects';

import {
  FETCH_CLIENTS_BY_ORG,
  storeClientsInfo,
  stopClientsLoading,
  startClientsLoading,
  ADD_CLIENT_TO_ORG,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  CREATE_ENTITY,
  stopEntitiesLoading,
  startEntitiesLoading,
  storeEntitiesByClientIdAction,
  DELETE_ENTITY,
  UPDATE_ENTITY,
  startProjectsLoading,
  stopProjectsLoading,
  CREATE_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  stopMonthlyFeesLoading,
  startMonthlyFeesLoading,
  UPDATE_MONTHLY_FEE,
  CREATE_MONTHLY_FEE,
  FETCH_CLIENTS_FROM_QBO,
  stopQuickbooksLoading,
  startQuickbooksLoading,
  storeStagedQboEntities,
  BULK_CREATE_FROM_QBO,
  UPDATE_PROJECT_CUSTOM_RATES
} from './index';
import Api from './../../utils/api-wrapper';

export function* fetchClientsByOrg() {
  try {
    yield put(startClientsLoading());
    const { count, clients } = yield call(Api.fetchClientsByOrg, {});
    yield put(storeClientsInfo({ count, clients }));
  } catch (err) {
    yield put(stopClientsLoading());
    console.log(err);
  }
}

export function* addClientToOrg({ payload }) {
  try {
    yield put(startClientsLoading());
    yield call(Api.createClient, { data: { ...payload } });
    yield call(fetchClientsByOrg, {});
  } catch (err) {
    yield put(stopClientsLoading());
    console.log(err);
  }
}

export function* updateClient({ payload: { data, clientId } }) {
  try {
    yield put(startClientsLoading());
    yield call(Api.updateClient, { data, clientId });
    yield call(fetchClientsByOrg, {});
  } catch (err) {
    yield put(stopClientsLoading());
    console.log(err);
  }
}

export function* deleteClient({ payload: { clientId } }) {
  try {
    yield put(startClientsLoading());
    yield call(Api.deleteClient, { clientId });
    yield call(fetchClientsByOrg, {});
  } catch (err) {
    yield put(stopClientsLoading());
    console.log(err);
  }
}

export function* createEntity({ payload, payload: { client_id } }) {
  try {
    yield put(startEntitiesLoading());
    yield call(Api.createEntity, { data: { ...payload } });
    const { entities } = yield call(Api.fetchEntitiesByClient, { clientId: client_id });
    yield put(storeEntitiesByClientIdAction({ entities, clientId: client_id }));
  } catch (err) {
    yield put(stopEntitiesLoading());
    console.log(err);
  }
}

export function* deleteEntity({ payload: { entityId, clientId } }) {
  try {
    yield put(startEntitiesLoading());
    yield call(Api.deleteEntity, { entityId });
    const { entities } = yield call(Api.fetchEntitiesByClient, { clientId });
    yield put(storeEntitiesByClientIdAction({ entities, clientId }));
  } catch (err) {
    yield put(stopEntitiesLoading());
    console.log(err);
  }
}

export function* updateEntity({ payload: { data, entityId, clientId } }) {
  try {
    yield put(startEntitiesLoading());
    yield call(Api.updateEntity, { entityId, data });
    const { entities } = yield call(Api.fetchEntitiesByClient, { clientId });
    yield put(storeEntitiesByClientIdAction({ entities, clientId }));
  } catch (err) {
    yield put(stopEntitiesLoading());
    console.log(err);
  }
}

export function* createProject({
  payload: { fixed_fee_amount, client_id, entity_id, name, billing_type }
}) {
  try {
    yield put(startProjectsLoading());
    yield call(Api.createProject, {
      data: { client_id, entity_id, name, billing_type, fixed_fee_amount }
    });
    const { entities } = yield call(Api.fetchEntitiesByClient, { clientId: client_id });
    yield put(storeEntitiesByClientIdAction({ entities, clientId: client_id }));
  } catch (err) {
    yield put(stopProjectsLoading());
    console.log(err);
  }
}

export function* deleteProject({ payload: { projectId, clientId } }) {
  try {
    yield put(startProjectsLoading());
    yield call(Api.deleteProject, { projectId });
    const { entities } = yield call(Api.fetchEntitiesByClient, { clientId });
    yield put(storeEntitiesByClientIdAction({ entities, clientId }));
  } catch (err) {
    yield put(stopClientsLoading());
    console.log(err);
  }
}

export function* updateProject({ payload: { data, projectId, clientId } }) {
  try {
    yield put(startProjectsLoading());
    yield call(Api.updateProject, { projectId, data });
    const { entities } = yield call(Api.fetchEntitiesByClient, { clientId });
    yield put(storeEntitiesByClientIdAction({ entities, clientId }));
  } catch (err) {
    yield put(stopClientsLoading());
    console.log(err);
  }
}

export function* updateProjectCustomRates({ payload: { data, projectId, clientId } }) {
  try {
    yield put(startProjectsLoading());
    yield call(Api.updateProjectCustomRates, { projectId, data });
    const { entities } = yield call(Api.fetchEntitiesByClient, { clientId });
    yield put(storeEntitiesByClientIdAction({ entities, clientId }));
  } catch (err) {
    yield put(stopClientsLoading());
    console.log(err);
  }
}

export function* updateMonthlyFee({ payload: { data, monthlyFeeId } }) {
  try {
    yield put(startMonthlyFeesLoading());
    yield call(Api.updateMonthlyFee, { data, monthlyFeeId });
    yield call(fetchClientsByOrg, {});
  } catch (err) {
    yield put(stopMonthlyFeesLoading());
    console.log(err);
  }
}

export function* createMonthlyFee({
  payload: { date_began, amount, client_id, previousMonthlyFeeId }
}) {
  try {
    yield put(startMonthlyFeesLoading());
    yield call(Api.createMonthlyFee, {
      data: { date_began, amount, client_id, previousMonthlyFeeId }
    });
    yield call(fetchClientsByOrg, {});
  } catch (err) {
    yield put(stopMonthlyFeesLoading());
    console.log(err);
  }
}

export function* fetchFromQuickbooks() {
  try {
    yield put(startQuickbooksLoading());
    const { clients } = yield call(Api.fetchClientsFromQbo, {});
    yield put(storeStagedQboEntities({ stagedEntities: clients }));
  } catch (err) {
    yield put(stopQuickbooksLoading());
    console.log(err);
  }
}

export function* bulkCreateFromQuickbooks({ payload: { newClients, newEntities } }) {
  try {
    yield put(startQuickbooksLoading());
    yield call(Api.bulkCreateClient, {
      data: { newClients, newEntities }
    });
    yield put(storeStagedQboEntities({ stagedEntities: [] }));
    yield call(fetchClientsByOrg, {});
  } catch (err) {
    yield put(stopQuickbooksLoading());
    console.log(err);
  }
}

export const clientsSagas = [
  takeLatest(FETCH_CLIENTS_BY_ORG, fetchClientsByOrg),
  takeLatest(ADD_CLIENT_TO_ORG, addClientToOrg),
  takeLatest(UPDATE_CLIENT, updateClient),
  takeLatest(DELETE_CLIENT, deleteClient),
  takeLatest(CREATE_ENTITY, createEntity),
  takeLatest(DELETE_ENTITY, deleteEntity),
  takeLatest(UPDATE_ENTITY, updateEntity),
  takeLatest(CREATE_PROJECT, createProject),
  takeLatest(DELETE_PROJECT, deleteProject),
  takeLatest(UPDATE_PROJECT, updateProject),
  takeLatest(UPDATE_PROJECT_CUSTOM_RATES, updateProjectCustomRates),
  takeLatest(UPDATE_MONTHLY_FEE, updateMonthlyFee),
  takeLatest(CREATE_MONTHLY_FEE, createMonthlyFee),
  takeLatest(FETCH_CLIENTS_FROM_QBO, fetchFromQuickbooks),
  takeLatest(BULK_CREATE_FROM_QBO, bulkCreateFromQuickbooks)
];

export default clientsSagas;
