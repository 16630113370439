import React, { useEffect, useState } from 'react';
import { DatePicker, Tooltip, Table } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';

import { findPayPeriodDates } from './../../../utils/moment-utils';
import { currencyFormatter } from './../../../utils/formatters';
import { fetchPayrollDataAction } from './../../../ducks/reports';
import {
  ContentContainer,
  FlexRow,
  PageContentContainer,
  TitleH4,
  GridRow,
  Text,
  CardNoTitle,
  PrimaryButton,
  AvatarCard
} from './../../../components';
import { COLORS } from './../../../constants/styles';

const { RangePicker } = DatePicker;

const PayrollReport = ({
  company,
  fetchPayrollData,
  reports: {
    payrollData: { companyStatistics, usersWithStatistics },
    loading
  }
}) => {
  const [dateRange, setDateRange] = useState(findPayPeriodDates(moment(), true));
  const [rangeChanged, setRangeChanged] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    if (dateRange.length > 0) {
      fetchPayrollData({
        startDate: moment(dateRange[0]).startOf('day').utc().format(),
        endDate: moment(dateRange[dateRange.length - 1])
          .endOf('day')
          .utc()
          .format()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openChange = (status) => {
    if (!status && rangeChanged) {
      setPickerOpen(true);
    } else {
      setPickerOpen(status);
    }
  };

  const onChange = (range) => {
    if (
      !moment(range[0]).isSame(moment(dateRange[0])) ||
      !moment(range[1]).isSame(moment(dateRange[1]))
    ) {
      setDateRange(range);
      setPickerOpen(true);
      setRangeChanged(true);
    }
  };

  const onOk = () => {
    setRangeChanged(false);
    setPickerOpen(false);
    fetchPayrollData({
      startDate: moment(dateRange[0]).startOf('day').utc().format(),
      endDate: moment(dateRange[dateRange.length - 1])
        .endOf('day')
        .utc()
        .format()
    });
  };

  const billableHours =
    companyStatistics &&
    companyStatistics.monthlyInfo.time +
      companyStatistics.hourlyInfo.time +
      companyStatistics.fixedFeeInfo.time;
  const billableHoursPercentage =
    companyStatistics && billableHours / companyStatistics.totalInfo.time;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => record.name
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (text, record, index) => currencyFormatter.format(record.totalInfo.cost)
    },
    {
      title: 'Total Time (Hrs)',
      dataIndex: 'totalTime',
      key: 'totalTime',
      render: (text, record, index) => record.totalInfo.time
    },
    {
      title: 'Hours By Project Type',
      children: [
        {
          title: 'Monthly',
          dataIndex: 'monthly',
          key: 'monthly',
          render: (text, record, index) => record.monthlyInfo.time
        },
        {
          title: 'Hourly',
          dataIndex: 'hourly',
          key: 'hourly',
          render: (text, record, index) => record.hourlyInfo.time
        },
        {
          title: 'Fixed Fee',
          dataIndex: 'fixed',
          key: 'fixed',
          render: (text, record, index) => record.fixedFeeInfo.time
        },
        {
          title: 'Non-Billable',
          dataIndex: 'nonBillable',
          key: 'nonBillable',
          render: (text, record, index) => record.noChargeInfo.time
        }
      ]
    }
  ];

  return (
    <ContentContainer loading={loading} loadingTip="Loading Payroll Data...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>{`${company.name} Payroll`}</TitleH4>

        <div>
          <RangePicker
            value={dateRange}
            onChange={onChange}
            format="MM/DD/YYYY"
            allowClear={false}
            onOpenChange={openChange}
            open={pickerOpen}
            renderExtraFooter={() => (
              <FlexRow justifyContent="flex-end" alignItems="center">
                <PrimaryButton disabled={!rangeChanged} onClick={onOk}>
                  Ok
                </PrimaryButton>
              </FlexRow>
            )}
          />
        </div>
      </FlexRow>

      <PageContentContainer>
        <GridRow gridTemplateColumns="1fr 1fr 1fr 1fr">
          <AvatarCard noAvatar name="Total Company Time">
            {companyStatistics && (
              <FlexRow padding="20px" justifyContent="center" alignItems="center">
                <TitleH4 style={{ color: COLORS.primaryColor }}>
                  {companyStatistics.totalInfo.time} Hours
                </TitleH4>
              </FlexRow>
            )}
          </AvatarCard>

          <AvatarCard noAvatar name="Billable Time">
            {companyStatistics && (
              <FlexRow padding="20px" justifyContent="center" alignItems="center">
                <FlexRow padding="0px" margin="0px" alignItems="center" justifyContent="center">
                  <TitleH4>{billableHours.toFixed(2)} Hours</TitleH4>
                  <Text>({(billableHoursPercentage * 100).toFixed(2)}%)</Text>
                </FlexRow>
              </FlexRow>
            )}
          </AvatarCard>

          <AvatarCard
            noAvatar
            name="Approximate Revenue"
            action={
              <Tooltip
                placement="topRight"
                title="Does not include revenue from Fixed Fee Projects"
              >
                <QuestionCircleFilled style={{ marginLeft: '5px' }} />
              </Tooltip>
            }
          >
            {companyStatistics && (
              <FlexRow padding="20px" justifyContent="center" alignItems="center">
                <TitleH4 style={{ color: COLORS.primaryColor }}>
                  {currencyFormatter.format(companyStatistics.totalInfo.revenue)}
                </TitleH4>
              </FlexRow>
            )}
          </AvatarCard>

          <AvatarCard noAvatar name="Total Cost">
            {companyStatistics && (
              <FlexRow padding="20px" justifyContent="center" alignItems="center">
                <TitleH4 style={{ color: COLORS.errorColor }}>
                  {currencyFormatter.format(companyStatistics.totalInfo.cost)}
                </TitleH4>
              </FlexRow>
            )}
          </AvatarCard>
        </GridRow>

        <CardNoTitle>
          <Table
            pagination={false}
            dataSource={
              usersWithStatistics &&
              usersWithStatistics.sort((a, b) => a.name.localeCompare(b.name))
            }
            rowKey="id"
            style={{ width: '100%' }}
            columns={columns}
          />
        </CardNoTitle>
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ company, reports }) => ({
  company,
  reports
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayrollData: (payload) => dispatch(fetchPayrollDataAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollReport);
