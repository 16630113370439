import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'antd';
import _ from 'lodash/core';

import {
  UniversalSelect,
  UniversalRadioButton,
  UniversalDoubleInput,
  UniversalInput
} from '../../../components';

const EmployeeModal = ({
  employee,
  addingNewEmployee,
  addEmployeeToOrg,
  updateEmployee,
  organizationId,
  onCancel,
  qboLinked,
  companyQboId,
  qboItems,
  fetchItemsFromQbo,
  qboItemsLoading,
  ...rest
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [type, setType] = useState('');
  const [email, setEmail] = useState('');
  const [qboId, setQboId] = useState(null);
  const [role, setRole] = useState('');
  const [linkedQboItem, setLinkedQboItem] = useState(null);

  useEffect(() => {
    setFirstName(employee.first_name || '');
    setLastName(employee.last_name || '');
    setType(employee.type || '');
    setEmail(employee.email || '');
    setQboId(employee.qbo_id && employee.qbo_id);
    setRole(employee.role_id || '');
    setLinkedQboItem(employee.linked_qbo_item || null);
  }, [employee]);

  useEffect(() => {
    if (qboLinked && organizationId) {
      fetchItemsFromQbo();
    }
  }, [qboLinked, organizationId, fetchItemsFromQbo]);

  const data = {
    first_name: firstName,
    last_name: lastName,
    type,
    email,
    role_id: role,
    qbo_id: qboId,
    linked_qbo_item: linkedQboItem
  };

  const addEmployeeFn = () => {
    addEmployeeToOrg({ ...data });
    onCancel();
  };

  const updateEmployeeFn = () => {
    updateEmployee({ data, userId: employee.id });
    onCancel();
  };

  const hasBlank = !firstName || !lastName || !type || !email || !role;

  const isDifferent =
    firstName !== employee.first_name ||
    lastName !== employee.last_name ||
    email !== employee.email ||
    type !== employee.type ||
    qboId !== employee.qbo_id ||
    role !== employee.role_id ||
    !_.isEqual(linkedQboItem, employee.linked_qbo_item);

  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailIsGood = !email || emailRegex.test(email);

  const canSubmit = !hasBlank && isDifferent && emailIsGood;

  return (
    <Modal
      title={
        addingNewEmployee
          ? 'Create New Employee'
          : `Edit ${employee.first_name} ${employee.last_name}`
      }
      onOk={addingNewEmployee ? addEmployeeFn : updateEmployeeFn}
      okText={addingNewEmployee ? 'Add Employee' : 'Update Employee'}
      onCancel={onCancel}
      okButtonProps={{ disabled: !canSubmit }}
      {...rest}
    >
      <UniversalDoubleInput
        title="Name*"
        inputValue1={firstName}
        onChange1={(e) => setFirstName(e.target.value)}
        placeholder1="First Name"
        inputValue2={lastName}
        onChange2={(e) => setLastName(e.target.value)}
        placeholder2="Last Name"
      />

      <UniversalRadioButton
        title="Role*"
        onChange={(e) => setRole(e.target.value)}
        radioValue={role}
        options={[
          { shouldHide: !(employee.role_id === 1), value: 1, name: 'Super Admin' },
          { value: 2, name: 'Admin' },
          { value: 3, name: 'User' }
        ]}
      />

      <UniversalRadioButton
        title="Type*"
        onChange={(e) => setType(e.target.value)}
        radioValue={type}
        options={[
          { value: 'Contractor', name: 'Contractor' },
          { value: 'Employee', name: 'Employee' }
        ]}
      />

      <UniversalInput
        title="Email*"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        inputValue={email}
      />

      {qboLinked && (
        <Fragment>
          <UniversalInput
            title="Quickbooks Id"
            inputValue={qboId && qboId.split('-')[1]}
            onChange={(e) => setQboId(`${companyQboId}-${e.target.value}`)}
            placeholder="Quickbooks Id"
          />

          <UniversalSelect
            loading={qboItemsLoading}
            title="Linked Quickbooks Service"
            onChange={(id) => setLinkedQboItem(qboItems.find((item) => item.id === id))}
            selectValue={linkedQboItem && linkedQboItem.name}
            placeholder="Select Item"
            options={
              qboItems &&
              qboItems.map((item) => ({
                key: item.id,
                value: item.id,
                name: item.name
              }))
            }
          />
        </Fragment>
      )}
    </Modal>
  );
};

export default EmployeeModal;
