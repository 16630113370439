import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash/core';

import { FlexRow, UniversalDrawer, DeleteConfirmationModal } from './../../../components';
import {
  createEntityAction,
  deleteEntityAction,
  updateEntityAction,
  createProjectAction,
  deleteProjectAction,
  updateProjectAction,
  updateProjectCustomRatesAction
} from './../../../ducks/clients';
import AddEntityPopover from './addEntityPopover';
import CustomRatesModal from './customRatesModal';
import { entityDrawerColumns, entityDrawerProjectColumns } from './constants';

const EntityDrawer = ({
  client: selectedClient,
  createEntity,
  deleteEntity,
  updateEntity,
  clients,
  createProject,
  deleteProject,
  updateProject,
  company,
  updateProjectCustomRates,
  ...rest
}) => {
  const [client, setClient] = useState(selectedClient);
  const [customRatesModalOpen, setCustomRatesModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [infoNeededToDelete, setInfoNeededToDelete] = useState({});
  const [warningMessage, setWarningMessage] = useState('');
  const [deleteType, setDeleteType] = useState(null);
  useEffect(() => {
    const newClient = clients.clients.filter((obj) => obj.id === client.id);
    if (newClient[0] && client && JSON.stringify(newClient[0]) !== JSON.stringify(client)) {
      setClient({ ...client, entities: newClient[0].entities });
    }
  }, [clients, client]);

  useEffect(() => {
    setClient(selectedClient);
  }, [selectedClient]);

  const openCustomRatesModal = (project) => {
    setCustomRatesModalOpen(true);
    setSelectedProject(project);
  };

  const closeCustomRatesModal = () => {
    setCustomRatesModalOpen(false);
    setSelectedProject({});
  };

  const openDelete = ({ obj, type, message }) => {
    setDeleteConfirmOpen(true);
    setInfoNeededToDelete(obj);
    setDeleteType(type);
    setWarningMessage(message);
  };

  const closeDelete = () => {
    setDeleteConfirmOpen(false);
    setInfoNeededToDelete({});
    setWarningMessage('');
    setDeleteType(null);
  };

  const onDelete = () => {
    deleteType === 'ENTITY' ? deleteEntity(infoNeededToDelete) : deleteProject(infoNeededToDelete);
    closeDelete();
  };

  return (
    <UniversalDrawer
      title={
        <FlexRow padding="0" alignItems="center" justifyContent="space-between">
          <div>{`${client.name} Entities`}</div>
          <AddEntityPopover
            qboLinked={company.qbo_linked}
            companyQboId={company.qboDetails && company.qboDetails.qbo_company_id}
            client={client}
            createEntity={createEntity}
            clients={clients}
          />
        </FlexRow>
      }
      {...rest}
    >
      <Table
        columns={entityDrawerColumns({
          openDelete,
          company,
          client,
          updateEntity,
          createProject
        })}
        dataSource={client.entities && client.entities.sort((a, b) => a.name.localeCompare(b.name))}
        pagination={false}
        rowKey={(record) => record.id}
        loading={clients.entitiesLoading || clients.projectsLoading}
        expandable={{
          rowExpandable: (record) => record.projects.length > 0,
          expandedRowRender: (record) => (
            <Table
              pagination={false}
              columns={entityDrawerProjectColumns({
                openDelete,
                client,
                updateProject,
                openCustomRatesModal
              })}
              dataSource={record.projects.sort((a, b) => a.name.localeCompare(b.name))}
              rowKey={(project) => project.id}
            />
          )
        }}
      />

      <CustomRatesModal
        selectedProject={selectedProject}
        visible={customRatesModalOpen}
        updateProjectCustomRates={updateProjectCustomRates}
        clientId={client.id}
        closeCustomRatesModal={closeCustomRatesModal}
      />

      <DeleteConfirmationModal
        emphasize={true}
        visible={deleteConfirmOpen}
        deleteFn={onDelete}
        cancelFn={closeDelete}
        warningMessage={warningMessage}
      />
    </UniversalDrawer>
  );
};

const mapStateToProps = ({ clients, company }) => ({
  clients,
  company
});

const mapDispatchToProps = (dispatch) => ({
  createEntity: (payload) => dispatch(createEntityAction(payload)),
  deleteEntity: (payload) => dispatch(deleteEntityAction(payload)),
  updateEntity: (payload) => dispatch(updateEntityAction(payload)),
  createProject: (payload) => dispatch(createProjectAction(payload)),
  deleteProject: (payload) => dispatch(deleteProjectAction(payload)),
  updateProject: (payload) => dispatch(updateProjectAction(payload)),
  updateProjectCustomRates: (payload) => dispatch(updateProjectCustomRatesAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityDrawer);
