import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import { UniversalInputNumber, UniversalTextArea } from '../../../components';

const EditInvoiceModal = ({
  currentTimeSpent,
  currentRate,
  currentDescription,
  id,
  visible,
  setVisibility,
  updateEntry,
  ...rest
}) => {
  const [description, setDescription] = useState(currentDescription);
  const [rate, setRate] = useState(currentRate);
  const [timeSpent, setTimeSpent] = useState(currentTimeSpent);

  useEffect(() => {
    if (visible) {
      setDescription(currentDescription);
      setRate(currentRate);
      setTimeSpent(currentTimeSpent);
    }
  }, [currentDescription, visible, currentRate, currentTimeSpent]);

  const cancelEdit = () => {
    setVisibility(false);
    setDescription('');
    setRate('');
    setTimeSpent('');
  };

  const onUpdateEntry = () => {
    updateEntry({
      id,
      description,
      rate,
      timeSpent
    });
    cancelEdit();
  };

  const notDifferent =
    description === currentDescription && rate === currentRate && timeSpent === currentTimeSpent;

  return (
    <Modal
      title="Edit Entry"
      onOk={onUpdateEntry}
      okText="Edit"
      onCancel={cancelEdit}
      okButtonProps={{ disabled: notDifferent }}
      visible={visible}
      {...rest}
    >
      <UniversalTextArea
        title="Description"
        inputValue={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <UniversalInputNumber
        title="Quantity"
        inputValue={timeSpent}
        onChange={(val) => setTimeSpent(val)}
      />
      <UniversalInputNumber title="Rate" inputValue={rate} onChange={(val) => setRate(val)} />
    </Modal>
  );
};

export default EditInvoiceModal;
