import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import {
  DrawerLoader,
  PrimaryButton,
  SecondaryButton,
  FlexRow,
  UniversalDrawer
} from './../../../components';
import { fetchClientsFromQboAction } from './../../../ducks/clients';
import { quickbooksDrawerColumns } from './constants';

const QuickbooksDrawer = ({
  fetchClientsFromQbo,
  visible,
  stagedEntitiesProp,
  loading,
  clientsProp,
  onClose,
  bulkCreateFromQbo,
  ...rest
}) => {
  const [stagedEntities, setStagedEntities] = useState([]);
  const [newClients, setNewClients] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [newClient, setNewClient] = useState('');

  useEffect(() => {
    if (visible && !stagedEntitiesProp.length) {
      fetchClientsFromQbo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, fetchClientsFromQbo]);

  useEffect(() => {
    if (clientsProp.length) {
      setAllClients(clientsProp.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [clientsProp, setAllClients]);

  useEffect(() => {
    if (stagedEntitiesProp.length) {
      setStagedEntities(stagedEntitiesProp);
    }
  }, [stagedEntitiesProp]);

  const cancel = () => {
    onClose();
    setStagedEntities(stagedEntitiesProp);
    setAllClients(clientsProp.sort((a, b) => a.name.localeCompare(b.name)));
    setNewClients([]);
    setNewClient('');
  };

  const addClient = () => {
    const uuid = `client-${new Date().getTime()}`;
    setNewClients(
      [...newClients, { name: newClient, id: uuid }].sort((a, b) => a.name.localeCompare(b.name))
    );
    setAllClients(
      [...allClients, { name: newClient, id: uuid }].sort((a, b) => a.name.localeCompare(b.name))
    );
    setNewClient('');
  };

  const removeEntity = (index) => {
    setStagedEntities([...stagedEntities.slice(0, index), ...stagedEntities.slice(index + 1)]);
  };

  const importEntities = () => {
    bulkCreateFromQbo({
      newClients,
      newEntities: stagedEntities
    });
    cancel();
  };

  const hasNoEmpty =
    stagedEntities.length &&
    stagedEntities.reduce((total, curr) => {
      return total * !!curr.display_name * !!curr.client_id;
    }, 1);

  return (
    <UniversalDrawer
      onClose={cancel}
      title="Import Entities from Quickbooks"
      visible={visible}
      destroyOnClose
      footer={
        !loading && (
          <FlexRow justifyContent="flex-end" alignItems="center">
            <SecondaryButton onClick={cancel} style={{ marginRight: '20px' }}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={importEntities}
              disabled={!hasNoEmpty}
              icon={<ImportOutlined />}
            >
              Import
            </PrimaryButton>
          </FlexRow>
        )
      }
      {...rest}
    >
      {loading ? (
        <DrawerLoader tip="Loading Data from Quickbooks..." />
      ) : (
        <Table
          scroll={{ y: ((window.innerHeight - 200) / 50 - 1) * 50 + 30 }}
          pagination={false}
          size="small"
          dataSource={stagedEntities}
          rowKey="qbo_customer_id"
          columns={quickbooksDrawerColumns({
            setStagedEntities,
            stagedEntities,
            newClient,
            setNewClient,
            addClient,
            allClients,
            removeEntity
          })}
          style={{ width: '100%' }}
        />
      )}
    </UniversalDrawer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchClientsFromQbo: (payload) => dispatch(fetchClientsFromQboAction(payload))
});

export default connect(null, mapDispatchToProps)(QuickbooksDrawer);
