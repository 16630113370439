import { takeLatest, call, put } from 'redux-saga/effects';

import {
  FETCH_USER_INFO,
  storeUserInfo,
  stopUserLoading,
  FETCH_USER_PAGE_INFO,
  startUserPageLoading,
  stopUserPageLoading,
  UPDATE_SELF,
  startMyTimeLoading,
  stopMyTimeLoading,
  storeMyTimeInfo,
  FETCH_MY_TIME_DETAILS,
  DELETE_ENTRY,
  EDIT_ENTRY
} from './index';
import { findClientInfo } from './../../utils/moment-utils';
import { startReportsLoading, stopReportsLoading } from './../reports';
import { fetchUserDetailData } from './../reports/sagas';
import { storeOrgInfo } from './../company';
import Api from './../../utils/api-wrapper';

export function* fetchUserInfo() {
  try {
    const { user, sessionExpires } = yield call(Api.fetchMeInfo);
    yield put(storeUserInfo({ ...user, sessionExpires }));
    const { org } = yield call(Api.fetchOrgData, {});
    yield put(storeOrgInfo(org));
    yield put(stopUserLoading());
  } catch (err) {
    yield put(stopUserLoading());
    console.log(err);
  }
}

export function* fetchUserPageInfo({ payload: { userId } }) {
  try {
    yield put(startUserPageLoading());
    const { user } = yield call(Api.fetchUserInfo, { userId });
    yield put(storeUserInfo(user));
    yield put(stopUserLoading());
  } catch (err) {
    yield put(stopUserPageLoading());
    console.log(err);
  }
}

export function* updateSelf({ payload: { data, userId } }) {
  try {
    yield put(startUserPageLoading());
    yield call(Api.updateSelf, { data });
    yield call(fetchUserPageInfo, { payload: { userId } });
  } catch (err) {
    yield put(stopUserPageLoading());
    console.log(err);
  }
}

export function* fetchMyTimeDetails({ payload: { startDate, endDate } }) {
  try {
    yield put(startMyTimeLoading());
    const { clients, countByUser, daysInRange, entries } = yield call(
      Api.fetchPayPeriodDetailsForLoggedInUser,
      {
        startDate,
        endDate
      }
    );

    const clientInfo = findClientInfo({ clients, entries, daysInRange });

    yield put(storeMyTimeInfo({ countByUser, daysInRange, clientInfo, entries }));
  } catch (err) {
    yield put(stopMyTimeLoading());
    console.log(err);
  }
}

export function* deleteEntry({ payload: { entryId, startDate, endDate, adminPage, userId } }) {
  try {
    yield put(adminPage ? startReportsLoading() : startMyTimeLoading());
    yield call(Api.deleteEntry, { entryId });

    if (adminPage) {
      yield call(fetchUserDetailData, { payload: { startDate, endDate, userId } });
    } else {
      yield call(fetchMyTimeDetails, { payload: { startDate, endDate } });
    }
  } catch (err) {
    yield put(adminPage ? stopReportsLoading() : stopMyTimeLoading());
    console.log(err);
  }
}

export function* editEntry({ payload: { entryId, data, startDate, endDate, adminPage, userId } }) {
  try {
    yield put(adminPage ? startReportsLoading() : startMyTimeLoading());
    yield call(Api.editEntry, { entryId, data });

    if (adminPage) {
      yield call(fetchUserDetailData, { payload: { startDate, endDate, userId } });
    } else {
      yield call(fetchMyTimeDetails, { payload: { startDate, endDate } });
    }
  } catch (err) {
    yield put(adminPage ? stopReportsLoading() : stopMyTimeLoading());
    console.log(err);
  }
}

export const userSagas = [
  takeLatest(FETCH_USER_INFO, fetchUserInfo),
  takeLatest(FETCH_USER_PAGE_INFO, fetchUserPageInfo),
  takeLatest(UPDATE_SELF, updateSelf),
  takeLatest(FETCH_MY_TIME_DETAILS, fetchMyTimeDetails),
  takeLatest(DELETE_ENTRY, deleteEntry),
  takeLatest(EDIT_ENTRY, editEntry)
];

export default userSagas;
