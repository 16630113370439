import styled from 'styled-components';

import { COLORS } from './../../../constants/styles';

export const LogoContainer = styled.div`
  margin: 10px 0px;
  padding: 30px;
  border: 1px solid ${COLORS.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
