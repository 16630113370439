import React from 'react';
import { Drawer } from 'antd';

export const UniversalDrawer = ({ title, width, closable, children, ...rest }) => {
  return (
    <Drawer title={title} width={width || 1100} closable={closable || false} {...rest}>
      {children}
    </Drawer>
  );
};
