import React, { Fragment } from 'react';
import { Avatar, List, Divider } from 'antd';
import { WarningOutlined, EditOutlined } from '@ant-design/icons';

import { COLORS } from './../../constants/styles';
import {
  FlexRowStyled,
  StyledPage,
  StyledFullPageLoadingContainer,
  StyledPageWithSidebar,
  StyledBorderedForm,
  StyledCard,
  StyledCardTitle,
  StyledCardContent,
  WarningContainer,
  EntryStyledCard,
  EntryStyledCardTitle,
  EntryCardButtonRow,
  GradientBorder
} from './styled-components';
import { FullPageLoader, SecondaryButton, PrimaryButton } from './../';

export const FlexRow = ({ children, ...rest }) => (
  <FlexRowStyled {...rest}>{children}</FlexRowStyled>
);

export const Page = ({ children, ...rest }) => <StyledPage {...rest}>{children}</StyledPage>;

export const FullPageLoadingContainer = ({ children, contentContainerSize, ...rest }) => (
  <StyledFullPageLoadingContainer contentContainerSize={contentContainerSize} {...rest}>
    {children}
  </StyledFullPageLoadingContainer>
);

export const PageWithSidebar = ({ children, ...rest }) => (
  <StyledPageWithSidebar {...rest}>{children}</StyledPageWithSidebar>
);

export const ContentContainer = ({ loading, children, loadingTip, ...rest }) => {
  return loading ? (
    <FullPageLoader tip={loadingTip} contentContainerSize={true} {...rest} />
  ) : (
    children
  );
};

export const BorderedForm = ({ children, ...rest }) => (
  <StyledBorderedForm {...rest}>{children}</StyledBorderedForm>
);

export const WarningMessage = ({ message }) => (
  <WarningContainer>
    <WarningOutlined
      style={{ marginRight: '20px', fontSize: '30px', color: COLORS.warningColor }}
    />
    {message}
  </WarningContainer>
);

export const Card = ({ children, title, action, grayBackground, ...rest }) => (
  <StyledCard grayBackground={grayBackground} {...rest}>
    <GradientBorder />
    <StyledCardTitle>
      <div>{title}</div>
      <div>{action}</div>
    </StyledCardTitle>
    <StyledCardContent grayBackground={grayBackground}>{children}</StyledCardContent>
  </StyledCard>
);

export const AvatarCard = ({ noAvatar, name, action, children, grayBackground, ...rest }) => (
  <Card
    title={
      <div>
        {!noAvatar && (
          <Avatar shape="square" style={{ backgroundColor: `${COLORS.primaryColor}` }}>
            {name.split('')[0]}
          </Avatar>
        )}

        <span style={{ marginLeft: '10px', fontSize: '18px', color: '#000' }}>{name}</span>
      </div>
    }
    action={action}
    grayBackground={grayBackground}
    {...rest}
  >
    {children}
  </Card>
);

export const EntryBaseCard = ({
  children,
  title,
  action,
  primaryDisabled,
  onEdit,
  onCancel,
  ...rest
}) => (
  <EntryStyledCard {...rest}>
    <EntryStyledCardTitle>
      <div>{title}</div>
      <div>{action}</div>
    </EntryStyledCardTitle>
    <StyledCardContent>{children}</StyledCardContent>
    <EntryCardButtonRow>
      <SecondaryButton onClick={onCancel} style={{ marginRight: '10px' }}>
        Cancel
      </SecondaryButton>
      <PrimaryButton onClick={onEdit} disabled={primaryDisabled} icon={<EditOutlined />}>
        Edit
      </PrimaryButton>
    </EntryCardButtonRow>
  </EntryStyledCard>
);

export const EntryCard = ({ name, action, children, ...rest }) => (
  <EntryBaseCard
    title={
      <div>
        <Avatar shape="square" style={{ backgroundColor: `${COLORS.primaryColor}` }}>
          {name.split('')[0]}
        </Avatar>
        <span style={{ marginLeft: '10px', color: '#000' }}>{name}</span>
      </div>
    }
    action={action}
    {...rest}
  >
    {children}
  </EntryBaseCard>
);

export const GridList = ({ style, ...rest }) => {
  return (
    <List
      style={style}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 4
      }}
      {...rest}
    />
  );
};

export const ButtonRow = ({ children, marginTop }) => {
  return (
    <Fragment>
      <Divider
        style={{
          width: 'calc(100% + 40px)',
          margin: `${marginTop || 0}px -20px 0px`,
          padding: '0px'
        }}
      />
      <FlexRow justifyContent="flex-end" margin="0px" padding="10px 0px 0px" alignItems="center">
        {children}
      </FlexRow>
    </Fragment>
  );
};
