import React from 'react';
import {
  ClockCircleOutlined,
  BarChartOutlined,
  SettingOutlined,
  TeamOutlined,
  BookOutlined,
  IdcardOutlined,
  FileAddOutlined,
  ExclamationCircleOutlined,
  RollbackOutlined,
  BankOutlined,
  AuditOutlined,
  DotChartOutlined
} from '@ant-design/icons';

export const adminMenu = [
  { key: 'clients', value: 'Clients', icon: <IdcardOutlined style={{ fontSize: '18px' }} /> },
  { key: 'employees', value: 'Employees', icon: <TeamOutlined style={{ fontSize: '18px' }} /> },
  { key: 'invoices', value: 'Invoices', icon: <BookOutlined style={{ fontSize: '18px' }} /> },
  { key: 'reports', value: 'Reports', icon: <BarChartOutlined style={{ fontSize: '18px' }} /> },
  { key: 'settings', value: 'Settings', icon: <SettingOutlined style={{ fontSize: '18px' }} /> }
];

export const userMenu = [
  {
    key: 'time',
    value: 'Work Entries',
    icon: <ClockCircleOutlined style={{ fontSize: '18px' }} />
  },
  { key: 'reports', value: 'Reports', icon: <BarChartOutlined style={{ fontSize: '18px' }} /> },
  { key: 'settings', value: 'Settings', icon: <SettingOutlined style={{ fontSize: '18px' }} /> }
];

export const invoicingSubMenu = [
  {
    key: 'create',
    value: 'Create Invoice',
    icon: <FileAddOutlined style={{ fontSize: '18px' }} />
  },
  {
    key: 'awaiting',
    value: 'Awaiting Approval',
    icon: <ExclamationCircleOutlined style={{ fontSize: '18px' }} />
  },
  { key: 'sent', value: 'Sent To QBO', icon: <RollbackOutlined style={{ fontSize: '18px' }} /> }
];

export const adminReportsSubMenu = [
  {
    key: 'payroll',
    value: 'Employee Payroll',
    icon: <BankOutlined style={{ fontSize: '18px' }} />
  },
  {
    key: 'monthlyFeeAudit',
    value: 'Monthly Fee Audit',
    icon: <AuditOutlined style={{ fontSize: '18px' }} />
  },
  {
    key: 'userDetail',
    value: 'User Detail',
    icon: <DotChartOutlined style={{ fontSize: '18px' }} />
  }
];
