import React, { useState } from 'react';
import { Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  PrimaryButton,
  SecondaryButton,
  UniversalDatePicker,
  UniversalInputNumber,
  ButtonRow
} from './../../../components';

const AddMonthlyFeePopover = ({ disabled, createMonthlyFee, clientId, latestMonthlyFee }) => {
  const [monthlyFeePopoverVisible, setMonthlyFeePopoverVisible] = useState(false);
  const [amount, setAmount] = useState('');
  const [dateBegan, setDateBegan] = useState(null);

  const cancelAdd = () => {
    setMonthlyFeePopoverVisible(false);
    setAmount('');
    setDateBegan(null);
  };

  const disabledDates = (current) => {
    if (latestMonthlyFee) {
      return (
        (current && current < moment(latestMonthlyFee.date_began).endOf('day')) ||
        current >= moment().endOf('day')
      );
    }
  };

  const addMonthlyFee = () => {
    const data = {
      date_began: moment(dateBegan).utc().format(),
      amount: Number(amount.toFixed(2)),
      client_id: clientId,
      previousMonthlyFeeId: latestMonthlyFee && latestMonthlyFee.id
    };

    createMonthlyFee(data);
    cancelAdd();
  };

  const anyBlank = !amount || !dateBegan;

  return (
    <Popover
      trigger="click"
      visible={monthlyFeePopoverVisible}
      onVisibleChange={(visible) => setMonthlyFeePopoverVisible(visible)}
      title="Add Rate"
      placement="bottomRight"
      overlayStyle={{ minWidth: '300px' }}
      content={
        <div>
          <UniversalInputNumber
            title="Amount*"
            inputValue={amount}
            onChange={setAmount}
            currency={true}
          />
          <UniversalDatePicker
            title="Starting Date*"
            value={dateBegan && moment(dateBegan)}
            onChange={(date) => date && setDateBegan(date.utc().format())}
            disabledDates={disabledDates}
          />

          <ButtonRow>
            <SecondaryButton onClick={cancelAdd} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={addMonthlyFee} disabled={anyBlank}>
              Add
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <PrimaryButton disabled={disabled} icon={<PlusOutlined />}>
        Add Fee
      </PrimaryButton>
    </Popover>
  );
};

export default AddMonthlyFeePopover;
