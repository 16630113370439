import React, { useState, useEffect } from 'react';
import { Popover, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import {
  PrimaryButton,
  SecondaryButton,
  UniversalInput,
  UniversalRadioButton,
  UniversalInputNumber,
  ButtonRow
} from './../../../components';
import { pojectPopoverRadioOptions } from './constants';

const EditProjectPopover = ({ client, record, updateProject }) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [fixedFeeAmount, setFixedFeeAmount] = useState(null);
  const [retired, setRetired] = useState(false);

  useEffect(() => {
    if (visible) {
      setName(record.name);
      setType(record.billing_type);
      setFixedFeeAmount(record.fixed_fee_amount);
      setRetired(record.retired);
    }
  }, [
    visible,
    record.name,
    setName,
    setType,
    record.billing_type,
    record.fixed_fee_amount,
    record.retired
  ]);

  const cancelEdit = () => {
    setVisible(false);
    setName('');
    setType('');
    setFixedFeeAmount(null);
    setRetired(false);
  };

  const callEditProject = () => {
    updateProject({
      data: {
        name,
        billing_type: type,
        fixed_fee_amount:
          type === 'Fixed' && fixedFeeAmount ? Number(fixedFeeAmount.toFixed(2)) : 0,
        retired
      },
      projectId: record.id,
      clientId: client.id
    });
    cancelEdit();
  };

  const hasEmptyField = !name || !type || (type === 'Fixed' && !fixedFeeAmount);
  const isNotDifferent =
    name === record.name &&
    type === record.billing_type &&
    fixedFeeAmount === record.fixed_fee_amount &&
    retired === record.retired;

  return (
    <Popover
      trigger="click"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      title="Edit Project"
      placement="bottomRight"
      content={
        <div>
          <UniversalInput
            title="Name*"
            inputValue={name}
            onChange={(e) => setName(e.target.value)}
          />

          <UniversalRadioButton
            title="Billing Type*"
            radioValue={type}
            onChange={(e) => setType(e.target.value)}
            options={pojectPopoverRadioOptions({
              monthlyCheck: !(client.monthly_fee || record.billing_type === 'Monthly')
            })}
          />

          {type === 'Fixed' && (
            <UniversalInputNumber
              title="Amount*"
              inputValue={fixedFeeAmount}
              onChange={setFixedFeeAmount}
              currency={true}
            />
          )}

          <UniversalRadioButton
            title="Retired*"
            radioValue={retired}
            onChange={(e) => setRetired(e.target.value)}
            options={[
              { value: true, name: 'Yes' },
              { value: false, name: 'No' }
            ]}
          />

          <ButtonRow>
            <SecondaryButton onClick={cancelEdit} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={hasEmptyField || isNotDifferent} onClick={callEditProject}>
              Edit
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <Tooltip title={`Edit ${record.name}`}>
        <SecondaryButton style={{ marginRight: '15px' }} icon={<EditOutlined />} />
      </Tooltip>
    </Popover>
  );
};

export default EditProjectPopover;
