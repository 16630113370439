import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import {
  PrimaryButton,
  SecondaryButton,
  FlexRow,
  UniversalInputNumber
} from './../../../components';

const EditMonthlyFeePopover = ({ disabled, currentAmount, recordId, updateMonthlyFee }) => {
  const [monthlyFeePopoverVisible, setMonthlyFeePopoverVisible] = useState(false);
  const [amount, setAmount] = useState(currentAmount);

  useEffect(() => {
    if (monthlyFeePopoverVisible) {
      setAmount(currentAmount);
    }
  }, [currentAmount, monthlyFeePopoverVisible]);

  const cancelAdd = () => {
    setMonthlyFeePopoverVisible(false);
    setAmount('');
  };

  const editMonthlyFee = () => {
    const data = {
      data: { amount: Number(amount.toFixed(2)) },
      monthlyFeeId: recordId
    };
    updateMonthlyFee(data);
    cancelAdd();
  };

  const notDifferent = amount === currentAmount;

  return (
    <Popover
      trigger="click"
      visible={monthlyFeePopoverVisible}
      onVisibleChange={(visible) => setMonthlyFeePopoverVisible(visible)}
      title="Edit Fee"
      placement="bottomRight"
      content={
        <div>
          <UniversalInputNumber
            title="Amount*"
            value={amount}
            onChange={setAmount}
            currency={true}
          />

          <FlexRow justifyContent="flex-end">
            <SecondaryButton onClick={cancelAdd} margin="0px 20px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={editMonthlyFee} disabled={notDifferent}>
              Edit
            </PrimaryButton>
          </FlexRow>
        </div>
      }
    >
      <SecondaryButton disabled={disabled} icon={<EditOutlined />} />
    </Popover>
  );
};

export default EditMonthlyFeePopover;
