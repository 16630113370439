import React from 'react';
import { Radio, Checkbox } from 'antd';

import { StyledButton } from './styled-components';
import { TitledData } from './../';

export const PrimaryButton = ({ icon, loading, onClick, children, ...rest }) => (
  <StyledButton type="primary" icon={icon} loading={loading} onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

export const SecondaryButton = ({ icon, loading, onClick, children, ...rest }) => (
  <StyledButton icon={icon} loading={loading} onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

export const DashedButton = ({ icon, loading, onClick, children, ...rest }) => (
  <StyledButton type="dashed" icon={icon} loading={loading} onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

export const LinkButton = ({ icon, loading, onClick, children, ...rest }) => (
  <StyledButton type="link" icon={icon} loading={loading} onClick={onClick} {...rest}>
    {children}
  </StyledButton>
);

export const UniversalRadioButton = ({ title, radioValue, onChange, options }) => (
  <TitledData
    title={title}
    value={
      <Radio.Group value={radioValue} onChange={onChange}>
        {options.map(
          (option) =>
            !option.shouldHide && (
              <Radio.Button key={option.value} value={option.value}>
                {option.name}
              </Radio.Button>
            )
        )}
      </Radio.Group>
    }
  />
);

export const UniversalCheckBox = ({ title, checkValue, onChange }) => (
  <TitledData title={title} value={<Checkbox onChange={onChange} checked={checkValue} />} />
);
