import React from 'react';

import { PageContainer } from './styled-components';
import { TitleH4 } from './../../components';

const TermsOfService = () => (
  <PageContainer>
    <TitleH4>Terms of Service</TitleH4>
    <br />

    <p>Terms of Service ("Terms")</p>

    <p>Last updated: 05/14/20202</p>

    <p>
      Please read these Terms of Service ("Terms", "Terms of Service") carefully before using
      www.chronicl.com (the "Service").
    </p>

    <p>
      Your access to and use of the Service is conditioned on your acceptance of and compliance with
      these Terms. These Terms apply to all visitors, users and others who access or use the
      Service.
    </p>

    <p>
      By accessing or using the Service you agree to be bound by these Terms. If you disagree with
      any part of the terms then you may not access the Service.
    </p>

    <p>
      We may terminate or suspend access to our Service immediately, without prior notice or
      liability, for any reason whatsoever, including without limitation if you breach the Terms.
      All provisions of the Terms which by their nature should survive termination shall survive
      termination, including, without limitation, ownership provisions, warranty disclaimers,
      indemnity and limitations of liability.
    </p>

    <p>
      We reserve the right, at our sole discretion, to modify or replace these Terms at any time.{' '}
    </p>
  </PageContainer>
);

export default TermsOfService;
