import axios from 'axios';
import dotenv from 'dotenv';
import store from './../ducks/store';

dotenv.config();

const API_BASE_URL = process.env.REACT_APP_API_FULL_BASE_URL;

const METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

const URIS = {
  //CLIENTS
  CLIENTS: () => `/clients`,
  CLIENT: ({ clientId }) => `/clients/${clientId}`,
  BULK_CLIENTS: () => `/clients/bulk`,

  //ENTITIES
  FETCH_ENTITIES_BY_CLIENT: ({ clientId }) => `/clients/${clientId}/entities`,
  ENTITIES_ENDPOINT: () => '/entities',
  UPDATE_ENTITY: ({ entityId }) => `/entities/${entityId}`,

  //ENTRIES
  ENTRIES_ENDPOINT: () => '/entries',
  FETCH_PAY_PERIOD_DETAILS_FOR_LOGGED_IN_USER: ({ startDate, endDate }) =>
    `/entries/payPeriodDetailsForLoggedInUser/${startDate}/${endDate}`,
  UPDATE_ENTRY: ({ entryId }) => `/entries/${entryId}`,
  FETCH_PERIOD_INFO: ({ startDate, endDate }) =>
    startDate && endDate
      ? `/entries/periodInfo?startDate=${startDate}&endDate=${endDate}`
      : '/entries/periodInfo',

  //INVOICES
  BULK_CREATE_INVOICES: () => '/invoices/bulkCreate',
  FETCH_IN_PROGRESS_INVOICES: () => '/invoices/saved',
  FETCH_INVOICES_AWAITING_APPROVAL: () => '/invoices/awaitingApproval',
  FETCH_INVOICES_SENT_TO_QBO: () => '/invoices/sent',
  DELETE_INVOICE: ({ invoiceId }) => `/invoices/${invoiceId}`,
  INVOICE_ENDPOINT: ({ invoiceId }) => `/invoices/${invoiceId}`,

  //MONTHLY FEES
  UPDATE_MONTHLY_FEE: ({ monthlyFeeId }) => `/monthly-fees/${monthlyFeeId}`,
  MONTHLY_FEES_ENDPOINT: () => '/monthly-fees',

  //ORGANIZATION
  FETCH_ORG_DATA: () => `/organization`,
  FETCH_ORG_QBO_DATA: () => `/organization/qboCredentials`,
  FETCH_QBO_CLIENTS: () => `/organization/qboClients`,
  FETCH_QBO_USERS: () => `/organization/qboUsers`,
  FETCH_QBO_ITEMS: () => `/organization/qboItems`,

  //PROJECTS
  PROJECTS_ENDPOINT: () => '/projects',
  UPDATE_PROJECT: ({ projectId }) => `/projects/${projectId}`,
  UPDATE_PROJECT_CUSTOM_RATES: ({ projectId }) => `/projects/${projectId}/rates`,
  FETCH_PROJECTS_BY_ENTITY: ({ entityId }) => `/entities/${entityId}/projects`,

  //RATES
  RATES_ENDPOINT: () => '/rates',
  UPDATE_RATES: ({ rateId }) => `/rates/${rateId}`,

  //REPORTS
  PAYROLL_ENDPOINT: ({ startDate, endDate }) =>
    `/reporting/payroll?startDate=${startDate}&endDate=${endDate}`,
  MONTHLY_AUDIT_ENDPOINT: ({ startDate, endDate }) =>
    `/reporting/monthlyClientAudit?startDate=${startDate}&endDate=${endDate}`,
  USER_DETAIL_ENDPOINT: ({ startDate, endDate, userId }) =>
    `/reporting/userDetail/${userId}?startDate=${startDate}&endDate=${endDate}`,

  //USERS
  FETCH_USERS_BY_ORG: () => `/users`,
  FIND_ME: () => `/all/me`,
  UPDATE_USER: ({ userId }) => `/users/${userId}`,
  USERS_ENDPOINT: () => '/users',
  BULK_CREATE_USERS: () => '/users/bulk',
  UPDATE_SELF: () => `/users/me`,
  FETCH_USER: ({ userId }) => `/users/${userId}`
};

const createClient = () => {
  const state = store.getState();
  let orgId = state.company && state.company.orgId;
  if (!orgId) orgId = state.user && state.user.organization_id;

  let baseURL = API_BASE_URL;
  if (orgId) {
    baseURL = `${baseURL}/${orgId}`;
  }
  return axios.create({ baseURL, withCredentials: true });
};

const onSuccess = ({ data }) => data;

const onError = (response) => {
  const { status, error_message } = response.response.data;
  const errorMsg = error_message || 'Something went wrong';

  const errorArray = [errorMsg];
  const message = errorArray.map((err) => ({ type: 'error', message: err }));

  return Promise.reject({ message, status }); // eslint-disable-line
};

const request = ({ ...options }) => {
  const state = store.getState();
  const sessionExpires = state && state.user && state.user.sessionExpires;

  if (sessionExpires) {
    const now = new Date();
    const sessionExpiryDate = new Date(sessionExpires);

    if (now > sessionExpiryDate) {
      // Session has expired, redirect the user to the home page
      window.location.href = '/';
      return;
    }
  }

  return createClient()(options).then(onSuccess).catch(onError);
};

export default {
  fetchMeInfo: () =>
    request({
      url: URIS.FIND_ME(),
      method: METHODS.GET
    }),

  fetchUserInfo: ({ userId }) =>
    request({
      url: URIS.FETCH_USER({ userId }),
      method: METHODS.GET
    }),

  fetchOrgData: () =>
    request({
      url: URIS.FETCH_ORG_DATA(),
      method: METHODS.GET
    }),

  fetchOrgQboData: () =>
    request({
      url: URIS.FETCH_ORG_QBO_DATA(),
      method: METHODS.GET
    }),

  fetchClientsByOrg: () =>
    request({
      url: URIS.CLIENTS(),
      method: METHODS.GET
    }),

  fetchEntitiesByClient: ({ clientId }) =>
    request({
      url: URIS.FETCH_ENTITIES_BY_CLIENT({ clientId }),
      method: METHODS.GET
    }),

  fetchProjectsByEntity: ({ entityId }) =>
    request({
      url: URIS.FETCH_PROJECTS_BY_ENTITY({ entityId }),
      method: METHODS.GET
    }),

  updateClient: ({ data, clientId }) =>
    request({
      url: URIS.CLIENT({ clientId }),
      method: METHODS.PUT,
      data
    }),

  createClient: ({ data }) =>
    request({
      url: URIS.CLIENTS(),
      method: METHODS.POST,
      data
    }),

  deleteClient: ({ clientId }) =>
    request({
      url: URIS.CLIENT({ clientId }),
      method: METHODS.DELETE
    }),

  createEntity: ({ data }) =>
    request({
      url: URIS.ENTITIES_ENDPOINT(),
      method: METHODS.POST,
      data
    }),

  deleteEntity: ({ entityId }) =>
    request({
      url: URIS.UPDATE_ENTITY({ entityId }),
      method: METHODS.DELETE
    }),

  updateEntity: ({ entityId, data }) =>
    request({
      url: URIS.UPDATE_ENTITY({ entityId }),
      method: METHODS.PUT,
      data
    }),

  createProject: ({ data }) =>
    request({
      url: URIS.PROJECTS_ENDPOINT(),
      method: METHODS.POST,
      data
    }),

  deleteProject: ({ projectId }) =>
    request({
      url: URIS.UPDATE_PROJECT({ projectId }),
      method: METHODS.DELETE
    }),

  updateProject: ({ projectId, data }) =>
    request({
      url: URIS.UPDATE_PROJECT({ projectId }),
      method: METHODS.PUT,
      data
    }),

  updateProjectCustomRates: ({ projectId, data }) =>
    request({
      url: URIS.UPDATE_PROJECT_CUSTOM_RATES({ projectId }),
      method: METHODS.PUT,
      data
    }),

  createEntry: ({ data }) =>
    request({
      url: URIS.ENTRIES_ENDPOINT(),
      method: METHODS.POST,
      data
    }),

  fetchUsersByOrg: () =>
    request({
      url: URIS.FETCH_USERS_BY_ORG(),
      method: METHODS.GET
    }),

  createUser: ({ data }) =>
    request({
      url: URIS.USERS_ENDPOINT(),
      method: METHODS.POST,
      data
    }),

  updateUser: ({ userId, data }) =>
    request({
      url: URIS.UPDATE_USER({ userId }),
      method: METHODS.PUT,
      data
    }),

  deleteUser: ({ userId }) =>
    request({
      url: URIS.UPDATE_USER({ userId }),
      method: METHODS.DELETE
    }),

  createRate: ({ data }) =>
    request({
      url: URIS.RATES_ENDPOINT(),
      method: METHODS.POST,
      data
    }),

  updateRate: ({ rateId, data }) =>
    request({
      url: URIS.UPDATE_RATES({ rateId }),
      method: METHODS.PUT,
      data
    }),

  updateOrg: ({ data }) =>
    request({
      url: URIS.FETCH_ORG_DATA(),
      method: METHODS.PUT,
      data
    }),

  updateSelf: ({ data }) =>
    request({
      url: URIS.UPDATE_SELF(),
      method: METHODS.PUT,
      data
    }),

  updateMonthlyFee: ({ data, monthlyFeeId }) =>
    request({
      url: URIS.UPDATE_MONTHLY_FEE({ monthlyFeeId }),
      method: METHODS.PUT,
      data
    }),

  createMonthlyFee: ({ data }) =>
    request({
      url: URIS.MONTHLY_FEES_ENDPOINT(),
      method: METHODS.POST,
      data
    }),

  fetchClientsFromQbo: () =>
    request({
      url: URIS.FETCH_QBO_CLIENTS(),
      method: METHODS.GET
    }),

  fetchUsersFromQbo: () =>
    request({
      url: URIS.FETCH_QBO_USERS(),
      method: METHODS.GET
    }),

  fetchItemsFromQbo: () =>
    request({
      url: URIS.FETCH_QBO_ITEMS(),
      method: METHODS.GET
    }),

  bulkCreateClient: ({ data }) =>
    request({
      url: URIS.BULK_CLIENTS(),
      method: METHODS.POST,
      data
    }),

  bulkCreateUsers: ({ data }) =>
    request({
      url: URIS.BULK_CREATE_USERS(),
      method: METHODS.POST,
      data
    }),

  fetchPayPeriodDetailsForLoggedInUser: ({ startDate, endDate }) =>
    request({
      url: URIS.FETCH_PAY_PERIOD_DETAILS_FOR_LOGGED_IN_USER({ startDate, endDate }),
      method: METHODS.GET
    }),

  deleteEntry: ({ entryId }) =>
    request({
      url: URIS.UPDATE_ENTRY({ entryId }),
      method: METHODS.DELETE
    }),

  editEntry: ({ entryId, data }) =>
    request({
      url: URIS.UPDATE_ENTRY({ entryId }),
      method: METHODS.PUT,
      data
    }),

  fetchPeriodInfo: ({ startDate, endDate }) =>
    request({
      url: URIS.FETCH_PERIOD_INFO({ startDate, endDate }),
      method: METHODS.GET
    }),

  bulkCreateInvoices: ({ data }) =>
    request({
      url: URIS.BULK_CREATE_INVOICES(),
      method: METHODS.POST,
      data
    }),

  fetchInProgressInvoices: () =>
    request({
      url: URIS.FETCH_IN_PROGRESS_INVOICES(),
      method: METHODS.GET
    }),

  fetchInvoicesAwaitingApproval: () =>
    request({
      url: URIS.FETCH_INVOICES_AWAITING_APPROVAL(),
      method: METHODS.GET
    }),

  fetchInvoicesSentToQuickbooks: () =>
    request({
      url: URIS.FETCH_INVOICES_SENT_TO_QBO(),
      method: METHODS.GET
    }),

  fetchInvoice: ({ invoiceId }) =>
    request({
      url: URIS.INVOICE_ENDPOINT({ invoiceId }),
      method: METHODS.GET
    }),

  updateInvoice: ({ invoiceId, data }) =>
    request({
      url: URIS.INVOICE_ENDPOINT({ invoiceId }),
      method: METHODS.PUT,
      data
    }),

  deleteInvoice: ({ invoiceId, data }) =>
    request({
      url: URIS.DELETE_INVOICE({ invoiceId }),
      method: METHODS.DELETE,
      data
    }),

  fetchPayrollData: ({ startDate, endDate }) =>
    request({
      url: URIS.PAYROLL_ENDPOINT({ startDate, endDate }),
      method: METHODS.GET
    }),

  fetchMonthlyAuditData: ({ startDate, endDate }) =>
    request({
      url: URIS.MONTHLY_AUDIT_ENDPOINT({ startDate, endDate }),
      method: METHODS.GET
    }),

  fetchUserDetailData: ({ startDate, endDate, userId }) =>
    request({
      url: URIS.USER_DETAIL_ENDPOINT({ startDate, endDate, userId }),
      method: METHODS.GET
    })
};
