import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Table, Tag } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  ContentContainer,
  FlexRow,
  TitleH4,
  PrimaryButton,
  UniversalDropdown,
  DeleteConfirmationModal,
  PageContentContainer,
  CardNoTitle
} from './../../../components';
import {
  fetchUsersInfoAction,
  addUserToOrgAction,
  updateUserAction,
  deleteUserAction,
  fetchUsersFromQboAction,
  bulkCreateFromQboAction,
  fetchItemsFromQboAction
} from './../../../ducks/users';
import { fetchQboCredentialsAction } from '../../../ducks/company';
import EmployeeModal from './employeeModal';
import RatesDrawer from './ratesDrawer';
import QuickbooksDrawer from './quickbooksDrawer';
import { addButtonMenu, cardDropdownMenu } from './constants';
import { currencyFormatter } from './../../../utils/formatters';

const Employees = ({
  addUserToOrg,
  updateUser,
  deleteUser,
  company,
  fetchUsers,
  fetchQboDetails,
  fetchQboUsers,
  bulkCreateQboUsers,
  fetchItemsFromQbo,
  users: {
    loading,
    users: usersProp,
    stagedQboEmployees,
    qboUsersLoading,
    qboItems,
    qboItemsLoading
  }
}) => {
  const [users, setUsers] = useState(
    usersProp.sort((a, b) => a.first_name.localeCompare(b.first_name))
  );
  const [employeeModalOpen, setEmployeeModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({ id: null });
  const [ratesDrawerOpen, setRatesDrawerOpen] = useState(false);
  const [quickbooksDrawerOpen, setQuickbooksDrawerOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [userToBeDeleted, setUserToBeDeleted] = useState({});

  useEffect(() => {
    if (company.orgId) {
      fetchUsers();
      fetchQboDetails();
    }
  }, [company.orgId, fetchUsers, fetchQboDetails]);

  useEffect(() => {
    if (selectedEmployee.id) {
      setSelectedEmployee(usersProp.find((user) => user.id === selectedEmployee.id));
    }
    setUsers(usersProp.sort((a, b) => a.first_name.localeCompare(b.first_name)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersProp]);

  const filterUsers = (e) => {
    setUsers(
      usersProp.filter(
        (user) =>
          user.first_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          user.last_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`.includes(
            e.target.value.toLowerCase()
          )
      )
    );
  };

  const closeAllDrawersAndModals = () => {
    setEmployeeModalOpen(false);
    setSelectedEmployee({ id: null });
    setRatesDrawerOpen(false);
    setQuickbooksDrawerOpen(false);
    setUserToBeDeleted({});
    setDeleteModalVisible(false);
  };

  const openDeleteModal = (employee) => {
    setUserToBeDeleted(employee);
    setDeleteModalVisible(true);
  };

  const openEditEmployeeModal = (employee) => {
    setEmployeeModalOpen(true);
    setSelectedEmployee(employee);
  };

  const openAddEmployeeModal = () => {
    setEmployeeModalOpen(true);
  };

  const openRatesDrawer = (employee) => {
    setRatesDrawerOpen(true);
    setSelectedEmployee(employee);
  };

  const onDeleteUser = () => {
    deleteUser(userToBeDeleted);
    closeAllDrawersAndModals();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 180,
      render: (_, user) => {
        return `${user.first_name} ${user.last_name}`;
      }
    },
    {
      title: 'Role',
      render: (_, user) =>
        user.role_id === 1 ? (
          <Tag color="purple">Super Admin</Tag>
        ) : user.role_id === 2 ? (
          <Tag color="blue">Admin</Tag>
        ) : (
          <Tag>User</Tag>
        )
    },
    {
      title: 'Employee Type',
      dataIndex: 'type',
      render: (_, { type }) => <Tag color={type === 'Contractor' ? 'default' : 'green'}>{type}</Tag>
    },
    {
      title: 'Billing Rate',
      dataIndex: 'billing_rate',
      render: (_, user) => {
        const billingRate = user.rates.filter(
          (rate) => rate.type === 'Billing' && !rate.date_ended
        )[0];

        return (
          <Tag color="green">{`${currencyFormatter.format(
            !!billingRate ? billingRate.amount : 0
          )}`}</Tag>
        );
      }
    },
    {
      title: 'Payment Rate',
      dataIndex: 'billing_rate',
      render: (_, user) => {
        const paymentRate = user.rates.filter(
          (rate) => rate.type === 'Payment' && !rate.date_ended
        )[0];

        return (
          <Tag color="red">{`${currencyFormatter.format(
            !!paymentRate ? paymentRate.amount : 0
          )}`}</Tag>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 300
    },
    {
      title: 'QBO Description',
      dataIndex: 'qbo_description',
      render: (_, user) => (
        <Tag>{(user.linked_qbo_item && user.linked_qbo_item.description) || 'N/A'}</Tag>
      )
    },
    {
      title: 'QBO Billing Rate',
      dataIndex: 'qbo_rate',
      render: (_, user) =>
        user.linked_qbo_item && user.linked_qbo_item.unitPrice ? (
          <Tag color="green">{currencyFormatter.format(user.linked_qbo_item.unitPrice)}</Tag>
        ) : (
          <Tag>N/A</Tag>
        )
    },
    {
      title: '',
      dataIndex: 'unique',
      render: (_, user) => {
        return (
          <UniversalDropdown
            baseItem={<Button type="link">Manage</Button>}
            menu={cardDropdownMenu({
              name: `${user.first_name} ${user.last_name}`,
              company,
              user,
              openEditEmployeeModal,
              openRatesDrawer,
              openDeleteModal
            })}
          />
        );
      }
    }
  ];

  return (
    <ContentContainer loading={loading || company.loading} loadingTip="Loading Employees...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>{`${company.name} Employees`}</TitleH4>

        <div>
          <Input.Search
            placeholder="Search Employees"
            style={{ width: 300, marginRight: '10px' }}
            onChange={filterUsers}
          />

          <UniversalDropdown
            baseItem={
              <PrimaryButton>
                Add <DownOutlined />
              </PrimaryButton>
            }
            menu={addButtonMenu({ company, setQuickbooksDrawerOpen, openAddEmployeeModal })}
          />
        </div>
      </FlexRow>

      <PageContentContainer>
        <CardNoTitle>
          <Table
            bordered
            size="small"
            pagination={false}
            columns={columns}
            dataSource={users && users.length ? users : []}
          />
        </CardNoTitle>
      </PageContentContainer>

      <DeleteConfirmationModal
        visible={deleteModalVisible}
        emphasize={true}
        deleteFn={onDeleteUser}
        cancelFn={closeAllDrawersAndModals}
        warningMessage="Deleting Users can cause issues with reports and invoicing. It is highly recommended you do not delete users. To re-instate a deleted user simply add them with the same email."
      />

      <EmployeeModal
        visible={employeeModalOpen}
        employee={selectedEmployee}
        addingNewEmployee={!selectedEmployee.id}
        addEmployeeToOrg={addUserToOrg}
        updateEmployee={updateUser}
        onCancel={closeAllDrawersAndModals}
        qboLinked={company.qbo_linked}
        companyQboId={company.qboDetails && company.qboDetails.qbo_company_id}
        qboItems={qboItems}
        fetchItemsFromQbo={fetchItemsFromQbo}
        qboItemsLoading={qboItemsLoading}
        organizationId={company.orgId}
      />

      <RatesDrawer
        visible={ratesDrawerOpen}
        employee={selectedEmployee}
        closable={false}
        onClose={closeAllDrawersAndModals}
      />

      <QuickbooksDrawer
        visible={quickbooksDrawerOpen}
        onClose={closeAllDrawersAndModals}
        stagedEmployeesProp={stagedQboEmployees}
        fetchQboUsers={fetchQboUsers}
        bulkCreateQboUsers={bulkCreateQboUsers}
        loading={qboUsersLoading}
        qboItems={qboItems}
      />
    </ContentContainer>
  );
};

const mapStateToProps = ({ company, users }) => ({
  company,
  users
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (payload) => dispatch(fetchUsersInfoAction(payload)),
  addUserToOrg: (payload) => dispatch(addUserToOrgAction(payload)),
  updateUser: (payload) => dispatch(updateUserAction(payload)),
  deleteUser: (payload) => dispatch(deleteUserAction(payload)),
  fetchQboDetails: (payload) => dispatch(fetchQboCredentialsAction(payload)),
  fetchQboUsers: (payload) => dispatch(fetchUsersFromQboAction(payload)),
  bulkCreateQboUsers: (payload) => dispatch(bulkCreateFromQboAction(payload)),
  fetchItemsFromQbo: (payload) => dispatch(fetchItemsFromQboAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
