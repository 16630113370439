import React from 'react';
import { Tooltip, Select, Input } from 'antd';
import moment from 'moment';
import {
  UserAddOutlined,
  ImportOutlined,
  EditOutlined,
  DollarOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import { currencyFormatter } from './../../../utils/formatters';
import { QboServiceContainer } from './styled-components';
import { SecondaryText, Text, SecondaryButton } from './../../../components';
import EditRatePopover from './editRatePopover';

const { Option } = Select;

export const addButtonMenu = ({ company, setQuickbooksDrawerOpen, openAddEmployeeModal }) => [
  {
    title: !company.qbo_linked ? (
      <Tooltip title="Link company on settings page.">Import QBO Entities</Tooltip>
    ) : (
      'Import QBO Employees'
    ),
    icon: <ImportOutlined />,
    onClickFn: () => setQuickbooksDrawerOpen(true),
    disabled: !company.qbo_linked
  },
  {
    title: 'Add Manually',
    icon: <UserAddOutlined />,
    onClickFn: openAddEmployeeModal
  }
];

export const cardDropdownMenu = ({
  name,
  company,
  user,
  openEditEmployeeModal,
  openRatesDrawer,
  openDeleteModal
}) => [
  {
    title: `Edit ${name}`,
    icon: <EditOutlined />,
    onClickFn: () => openEditEmployeeModal(user)
  },
  {
    title: ' Manage Rates',
    icon: <DollarOutlined />,
    onClickFn: () => openRatesDrawer(user)
  },
  {
    title: 'Delete',
    icon: <DeleteOutlined />,
    onClickFn: () => openDeleteModal({ userId: user.id })
  }
];

export const employeeRateColumns = ({ employee, updateRate, company }) => [
  {
    title: 'Date Began',
    key: 'date_began',
    dataIndex: 'date_began',
    render: (date) => moment(date).format('MM-DD-YYYY, hh:mm:ss a')
  },
  {
    title: 'Date Ended',
    key: 'date_ended',
    dataIndex: 'date_ended',
    render: (date) => (!!date ? moment(date).format('MM-DD-YYYY, hh:mm:ss a') : 'Current')
  },
  {
    title: 'Hourly Rate',
    key: 'amount',
    dataIndex: 'amount',
    render: (amount) => currencyFormatter.format(amount)
  },
  {
    title: 'Edit',
    key: 'edit',
    render: (text, record) => (
      <EditRatePopover
        currentAmount={record.amount}
        recordId={record.id}
        employee={employee.id}
        updateRate={updateRate}
      />
    )
  }
];

export const quickbooksColumns = ({ updateAttribute, qboItems, removeEmployee }) => [
  {
    title: 'Quickbooks Id',
    dataIndex: 'qbo_id',
    key: 'qbo_id',
    width: 150
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    render: (text, record, index) => (
      <Input
        onChange={(e) => updateAttribute(e.target.value, 'first_name', index)}
        value={record.first_name}
      />
    )
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
    render: (text, record, index) => (
      <Input
        onChange={(e) => updateAttribute(e.target.value, 'last_name', index)}
        value={record.last_name}
      />
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text, record, index) => (
      <Input
        onChange={(e) => updateAttribute(e.target.value, 'email', index)}
        value={record.email}
      />
    )
  },
  {
    title: 'Role',
    dataIndex: 'role_id',
    key: 'role_id',
    render: (text, record, index) => (
      <Select
        style={{ width: '100%' }}
        onChange={(role) => updateAttribute(role, 'role_id', index)}
        value={record.role_id}
        placeholder="Select Role"
      >
        <Option value={2}>Admin</Option>
        <Option value={3}>User</Option>
      </Select>
    )
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text, record, index) => (
      <Select
        style={{ width: '100%' }}
        onChange={(type) => updateAttribute(type, 'type', index)}
        value={record.type}
        placeholder="Select Type"
      >
        <Option value="Employee">Employee</Option>
        <Option value="Contractor">Contractor</Option>
      </Select>
    )
  },
  {
    title: 'Linked QBO Item',
    dataIndex: 'linked_qbo_item',
    key: 'linked_qbo_item',
    render: (text, record, index) => (
      <Select
        style={{ width: '100%' }}
        onChange={(id) =>
          updateAttribute(
            qboItems.find((item) => item.id === id),
            'linked_qbo_item',
            index
          )
        }
        value={record.linked_qbo_item ? record.linked_qbo_item.name : null}
        placeholder="Select Item"
      >
        {qboItems &&
          qboItems.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
      </Select>
    )
  },
  {
    title: 'Remove',
    dataIndex: 'remove',
    key: 'remove',
    width: 100,
    align: 'center',
    render: (text, record, index) => (
      <SecondaryButton onClick={() => removeEmployee(index)} icon={<DeleteOutlined />} />
    )
  }
];
