import styled from 'styled-components';
import { COLORS } from './../../../constants/styles';

export const InvoiceHeader = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${COLORS.borderColor};
`;

export const InvoiceContainer = styled.div`
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: ${COLORS.borderRadius};
  box-sizing: border-box;
`;

export const InvoiceInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  box-sizing: border-box;
  padding: 20px;
`;

export const InvoiceInfoContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid ${COLORS.borderColor};
  box-sizing: border-box;
`;

export const InvoiceEntriesContainer = styled.div`
  padding: 20px 20px 0px;
`;
