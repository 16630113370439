import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export function configureStore(state = {}) {
  const middlewares = [sagaMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(createReducer, state, composeWithDevTools(...enhancers));

  store.runSaga = sagaMiddleware.run;
  store.runSaga(rootSaga);

  return store;
}

const store = configureStore({});
export default store;
