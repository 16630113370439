import moment from 'moment';

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let now = moment(startDate);
  let dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(moment(now));
    now.add(1, 'days');
  }

  return dates;
};

export const findPayPeriodDates = (day, onlyFirstAndLast) => {
  let start = day.date() > 15 ? moment(day).date(16) : moment(day).date(1);
  let end = day.date() > 15 ? moment(day).endOf('month') : moment(day).date(15);
  const dates = enumerateDaysBetweenDates(start, end);

  if (onlyFirstAndLast) {
    return [dates[0], dates[dates.length - 1]];
  }

  return dates;
};

export const convertDay = (day) => {
  if (day === 0) {
    return 'Sun';
  } else if (day === 1) {
    return 'Mon';
  } else if (day === 2) {
    return 'Tue';
  } else if (day === 3) {
    return 'Wed';
  } else if (day === 4) {
    return 'Thu';
  } else if (day === 5) {
    return 'Fri';
  } else if (day === 6) {
    return 'Sat';
  }
};

const findDataPerDay = ({ entries, daysInRange }) => {
  return daysInRange
    .map((day) => ({
      [moment(day).format('MM/DD/YYYY')]: Number(
        entries
          .filter(
            (entry) => moment(entry.date).format('MM/DD/YYYY') === moment(day).format('MM/DD/YYYY')
          )
          .reduce((tot, curr) => tot + curr.time_spent, 0)
          .toFixed(2)
      )
    }))
    .reduce((tot, curr) => ({ ...tot, ...curr }), {});
};

export const findClientInfo = ({ clients, entries, daysInRange }) => {
  return clients.map((client) => {
    const clientEntries = entries.filter((entry) => entry.client_id === client.id);
    const clientDailyData = findDataPerDay({ entries: clientEntries, daysInRange });
    return {
      id: client.id,
      name: client.name,
      time: Number(clientEntries.reduce((tot, curr) => tot + curr.time_spent, 0).toFixed(2)),
      ...clientDailyData,
      children: client.entities
        .sort((a, b) => {
          if (a.name.localeCompare(b.name)) return a.name.localeCompare(b.name);
          return a.projectName.localeCompare(b.projectName);
        })
        .map((entity) => {
          const entityEntries = clientEntries.filter((entry) => entry.entity_id === entity.id);
          const entityDailyData = findDataPerDay({ entries: entityEntries, daysInRange });
          return {
            id: entity.id,
            name: entity.name,
            time: Number(entityEntries.reduce((tot, curr) => tot + curr.time_spent, 0).toFixed(2)),
            ...entityDailyData,
            children: entity.projects
              .sort((a, b) => {
                if (a.name.localeCompare(b.name)) return a.name.localeCompare(b.name);
                return a.projectName.localeCompare(b.projectName);
              })
              .map((project) => {
                const projectEntries = entityEntries.filter(
                  (entry) => entry.project_id === project.id
                );
                const projectDailyData = findDataPerDay({ entries: projectEntries, daysInRange });
                return {
                  id: project.id,
                  name: project.name,
                  time: Number(
                    projectEntries.reduce((tot, curr) => tot + curr.time_spent, 0).toFixed(2)
                  ),
                  ...projectDailyData
                };
              })
          };
        })
    };
  });
};
