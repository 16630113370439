import React, { useState } from 'react';
import { EditOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  TitledData,
  AvatarCard,
  UniversalDropdown,
  DeleteConfirmationModal
} from './../../../components';
import { currencyFormatter } from './../../../utils/formatters';

const InvoiceCard = ({ entity, history, invoice, deleteFn }) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const menu = [
    {
      title: !deleteFn ? 'Review' : 'Edit',
      icon: <EditOutlined />,
      onClickFn: () => history.push(`/admin/invoices/edit/${invoice.id}`)
    },
    {
      title: 'Delete',
      icon: <DeleteOutlined />,
      onClickFn: () => setDeleteConfirmOpen(true)
    }
  ];

  return (
    <AvatarCard
      name={!!entity ? `${entity.name} | #${invoice.id}` : 'No Name'}
      action={
        <UniversalDropdown
          baseItem={<MoreOutlined style={{ cursor: 'pointer' }} />}
          menu={!deleteFn ? [menu[0]] : menu}
        />
      }
    >
      <TitledData title="Total Time" value={`${invoice.total_time} Hrs`} />
      <TitledData title="Total Amount" value={currencyFormatter.format(invoice.total_amount)} />
      <TitledData title="Number of Entries" value={invoice.entry_ids.length} />
      <TitledData title="Invoice Date" value={moment(invoice.invoice_date).format('MM/DD/YYYY')} />
      <TitledData
        title="Date Range"
        value={`${moment(invoice.start_date).format('MM/DD/YYYY')} - ${moment(
          invoice.end_date
        ).format('MM/DD/YYYY')}`}
      />
      <DeleteConfirmationModal
        visible={deleteConfirmOpen}
        deleteFn={() => deleteFn({ invoiceId: invoice.id, entry_ids: invoice.entry_ids })}
        cancelFn={() => setDeleteConfirmOpen(false)}
        warningMessage="This is irreversible. All work entries will be placed back in the pool of unbilled work. All edits to the work entries will remain."
      />
    </AvatarCard>
  );
};

export default InvoiceCard;
