import React from 'react';
import { Input, InputNumber, Select } from 'antd';

import { TitledData, GridRow } from './..';

const { Option } = Select;

export const UniversalTextArea = ({ title, inputValue, onChange, placeholder, style, ...rest }) => (
  <TitledData
    title={title}
    value={
      <Input.TextArea
        style={style || { minWidth: '300px', width: '100%' }}
        value={inputValue}
        onChange={onChange}
        placeholder={placeholder || ''}
        {...rest}
      />
    }
  />
);

export const UniversalInput = ({ title, inputValue, onChange, placeholder, style, ...rest }) => (
  <TitledData
    title={title}
    value={
      <Input
        style={style || { minWidth: '300px', width: '100%' }}
        value={inputValue}
        onChange={onChange}
        placeholder={placeholder || ''}
        {...rest}
      />
    }
  />
);

export const UniversalDoubleInput = ({
  title,
  inputValue1,
  inputValue2,
  onChange1,
  onChange2,
  placeholder1,
  placeholder2
}) => (
  <TitledData
    title={title}
    value={
      <GridRow padding="0px" margin="0px">
        <Input placeholder={placeholder1} value={inputValue1} onChange={onChange1} />
        <Input placeholder={placeholder2} value={inputValue2} onChange={onChange2} />
      </GridRow>
    }
  />
);

export const UniversalInputNumber = ({
  title,
  inputValue,
  onChange,
  integer,
  currency,
  style,
  ...rest
}) => (
  <TitledData
    title={title}
    value={
      <InputNumber
        style={style || { minWidth: '300px', width: '100%' }}
        formatter={(value) => (currency ? `$${value}` : value)}
        parser={(value) => (currency ? value.replace('$', '') : value)}
        value={inputValue}
        onChange={onChange}
        min={0}
        step={integer ? 1 : 0.01}
        {...rest}
      />
    }
  />
);

export const NonTitledInputNumber = ({
  inputValue,
  onChange,
  integer,
  currency,
  style,
  ...rest
}) => (
  <InputNumber
    style={style || { width: '100%' }}
    formatter={(value) => (currency ? `$${value}` : value)}
    parser={(value) => (currency ? value.replace('$', '') : value)}
    value={inputValue}
    onChange={onChange}
    min={0}
    step={integer ? 1 : 0.01}
    {...rest}
  />
);

export const UniversalSelect = ({
  title,
  onChange,
  selectValue,
  placeholder,
  options,
  style,
  ...rest
}) => {
  return (
    <TitledData
      title={title}
      value={
        <Select
          onChange={onChange}
          style={style || { minWidth: '300px', width: '100%' }}
          value={selectValue}
          placeholder={placeholder || ''}
          {...rest}
        >
          {options &&
            options.map((opt) => (
              <Option key={opt.key} value={opt.value}>
                {opt.name}
              </Option>
            ))}
        </Select>
      }
    />
  );
};
