import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px 20px;
  z-index: 100;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;
