import moment from 'moment';

import { findPayPeriodDates } from './../../utils/moment-utils';

//Actions
export const FETCH_USER_INFO = 'USER/FETCH_USER_INFO';
export const STORE_USER_INFO = 'USER/STORE_USER_INFO';
export const STOP_USER_LOADING = 'USER/STOP_USER_LOADING';

export const FETCH_USER_PAGE_INFO = 'USER/FETCH_USER_PAGE_INFO';
export const START_USER_PAGE_LOADING = 'USER/START_USER_PAGE_LOADING';
export const STOP_USER_PAGE_LOADING = 'USER/STOP_USER__PAGELOADING';

export const UPDATE_SELF = 'USER/UPDATE_SELF';

export const START_MY_TIME_LOADING = 'USER/START_MY_TIME_LOADING';
export const STOP_MY_TIME_LOADING = 'USER/STOP_MY_TIME_LOADING';
export const FETCH_MY_TIME_DETAILS = 'USER/FETCH_MY_TIME_DETAILS';
export const STORE_MY_TIME_DETAILS = 'USER/STORE_MY_TIME_DETAILS';

export const DELETE_ENTRY = 'USER/DELETE_ENTRY';
export const EDIT_ENTRY = 'USER/EDIT_ENTRY';

export const UPDATE_MY_TIME_SELECTED_DATE = 'USER/UPDATE_MY_TIME_SELECTED_DATE';
export const UPDATE_MY_TIME_DATE_RANGE = 'USER/UPDATE_MY_TIME_DATE_RANGE';

//Reducer
const initialState = {
  userId: '',
  first_name: '',
  last_name: '',
  role_id: '',
  organization_id: '',
  link_to_photo: '',
  qbo_id: '',
  type: '',
  myTimeInfo: {
    clientInfo: [],
    countByUser: [],
    entries: []
  },
  goal_daily_hours: 8,
  linked_qbo_item: null,
  loading: true,
  userPageLoading: false,
  myTimeLoading: false,
  myTimeSelectedDate: moment(),
  myTimeDateRange: findPayPeriodDates(moment()),
  sessionExpires: null
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_USER_INFO:
      return {
        ...state,
        loading: true
      };

    case STORE_USER_INFO:
      const { id, ...rest } = action.payload;
      return {
        ...state,
        userId: id,
        userPageLoading: false,
        ...rest
      };

    case STOP_USER_LOADING:
      return {
        ...state,
        loading: false
      };

    case START_USER_PAGE_LOADING:
      return {
        ...state,
        userPageLoading: true
      };

    case STOP_USER_PAGE_LOADING:
      return {
        ...state,
        userPageLoading: false
      };

    case START_MY_TIME_LOADING:
      return {
        ...state,
        myTimeLoading: true
      };

    case STOP_MY_TIME_LOADING:
      return {
        ...state,
        myTimeLoading: false
      };

    case STORE_MY_TIME_DETAILS:
      return {
        ...state,
        myTimeInfo: action.payload,
        myTimeLoading: false
      };

    case UPDATE_MY_TIME_SELECTED_DATE:
      return {
        ...state,
        myTimeSelectedDate: action.payload.date
      };

    case UPDATE_MY_TIME_DATE_RANGE:
      return {
        ...state,
        myTimeDateRange: action.payload.dateRange
      };

    default:
      return state;
  }
};

//Action Creators
export const fetchUserInfoAction = (payload) => ({
  type: FETCH_USER_INFO,
  payload
});

export const storeUserInfo = (payload) => ({
  type: STORE_USER_INFO,
  payload
});

export const stopUserLoading = (payload) => ({
  type: STOP_USER_LOADING,
  payload
});

export const fetchUserPageInfoAction = (payload) => ({
  type: FETCH_USER_PAGE_INFO,
  payload
});

export const startUserPageLoading = (payload) => ({
  type: START_USER_PAGE_LOADING,
  payload
});

export const stopUserPageLoading = (payload) => ({
  type: STOP_USER_PAGE_LOADING,
  payload
});

export const updateSelfAction = (payload) => ({
  type: UPDATE_SELF,
  payload
});

export const startMyTimeLoading = (payload) => ({
  type: START_MY_TIME_LOADING,
  payload
});

export const stopMyTimeLoading = (payload) => ({
  type: STOP_MY_TIME_LOADING,
  payload
});

export const fetchMyTimeInfoAction = (payload) => ({
  type: FETCH_MY_TIME_DETAILS,
  payload
});

export const storeMyTimeInfo = (payload) => ({
  type: STORE_MY_TIME_DETAILS,
  payload
});

export const deleteEntryAction = (payload) => ({
  type: DELETE_ENTRY,
  payload
});

export const editEntryAction = (payload) => ({
  type: EDIT_ENTRY,
  payload
});

export const updateMyTimeSelectedDateAction = (payload) => ({
  type: UPDATE_MY_TIME_SELECTED_DATE,
  payload
});

export const updateMyTimeDateRangeAction = (payload) => ({
  type: UPDATE_MY_TIME_DATE_RANGE,
  payload
});

export default reducer;
