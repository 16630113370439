import React from 'react';

import { PageContainer } from './styled-components';
import { TitleH4 } from './../../components';

const PrivacyPolicy = () => (
  <PageContainer>
    <TitleH4>Privacy Policy</TitleH4>
    <br />

    <p>
      We collect the data you give us, including your email, name, Quickbooks information, company
      information, client, entity, and project names and information, and the work entries and
      associated information. We collect this information for the sole purpose of providing the
      Chronicl service to you, and do not sell or provide this information to any other third
      parties.
    </p>

    <p>
      We use cookies on this site, meaning we store a short alpha-numeric string in your browser, so
      you are able to login to the site and communicate with our data services. Without this cookie
      you are unable to use this site.
    </p>
  </PageContainer>
);

export default PrivacyPolicy;
