import React from 'react';
import { Heading, StyledText, StyledParagraph, StyledTitledData } from './styled-components';

export const TitleH1 = ({ children, ...rest }) => <Heading {...rest}>{children}</Heading>;

export const TitleH2 = ({ children, ...rest }) => (
  <Heading level={2} {...rest}>
    {children}
  </Heading>
);

export const TitleH3 = ({ children, ...rest }) => (
  <Heading level={3} {...rest}>
    {children}
  </Heading>
);

export const TitleH4 = ({ children, ...rest }) => (
  <Heading level={4} {...rest}>
    {children}
  </Heading>
);

export const Text = ({ children, ...rest }) => <StyledText {...rest}>{children}</StyledText>;

export const SecondaryText = ({ children, ...rest }) => (
  <StyledText type="secondary" {...rest}>
    {children}
  </StyledText>
);

export const WarningText = ({ children, ...rest }) => (
  <StyledText type="warning" {...rest}>
    {children}
  </StyledText>
);

export const ErrorText = ({ children, ...rest }) => (
  <StyledText type="danger" {...rest}>
    {children}
  </StyledText>
);

export const DisabledText = ({ children, ...rest }) => (
  <StyledText disabled={true} {...rest}>
    {children}
  </StyledText>
);

export const MarkedText = ({ children, ...rest }) => (
  <StyledText mark {...rest}>
    {children}
  </StyledText>
);

export const CodeText = ({ children, ...rest }) => (
  <StyledText code {...rest}>
    {children}
  </StyledText>
);

export const UnderlinedText = ({ children, ...rest }) => (
  <StyledText underline {...rest}>
    {children}
  </StyledText>
);

export const DeletedText = ({ children, ...rest }) => (
  <StyledText delete {...rest}>
    {children}
  </StyledText>
);

export const StrongText = ({ children, ...rest }) => (
  <StyledText strong {...rest}>
    {children}
  </StyledText>
);

export const EditableText = ({ children, onChange, ...rest }) => (
  <StyledParagraph editable={{ onChange }} {...rest}>
    {children}
  </StyledParagraph>
);

export const TitledData = ({ title, value, code, ...rest }) => (
  <StyledTitledData {...rest}>
    <SecondaryText>{title}</SecondaryText>
    {code ? <CodeText>{value}</CodeText> : value}
  </StyledTitledData>
);
