import React, { useEffect } from 'react';
import { List } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  fetchInvoicesAwaitingApprovalAction,
  deleteInvoiceAction
} from './../../../ducks/invoices';
import {
  ContentContainer,
  FlexRow,
  PageContentContainer,
  TitleH4,
  GridList
} from './../../../components';
import InvoiceCard from './invoiceCard';

const InvoicesAwaitingApproval = ({
  invoices: { loading, invoicesAwaitingApproval },
  clients: { allEntities },
  fetchInvoicesAwaitingApproval,
  deleteInvoice
}) => {
  const history = useHistory();

  useEffect(() => {
    fetchInvoicesAwaitingApproval({});
  }, [fetchInvoicesAwaitingApproval]);

  const onDelete = ({ invoiceId, entry_ids }) => {
    deleteInvoice({
      invoiceId,
      createPage: false,
      history,
      data: {
        entry_ids
      }
    });
  };

  return (
    <ContentContainer loading={loading} loadingTip="Loading Invoice Data...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>Invoices Awaiting Approval</TitleH4>
      </FlexRow>

      <PageContentContainer>
        <GridList
          style={{ marginTop: '20px' }}
          dataSource={invoicesAwaitingApproval}
          renderItem={(invoice) => {
            const entity = allEntities.find((ent) => ent.id === invoice.entity_id);
            return (
              <List.Item>
                <InvoiceCard
                  deleteFn={onDelete}
                  entity={entity}
                  invoice={invoice}
                  history={history}
                />
              </List.Item>
            );
          }}
        />
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ clients, invoices }) => ({
  clients,
  invoices
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoicesAwaitingApproval: (payload) =>
    dispatch(fetchInvoicesAwaitingApprovalAction(payload)),
  deleteInvoice: (payload) => dispatch(deleteInvoiceAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesAwaitingApproval);
