import { takeLatest, call, put } from 'redux-saga/effects';
import moment from 'moment';

import { stopEntriesLoading, startEntriesLoading, CREATE_ENTRY } from './index';
import Api from './../../utils/api-wrapper';
import { fetchMyTimeDetails } from './../user/sagas';
import { findPayPeriodDates } from './../../utils/moment-utils';

export function* createEntry({ payload: { data, startDate, endDate } }) {
  try {
    yield put(startEntriesLoading());
    yield call(Api.createEntry, { data });

    const dateRangeOfEntry = findPayPeriodDates(moment(data.date));
    const startDateOfEntryRange = moment(dateRangeOfEntry[0])
      .startOf('day')
      .utc()
      .format('MM/DD/YYYY');

    if (startDateOfEntryRange === moment(startDate).format('MM/DD/YYYY')) {
      yield call(fetchMyTimeDetails, { payload: { startDate, endDate } });
    }
  } catch (err) {
    yield put(stopEntriesLoading());
    console.log(err);
  }
}

export const entriesSags = [takeLatest(CREATE_ENTRY, createEntry)];

export default entriesSags;
