import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ContentContainer,
  PageContentContainer,
  TitleH4,
  TitledData,
  AvatarCard
} from './../../components';
import { fetchUserPageInfoAction, updateSelfAction } from './../../ducks/user';
import EditUserPopover from './editUserPopover';

const UserSettings = ({ user, fetchUserInfo, updateSelf }) => {
  useEffect(() => {
    fetchUserInfo({ userId: user.userId });
  }, [fetchUserInfo, user.userId]);

  const billingRate = user.rates.filter((rate) => rate.type === 'Billing' && !rate.date_ended)[0];
  const paymentRate = user.rates.filter((rate) => rate.type === 'Payment' && !rate.date_ended)[0];

  return (
    <ContentContainer loading={user.userPageLoading} loadingTip="Loading Settings...">
      <TitleH4>{`${user.first_name} ${user.last_name} Settings`}</TitleH4>

      <PageContentContainer>
        <AvatarCard
          name={`${user.first_name} ${user.last_name} Profile`}
          action={
            <EditUserPopover
              currentFirstName={user.first_name}
              currentLastName={user.last_name}
              currentDailyHoursGoal={user.goal_daily_hours}
              updateSelf={updateSelf}
              userId={user.userId}
            />
          }
          style={{ marginTop: '20px' }}
        >
          <TitledData title="Name" value={`${user.first_name} ${user.last_name}`} />
          <TitledData
            title="Role"
            value={user.role_id === 1 ? 'Super Admin' : user.role_id === 2 ? 'Admin' : 'User'}
          />
          <TitledData title="Employee Type" value={user.type} />
          <TitledData
            title="Billing / Payment Rate"
            value={`$${!!billingRate ? billingRate.amount : 0} / $${
              !!paymentRate ? paymentRate.amount : 0
            }`}
          />
          <TitledData title="Email" value={user.email} />
          <TitledData title="Quickbooks Id" value={user.qbo_id || 'None'} />
          <TitledData title="Daily Hours Goal" value={user.goal_daily_hours || 0} />
        </AvatarCard>
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserInfo: (payload) => dispatch(fetchUserPageInfoAction(payload)),
  updateSelf: (payload) => dispatch(updateSelfAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
