import React from 'react';
import { Dropdown, Menu } from 'antd';

export const UniversalDropdown = ({ menu, baseItem }) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          {menu.map((item, index) => (
            <Menu.Item
              key={`${item.title}-${index}`}
              disabled={item.disabled}
              onClick={item.onClickFn}
            >
              {item.icon} {item.title}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      {baseItem}
    </Dropdown>
  );
};
