import { Typography } from 'antd';
import styled from 'styled-components';

const { Title, Text: AntText, Paragraph } = Typography;

export const Heading = styled(Title)`
  ${({ padding }) => padding && `padding: ${padding}`};
  margin: ${({ margin }) => margin || '0px'} !important;
`;

export const StyledText = styled(AntText)`
  ${({ padding }) => padding && `padding: ${padding}`};
  margin: ${({ margin }) => margin || '0px'} !important;
  ${({ shouldHaveBackground }) => shouldHaveBackground && 'color: #fff'};
`;

export const StyledParagraph = styled(Paragraph)`
  ${({ padding }) => padding && `padding: ${padding}`};
  margin: ${({ margin }) => margin || '0px'} !important;
`;

export const StyledTitledData = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
