import React, { useState, useEffect } from 'react';
import { Popover, Cascader } from 'antd';
import { ClockCircleFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';

import TimerAvatar from './timerAvatar';
import { COLORS } from './../../constants/styles';
import {
  PrimaryButton,
  TitleH4,
  NonTitledInputNumber,
  UniversalTextArea,
  TitledData,
  UniversalDatePicker,
  GridRow
} from './../';
import { createEntryAction } from '../../ducks/entries';

const TimerCore = ({
  startTimer,
  stopTimer,
  id,
  paused,
  deleteFn,
  createEntry,
  user,
  clients: { clients: clientsProp }
}) => {
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [options, setOptions] = useState([]);
  const [client, setClient] = useState({});
  const [entity, setEntity] = useState({});
  const [project, setProject] = useState({});
  const [description, setDescription] = useState('');
  const [timeSpent, setTimeSpent] = useState(0);
  const [timeStarted, setTimeStarted] = useState(null);
  const [date, setDate] = useState(moment());

  useEffect(() => {
    const markedClients = clientsProp
      .filter((cli) => !!cli.entities.length)
      .map((client) => {
        const markedEntities = client.entities
          .filter((ent) => !!ent.projects.length)
          .map((ent) => {
            return {
              id: ent.id,
              name: ent.name,
              children: ent.projects
                .filter((proj) => !proj.retired)
                .sort((a, b) => a.name.localeCompare(b.name))
            };
          });

        return {
          id: client.id,
          name: client.name,
          children: markedEntities.sort((a, b) => a.name.localeCompare(b.name))
        };
      });

    setOptions(
      markedClients
        .filter((cli) => !!cli.children.length)
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [clientsProp]);

  useEffect(() => {
    if (paused && !!timeStarted) {
      const additionalTime = (moment().diff(timeStarted, 'seconds') / 3600).toFixed(2);
      setTimeSpent(Number((timeSpent + Number(additionalTime)).toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paused]);

  const handleHoverChange = (visible) => {
    setHovered(visible);
    setClicked(false);
  };

  const handleClickChange = (visible) => {
    setClicked(visible);
    setHovered(false);
  };

  const selectProject = (val, selectedOptions) => {
    const [selectedClient, selectedEntity, selectedProject] = selectedOptions;
    if (!!selectedClient && !!selectedEntity && !!selectedProject) {
      setClient({ name: selectedClient.name, id: selectedClient.id });
      setEntity({ name: selectedEntity.name, id: selectedEntity.id });
      setProject({ name: selectedProject.name, id: selectedProject.id });
    }
  };

  const startTiming = () => {
    setTimeStarted(moment());
    startTimer({ timerId: id });
  };

  const stopTiming = () => {
    stopTimer({ timerId: id });
  };

  const submitEntry = () => {
    if (project.id && entity.id && client.id) {
      createEntry({
        data: {
          project_id: project.id,
          entity_id: entity.id,
          client_id: client.id,
          user_id: user.userId,
          description,
          time_spent: Number(timeSpent.toFixed(2)),
          date: moment(date).utc().format()
        },
        startDate: moment(user.myTimeDateRange[0]).startOf('day').utc().format(),
        endDate: moment(user.myTimeDateRange[user.myTimeDateRange.length - 1])
          .endOf('day')
          .utc()
          .format()
      });
    }

    deleteFn({ timerId: id });
  };

  const disabledDates = (current) => current >= moment().endOf('day');

  const filter = (inputValue, path) =>
    path.some((option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const canSubmit =
    paused && !!project.id && !!entity.id && !!client.id && !!description && !!timeSpent;

  const hoverContent = project.id
    ? `Timer for ${client.name} / ${entity.name} / ${project.name}`
    : 'Click to add details';
  const clickContent = (
    <div style={{ width: '555px' }}>
      <TitledData
        title="Project"
        value={
          <Cascader
            style={{ width: '100%' }}
            options={options}
            placeholder="Select Project"
            onChange={selectProject}
            fieldNames={{ label: 'name', value: 'id' }}
            showSearch={{ filter }}
          />
        }
      />

      <UniversalDatePicker
        title="Date"
        value={date}
        onChange={setDate}
        format="MM/DD/YYYY"
        disabledDates={disabledDates}
      />

      <UniversalTextArea
        title="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={4}
      />

      <TitledData
        title="Time Spent"
        value={
          <GridRow padding="0px">
            <NonTitledInputNumber
              inputValue={timeSpent}
              onChange={(val) => setTimeSpent(val)}
              min={0}
              step={0.01}
              disabled={!paused}
            />
            <PrimaryButton
              type={paused ? 'secondary' : 'primary'}
              onClick={paused ? startTiming : stopTiming}
            >
              {paused ? 'Start' : 'Stop'}
            </PrimaryButton>
          </GridRow>
        }
      />

      <PrimaryButton onClick={submitEntry} disabled={!canSubmit} block>
        Submit
      </PrimaryButton>
    </div>
  );

  return (
    <Popover
      placement="leftBottom"
      content={hoverContent}
      visible={hovered}
      onVisibleChange={handleHoverChange}
      trigger="hover"
    >
      <Popover
        placement="leftBottom"
        content={clickContent}
        visible={clicked}
        onVisibleChange={handleClickChange}
        title={project.id ? `Timer for ${project.name}` : 'Timer for ...'}
        trigger="click"
        overlayStyle={{ width: '600px' }}
      >
        <TimerAvatar
          paused={paused}
          deleteFn={() => deleteFn({ timerId: id })}
          icon={
            project.id ? (
              <TitleH4 style={{ color: paused ? COLORS.primaryColor : '#fff' }}>
                {project.name.split('')[0].toUpperCase()}
              </TitleH4>
            ) : (
              <ClockCircleFilled
                style={{ fontSize: '26px', color: paused ? `${COLORS.primaryColor}` : '#fff' }}
              />
            )
          }
        />
      </Popover>
    </Popover>
  );
};

const mapStateToProps = ({ clients, user }) => ({
  clients,
  user
});

const mapDispatchToProps = (dispatch) => ({
  createEntry: (payload) => dispatch(createEntryAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TimerCore);
