import React from 'react';
import { DatePicker } from 'antd';

import { TitledData } from './../';

export const UniversalDatePicker = ({
  title,
  value,
  disabledDates,
  onChange,
  style,
  format,
  noTitle,
  ...rest
}) => {
  return noTitle ? (
    <DatePicker
      style={style || { minWidth: '300px', width: '100%' }}
      allowClear={false}
      value={value}
      disabledDate={disabledDates}
      onChange={onChange}
      format={format || 'MM/DD/YYYY'}
      {...rest}
    />
  ) : (
    <TitledData
      title={title}
      value={
        <DatePicker
          style={style || { minWidth: '300px', width: '100%' }}
          allowClear={false}
          value={value}
          disabledDate={disabledDates}
          onChange={onChange}
          format={format || 'MM/DD/YYYY'}
          {...rest}
        />
      }
    />
  );
};
