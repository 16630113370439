//Actions
export const STOP_REPORTS_LOADING = 'REPORTS/STOP_REPORTS_LOADING';
export const START_REPORTS_LOADING = 'REPORTS/START_REPORTS_LOADING';
export const FETCH_PAYROLL_DATA = 'REPORTS/FETCH_PAYROLL_DATA';
export const STORE_PAYROLL_DATA = 'REPORTS/STORE_PAYROLL_DATA';
export const FETCH_MONTHLY_AUDIT_DATA = 'REPORTS/FETCH_MONTHLY_AUDIT_DATA';
export const STORE_MONTHLY_AUDIT_DATA = 'REPORTS/STORE_MONTHLY_AUDIT_DATA';
export const FETCH_USER_DETAIL_DATA = 'REPORTS/FETCH_USER_DETAIL_DATA';
export const STORE_USER_DETAIL_DATA = 'REPORTS/STORE_USER_DETAIL_DATA';

//Reducer
const initialState = {
  payrollData: [],
  monthlyAuditData: [],
  userDetailData: {},
  loading: false
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case START_REPORTS_LOADING:
      return {
        ...state,
        loading: true
      };

    case STOP_REPORTS_LOADING:
      return {
        ...state,
        loading: false
      };

    case STORE_PAYROLL_DATA:
      return {
        ...state,
        payrollData: action.payload.data,
        loading: false
      };

    case STORE_MONTHLY_AUDIT_DATA:
      return {
        ...state,
        monthlyAuditData: action.payload.data,
        loading: false
      };

    case STORE_USER_DETAIL_DATA:
      return {
        ...state,
        userDetailData: action.payload.data,
        loading: false
      };

    default:
      return state;
  }
};

//Action Creators
export const stopReportsLoading = (payload) => ({
  type: STOP_REPORTS_LOADING,
  payload
});

export const startReportsLoading = (payload) => ({
  type: START_REPORTS_LOADING,
  payload
});

export const fetchPayrollDataAction = (payload) => ({
  type: FETCH_PAYROLL_DATA,
  payload
});

export const storePayrollData = (payload) => ({
  type: STORE_PAYROLL_DATA,
  payload
});

export const fetchMonthlyAuditDataAction = (payload) => ({
  type: FETCH_MONTHLY_AUDIT_DATA,
  payload
});

export const storeMonthlyAuditData = (payload) => ({
  type: STORE_MONTHLY_AUDIT_DATA,
  payload
});

export const fetchUserDetailDataAction = (payload) => ({
  type: FETCH_USER_DETAIL_DATA,
  payload
});

export const storeUserDetailData = (payload) => ({
  type: STORE_USER_DETAIL_DATA,
  payload
});

export default reducer;
