import React from 'react';
import moment from 'moment';
import { Select, Tooltip, Menu, Dropdown } from 'antd';
import { QuestionCircleFilled, MoreOutlined } from '@ant-design/icons';

import { currencyFormatter } from './../../../utils/formatters';

const { Option } = Select;

const returnInvoiceColumns = ({
  qboItems,
  qbo_linked,
  updateUser,
  setEntryBeingEdited,
  setEditInvoiceModalVisible,
  updateEntryByProp,
  sent_to_qbo
}) => {
  return [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (text) => moment(text).format('MM/DD/YY')
    },
    {
      title: 'Project',
      key: 'project',
      dataIndex: 'project',
      render: (text, entry) => entry.project.name
    },
    {
      title: 'Employee',
      key: 'employee',
      dataIndex: 'employee',
      render: (text, { userInfo }) =>
        userInfo && userInfo.id
          ? `${userInfo.first_name} ${userInfo.last_name}`
          : 'Deleted Employee'
    },
    {
      title: () => (
        <div>
          Service{' '}
          <Tooltip placement="top" title="Services from Quickbooks.com, link one service per user.">
            <QuestionCircleFilled style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      key: 'service',
      dataIndex: 'service',
      render: (text, { linkedItem, userInfo }) => {
        return qbo_linked ? (
          <Select
            disabled={sent_to_qbo}
            style={{ width: 175 }}
            onChange={(id) =>
              updateUser({
                data: {
                  linked_qbo_item: qboItems.find((item) => item.id === id)
                },
                userId: userInfo.id
              })
            }
            value={linkedItem && linkedItem.name}
            placeholder="Select Item"
          >
            {qboItems &&
              qboItems.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        ) : (
          'Link Quickbooks to Account'
        );
      }
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (text, entry, index) => text
    },
    {
      title: 'Quantity',
      key: 'time_spent',
      dataIndex: 'time_spent',
      render: (text, entry, index) => text
    },
    {
      title: () => (
        <div>
          Rate{' '}
          <Tooltip
            placement="top"
            title="If project has custom rates, those are applied by default. Otherwise, falls back to user billing rate."
          >
            <QuestionCircleFilled style={{ marginLeft: '5px' }} />
          </Tooltip>
        </div>
      ),
      key: 'rate',
      dataIndex: 'rate',
      render: (text, entry, index) => currencyFormatter.format(entry.rate_invoiced_at || 0)
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: (text, entry) => currencyFormatter.format(entry.entry_total || 0)
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      align: 'right',
      render: (text, entry) =>
        !sent_to_qbo ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setEntryBeingEdited(entry);
                    setEditInvoiceModalVisible(true);
                  }}
                  key="1"
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    updateEntryByProp({ id: entry.id, prop: 'removeFromInvoice', value: true })
                  }
                  key="2"
                >
                  <Tooltip
                    placement="left"
                    title="Will remove from this invoice and place back in the pool of unbilled work entries. Takes effect once invoice is saved."
                  >
                    Remove from Invoice
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    updateEntryByProp({ id: entry.id, prop: 'will_not_charge', value: true })
                  }
                  key="3"
                >
                  <Tooltip
                    placement="left"
                    title="Will remove from this invoice but not place back in the pool of unbilled work entries. Takes effect once invoice is saved."
                  >
                    Mark as "Will not charge"
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    updateEntryByProp({ id: entry.id, prop: 'shouldDelete', value: true })
                  }
                  key="4"
                >
                  <Tooltip
                    placement="left"
                    title="Will delete entry from this invoice, all reports, user time, and the invoice pool. This is not recommended. Takes effect once invoice is saved."
                  >
                    Delete Entry Completely
                  </Tooltip>
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined style={{ cursor: 'pointer' }} />
          </Dropdown>
        ) : null
    }
  ];
};

export default returnInvoiceColumns;
