import { takeLatest, call, put } from 'redux-saga/effects';

import {
  stopReportsLoading,
  startReportsLoading,
  FETCH_PAYROLL_DATA,
  FETCH_MONTHLY_AUDIT_DATA,
  FETCH_USER_DETAIL_DATA,
  storePayrollData,
  storeMonthlyAuditData,
  storeUserDetailData
} from './index';
import Api from './../../utils/api-wrapper';
import { findClientInfo } from './../../utils/moment-utils';

export function* fetchPayrollData({ payload: { startDate, endDate } }) {
  try {
    yield put(startReportsLoading());
    const data = yield call(Api.fetchPayrollData, {
      startDate,
      endDate
    });
    yield put(storePayrollData({ data }));
  } catch (err) {
    yield put(stopReportsLoading());
    console.log(err);
  }
}

export function* fetchMonthlyAuditData({ payload: { startDate, endDate } }) {
  try {
    yield put(startReportsLoading());
    const data = yield call(Api.fetchMonthlyAuditData, {
      startDate,
      endDate
    });
    yield put(storeMonthlyAuditData({ data }));
  } catch (err) {
    yield put(stopReportsLoading());
    console.log(err);
  }
}

export function* fetchUserDetailData({ payload: { startDate, endDate, userId } }) {
  try {
    yield put(startReportsLoading());
    const { clients, countByUser, daysInRange, entries } = yield call(Api.fetchUserDetailData, {
      startDate,
      endDate,
      userId
    });

    const clientInfo = findClientInfo({ clients, entries, daysInRange });

    yield put(storeUserDetailData({ data: { clientInfo, countByUser, daysInRange, entries } }));
  } catch (err) {
    yield put(stopReportsLoading());
    console.log(err);
  }
}

export const invoicesSagas = [
  takeLatest(FETCH_PAYROLL_DATA, fetchPayrollData),
  takeLatest(FETCH_MONTHLY_AUDIT_DATA, fetchMonthlyAuditData),
  takeLatest(FETCH_USER_DETAIL_DATA, fetchUserDetailData)
];

export default invoicesSagas;
