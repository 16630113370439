import React, { useEffect, useState } from 'react';
import { DatePicker, Table, List } from 'antd';
import { FolderAddOutlined, FileAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import {
  fetchNewInvoiceInfoAction,
  createInvoicesAction,
  deleteInvoiceAction
} from './../../../ducks/invoices';
import {
  ContentContainer,
  FlexRow,
  PageContentContainer,
  CardNoTitle,
  TitleH4,
  LinkButton,
  GridList
} from './../../../components';
import InvoiceCard from './invoiceCard';

const { RangePicker } = DatePicker;

const CreateInvoices = ({
  invoices: {
    loading,
    newInvoicesInfo: { countByClient, countByEntity },
    inProgressInvoices
  },
  clients: { clients, allEntities },
  fetchNewInvoiceInfo,
  createInvoices,
  deleteInvoice
}) => {
  const history = useHistory();
  const [dateRange, setDateRange] = useState(null);
  const [entityData, setEntityData] = useState([]);
  const [usableData, setUsableData] = useState([]);

  useEffect(() => {
    fetchNewInvoiceInfo({});
    setDateRange(null);
  }, [fetchNewInvoiceInfo]);

  useEffect(() => {
    if (!dateRange) {
      fetchNewInvoiceInfo({});
    } else {
      fetchNewInvoiceInfo({
        startDate: moment(dateRange[0]).startOf('day').utc().format(),
        endDate: moment(dateRange[1]).endOf('day').utc().format()
      });
    }
  }, [dateRange, fetchNewInvoiceInfo]);

  const onDelete = ({ invoiceId, entry_ids }) => {
    deleteInvoice({
      invoiceId,
      createPage: true,
      history,
      data: {
        entry_ids
      },
      startDate: dateRange && moment(dateRange[0]).startOf('day').utc().format(),
      endDate: dateRange && moment(dateRange[1]).endOf('day').utc().format()
    });
  };

  useEffect(() => {
    if (allEntities.length > 0 && countByEntity.length > 0) {
      const data = countByEntity.map((entity) => {
        const otherEntityInfo = allEntities.find((ent) => ent.id === entity.entity_id);
        return {
          id: entity.entity_id,
          total_amount: Number(entity.total_amount.toFixed(2)),
          client_id: otherEntityInfo && otherEntityInfo.client_id,
          name: otherEntityInfo && otherEntityInfo.name,
          startDate: entity.start_date,
          endDate: entity.end_date
        };
      });

      setEntityData(data);
    }
  }, [countByEntity, allEntities]);

  useEffect(() => {
    if (entityData.length > 0 && countByClient.length > 0 && clients.length > 0) {
      const data = countByClient.map((client) => ({
        id: client.client_id,
        total_amount: Number(client.total_amount.toFixed(2)),
        name: clients.find((cli) => cli.id === client.client_id).name,
        children: entityData.filter((ent) => ent.client_id === client.client_id),
        startDate: client.start_date,
        endDate: client.end_date
      }));

      setUsableData(data);
    }
  }, [entityData, countByClient, clients]);

  const onChange = (range) => {
    setDateRange(range);
  };

  const onCreateInvoice = (entityIds) => {
    dateRange
      ? createInvoices({
          entityIds,
          startDate: moment(dateRange[0]).startOf('day').utc().format(),
          endDate: moment(dateRange[1]).endOf('day').utc().format()
        })
      : createInvoices({ entityIds });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Unbilled Hours',
      dataIndex: 'total_amount',
      key: 'total_amount'
    },
    {
      title: 'Date Range',
      dataIndex: 'date_range',
      key: 'date_range',
      render: (text, record) =>
        `${moment(record.startDate).format('MM/DD/YYYY')} - ${moment(record.endDate).format(
          'MM/DD/YYYY'
        )}`
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) =>
        record.client_id ? (
          <LinkButton onClick={() => onCreateInvoice([record.id])} icon={<FileAddOutlined />}>
            Invoice
          </LinkButton>
        ) : (
          <LinkButton
            onClick={() =>
              onCreateInvoice(
                entityData.filter((ent) => ent.client_id === record.id).map((ent) => ent.id)
              )
            }
            icon={<FolderAddOutlined />}
          >
            Invoice All Entities
          </LinkButton>
        )
    }
  ];

  return (
    <ContentContainer loading={loading} loadingTip="Loading Invoice Data...">
      <FlexRow justifyContent="space-between" alignItems="center">
        <TitleH4>Start New Invoice</TitleH4>

        <div>
          <RangePicker value={dateRange} onChange={onChange} format="MM/DD/YYYY" allowClear />
        </div>
      </FlexRow>

      <PageContentContainer>
        <CardNoTitle>
          <Table
            columns={columns}
            pagination={false}
            rowKey={(record) => (record.client_id ? `entity_${record.id}` : `client_${record.id}`)}
            dataSource={usableData.length ? usableData : []}
          />
        </CardNoTitle>

        <FlexRow margin="40px 0px 0px" justifyContent="space-between" alignItems="center">
          <TitleH4>Saved Invoices</TitleH4>
        </FlexRow>

        <GridList
          style={{ marginTop: '20px' }}
          dataSource={inProgressInvoices}
          renderItem={(invoice) => {
            const entity = allEntities.find((ent) => ent.id === invoice.entity_id);
            return (
              <List.Item>
                <InvoiceCard
                  deleteFn={onDelete}
                  entity={entity}
                  invoice={invoice}
                  history={history}
                />
              </List.Item>
            );
          }}
        />
      </PageContentContainer>
    </ContentContainer>
  );
};

const mapStateToProps = ({ company, clients, invoices }) => ({
  company,
  clients,
  invoices
});

const mapDispatchToProps = (dispatch) => ({
  fetchNewInvoiceInfo: (payload) => dispatch(fetchNewInvoiceInfoAction(payload)),
  createInvoices: (payload) => dispatch(createInvoicesAction(payload)),
  deleteInvoice: (payload) => dispatch(deleteInvoiceAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoices);
