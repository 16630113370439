import { takeLatest, call, put } from 'redux-saga/effects';

import {
  stopInvoicesLoading,
  startInvoicesLoading,
  FETCH_NEW_INVOICE_INFO,
  storeNewInvoiceInfo,
  CREATE_INVOICES,
  storeInProgressInvoices,
  FETCH_INVOICE,
  storeInvoiceInfo,
  UPDATE_INVOICE,
  storeInvoicesAwaitingApproval,
  FETCH_INVOICES_AWAITING_APPROVAL,
  FETCH_INVOICES_SENT_TO_QBO,
  storeInvoicesSentToQuickbooks,
  DELETE_INVOICE
} from './index';
import Api from './../../utils/api-wrapper';

export function* fetchNewInvoiceInfo({ payload: { startDate, endDate } }) {
  try {
    yield put(startInvoicesLoading());
    const { countByEntity, countByClient } = yield call(Api.fetchPeriodInfo, {
      startDate,
      endDate
    });
    const { invoices } = yield call(Api.fetchInProgressInvoices);
    yield put(storeNewInvoiceInfo({ countByEntity, countByClient }));
    yield put(storeInProgressInvoices({ newInvoices: invoices }));
  } catch (err) {
    yield put(stopInvoicesLoading());
    console.log(err);
  }
}

export function* fetchInvoicesAwaitingApproval() {
  try {
    yield put(startInvoicesLoading());
    const { invoices } = yield call(Api.fetchInvoicesAwaitingApproval);
    yield put(storeInvoicesAwaitingApproval({ invoices }));
  } catch (err) {
    yield put(stopInvoicesLoading());
    console.log(err);
  }
}

export function* fetchInvoicesSentToQbo() {
  try {
    yield put(startInvoicesLoading());
    const { invoices } = yield call(Api.fetchInvoicesSentToQuickbooks);
    yield put(storeInvoicesSentToQuickbooks({ invoices }));
  } catch (err) {
    yield put(stopInvoicesLoading());
    console.log(err);
  }
}

export function* createInvoices({ payload }) {
  try {
    yield put(startInvoicesLoading());
    yield call(Api.bulkCreateInvoices, { data: payload });
    yield call(fetchNewInvoiceInfo, {
      payload: { startDate: payload.startDate, endDate: payload.endDate }
    });
  } catch (err) {
    yield put(stopInvoicesLoading());
    console.log(err);
  }
}

export function* fetchInvoice({ payload: { invoiceId } }) {
  try {
    yield put(startInvoicesLoading());
    const { invoiceInfo, entries } = yield call(Api.fetchInvoice, { invoiceId });
    yield put(storeInvoiceInfo({ invoiceInfo: { ...invoiceInfo, entries } }));
  } catch (err) {
    yield put(stopInvoicesLoading());
    console.log(err);
  }
}

export function* updateInvoice({
  payload: { invoiceId, data, history, shouldRedirect, ready_for_approval }
}) {
  try {
    yield put(startInvoicesLoading());
    yield call(Api.updateInvoice, { invoiceId, data });
    yield put(stopInvoicesLoading());
    if (shouldRedirect)
      ready_for_approval
        ? history.push('/admin/invoices/awaiting')
        : history.push('/admin/invoices/create');
  } catch (err) {
    yield put(stopInvoicesLoading());
    console.log(err);
  }
}

export function* deleteInvoice({
  payload: { invoiceId, data, createPage, history, startDate, endDate }
}) {
  try {
    yield put(startInvoicesLoading());
    yield call(Api.deleteInvoice, { invoiceId, data });

    if (createPage) {
      yield call(fetchNewInvoiceInfo, {
        payload: { startDate, endDate }
      });
      history.push('/admin/invoices/create');
    } else {
      yield call(fetchInvoicesAwaitingApproval, {});
      history.push('/admin/invoices/awaiting');
    }
  } catch (err) {
    yield put(stopInvoicesLoading());
    console.log(err);
  }
}

export const invoicesSagas = [
  takeLatest(FETCH_NEW_INVOICE_INFO, fetchNewInvoiceInfo),
  takeLatest(CREATE_INVOICES, createInvoices),
  takeLatest(FETCH_INVOICE, fetchInvoice),
  takeLatest(UPDATE_INVOICE, updateInvoice),
  takeLatest(FETCH_INVOICES_AWAITING_APPROVAL, fetchInvoicesAwaitingApproval),
  takeLatest(FETCH_INVOICES_SENT_TO_QBO, fetchInvoicesSentToQbo),
  takeLatest(DELETE_INVOICE, deleteInvoice)
];

export default invoicesSagas;
