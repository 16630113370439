import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import { WarningMessage, UniversalInput, UniversalRadioButton } from '../../../components';

const ClientModal = ({
  client,
  addingNewClient,
  addClientToOrg,
  updateClient,
  onCancel,
  ...rest
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [monthlyFeeEnabled, setMonthlyFeeEnabled] = useState(false);

  useEffect(() => {
    setName(client.name);
    setEmail(client.email);
    setMonthlyFeeEnabled(!!client.monthly_fee);
  }, [client]);

  const data = {
    name,
    email,
    monthly_fee: monthlyFeeEnabled
  };

  const addClientFn = () => {
    addClientToOrg(data);
    onCancel();
  };

  const updateClientFn = () => {
    updateClient({ data, clientId: client.id });
    onCancel();
  };

  const hasBlank = !name;

  const isDifferent =
    name !== client.name || email !== client.email || monthlyFeeEnabled !== client.monthly_fee;

  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailIsGood = !email || emailRegex.test(email);

  const canSubmit = !hasBlank && isDifferent && emailIsGood;

  const editingToNonMonthly =
    !addingNewClient && monthlyFeeEnabled !== client.monthly_fee && !monthlyFeeEnabled;

  return (
    <Modal
      title={addingNewClient ? 'Create New Client' : `Edit ${client.name}`}
      onOk={addingNewClient ? addClientFn : updateClientFn}
      okText={addingNewClient ? 'Add Client' : 'Update Client'}
      onCancel={onCancel}
      okButtonProps={{ disabled: !canSubmit }}
      {...rest}
    >
      <UniversalInput
        title="Name*"
        inputValue={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
      />

      <UniversalInput
        title="Email"
        inputValue={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />

      <UniversalRadioButton
        title="Monthly Fee Enabled"
        radioValue={monthlyFeeEnabled}
        onChange={(e) => setMonthlyFeeEnabled(e.target.value)}
        options={[
          { value: true, name: 'Yes' },
          { value: false, name: 'No' }
        ]}
      />

      {editingToNonMonthly && (
        <WarningMessage message="Current monthly fee will end. For the sake of reporting, all work done on monthly fee projects will be compared against a $0 monthly fee from this point forward. After re-enabling, you will need to update the monthly fee from $0." />
      )}
    </Modal>
  );
};

export default ClientModal;
