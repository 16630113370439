import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';

import { FlexRow, UniversalDrawer } from './../../../components';
import AddMonthlyFeePopover from './addMonthlyFeePopover';
import { updateMonthlyFeeAction, createMonthlyFeeAction } from './../../../ducks/clients';
import { monthlyFeesColumns } from './constants';

const MonthlyFeesDrawer = ({
  client: selectedClient,
  updateMonthlyFee,
  createMonthlyFee,
  ...rest
}) => {
  const [client, setClient] = useState(selectedClient);
  const [monthlyFees, setMonthlyFees] = useState([]);

  useEffect(() => {
    setClient(selectedClient);
    if (selectedClient.monthly_fees) {
      setMonthlyFees(
        selectedClient.monthly_fees.sort((a, b) => moment(b.date_began).diff(a.date_began))
      );
    } else {
      setMonthlyFees([]);
    }
  }, [selectedClient, setMonthlyFees]);

  return (
    <UniversalDrawer
      title={
        <FlexRow padding="0" alignItems="center" justifyContent="space-between">
          <div>{`${client.name} Monthly Fees`}</div>
          <AddMonthlyFeePopover
            clientId={client.id}
            latestMonthlyFee={monthlyFees.find((fee) => fee.date_ended === null)}
            disabled={!client.monthly_fee}
            createMonthlyFee={createMonthlyFee}
          />
        </FlexRow>
      }
      {...rest}
    >
      <Table
        pagination={false}
        rowKey={(record) => record.id}
        columns={monthlyFeesColumns({ client, updateMonthlyFee })}
        dataSource={monthlyFees}
      />
    </UniversalDrawer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateMonthlyFee: (payload) => dispatch(updateMonthlyFeeAction(payload)),
  createMonthlyFee: (payload) => dispatch(createMonthlyFeeAction(payload))
});

export default connect(null, mapDispatchToProps)(MonthlyFeesDrawer);
