import React, { useState, useEffect } from 'react';
import { Cascader, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  SecondaryText,
  Text,
  SecondaryButton,
  TitledData,
  EntryCard,
  UniversalDatePicker,
  UniversalInputNumber,
  UniversalTextArea
} from './../../components';
import { EntryGridRow } from './styled-components';

const Entry = ({
  data: {
    id,
    project_id,
    entity_id,
    client_id,
    description: descriptionProp,
    time_spent,
    date: dateProp,
    invoiced,
    invoice_staged,
    will_not_charge
  },
  clientsProp,
  allProjects,
  startDate,
  endDate,
  editEntry,
  deleteEntry,
  adminPage,
  userId
}) => {
  const [options, setOptions] = useState([]);
  const [client, setClient] = useState({ id: client_id });
  const [entity, setEntity] = useState({ id: entity_id });
  const [project, setProject] = useState({ id: project_id });
  const [description, setDescription] = useState(descriptionProp);
  const [timeSpent, setTimeSpent] = useState(time_spent);
  const [date, setDate] = useState(moment(dateProp));

  useEffect(() => {
    setClient({ id: client_id });
    setEntity({ id: entity_id });
    setProject({ id: project_id });
    setDescription(descriptionProp);
    setTimeSpent(time_spent);
    setDate(moment(dateProp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const markedClients = clientsProp
      .filter((cli) => !!cli.entities.length)
      .map((client) => {
        const markedEntities = client.entities
          .filter((ent) => !!ent.projects.length)
          .map((ent) => {
            return {
              id: ent.id,
              name: ent.name,
              children: ent.projects
                .filter((proj) => !proj.retired)
                .sort((a, b) => a.name.localeCompare(b.name))
            };
          });

        return {
          id: client.id,
          name: client.name,
          children: markedEntities.sort((a, b) => a.name.localeCompare(b.name))
        };
      });

    setOptions(
      markedClients
        .filter((cli) => !!cli.children.length)
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [clientsProp]);

  const selectProject = (val, selectedOptions) => {
    const [selectedClient, selectedEntity, selectedProject] = selectedOptions;
    if (!!selectedClient && !!selectedEntity && !!selectedProject) {
      setClient({ name: selectedClient.name, id: selectedClient.id });
      setEntity({ name: selectedEntity.name, id: selectedEntity.id });
      setProject({ name: selectedProject.name, id: selectedProject.id });
    }
  };

  const filter = (inputValue, path) =>
    path.some((option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const onDelete = () => {
    deleteEntry({ entryId: id, startDate, endDate, adminPage, userId });
  };

  const onCancel = () => {
    setClient({ id: client_id });
    setEntity({ id: entity_id });
    setProject({ id: project_id });
    setDescription(descriptionProp);
    setTimeSpent(time_spent);
    setDate(moment(dateProp));
  };

  const onEdit = () => {
    editEntry({
      entryId: id,
      data: {
        project_id: project.id,
        entity_id: entity.id,
        client_id: client.id,
        description: description,
        time_spent: timeSpent,
        date: moment(date).utc().format()
      },
      startDate,
      endDate,
      adminPage,
      userId
    });
    onCancel();
  };

  const hasEmpty =
    !timeSpent ||
    !date ||
    !description ||
    !project ||
    !project.id ||
    !entity ||
    !entity.id ||
    !client ||
    !client.id;

  const different =
    timeSpent !== time_spent ||
    moment(dateProp).format() !== moment(date).format() ||
    project.id !== project_id ||
    entity.id !== entity_id ||
    client.id !== client_id ||
    description !== descriptionProp;

  const disabledDates = (current) => current >= moment().endOf('day');
  const projInfo = allProjects.find((proj) => proj.id === project.id);
  const projectName = projInfo ? projInfo.name : 'Deleted Project';

  return (
    <EntryCard
      primaryDisabled={hasEmpty || !different}
      onCancel={onCancel}
      onEdit={onEdit}
      action={
        <Popconfirm
          placement="left"
          title="Are you sure?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
        >
          <SecondaryButton>
            <DeleteOutlined />
          </SecondaryButton>
        </Popconfirm>
      }
      style={{ marginTop: '15px' }}
      name={`${projectName} | ${timeSpent} Hrs`}
    >
      <EntryGridRow>
        <TitledData
          title="Project"
          value={
            <Cascader
              value={[client.id, entity.id, project.id]}
              options={options}
              placeholder="Select Project"
              onChange={selectProject}
              fieldNames={{ label: 'name', value: 'id' }}
              style={{
                width: '100%'
              }}
              allowClear={false}
              showSearch={{ filter }}
              disabled={invoiced || invoice_staged || will_not_charge}
            />
          }
        />

        <UniversalDatePicker
          title="Date"
          value={date}
          onChange={setDate}
          disabledDates={disabledDates}
          disabled={invoiced || invoice_staged || will_not_charge}
        />

        <UniversalInputNumber
          title="Time Spent (Hrs)"
          inputValue={timeSpent}
          onChange={(val) => setTimeSpent(val)}
          disabled={invoiced || invoice_staged || will_not_charge}
        />
      </EntryGridRow>

      <UniversalTextArea
        title="Description"
        inputValue={description}
        onChange={(e) => setDescription(e.target.value)}
        rows={3}
        disabled={invoiced || invoice_staged || will_not_charge}
      />

      <SecondaryText>Status: </SecondaryText>
      <Text>
        {invoiced
          ? 'Invoiced'
          : invoice_staged
          ? 'Invoice Staged'
          : will_not_charge
          ? 'Will not charge'
          : 'Open'}
      </Text>
    </EntryCard>
  );
};

export default Entry;
