import React, { useState } from 'react';
import { Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  PrimaryButton,
  SecondaryButton,
  ButtonRow,
  UniversalInputNumber,
  UniversalRadioButton,
  UniversalDatePicker
} from './../../../components';

const AddRatePopover = ({ createRate, employee, billingRates, paymentRates }) => {
  const [ratePopoverVisible, setRatePopoverVisible] = useState(false);
  const [amount, setAmount] = useState('');
  const [dateBegan, setDateBegan] = useState(null);
  const [type, setType] = useState('Billing');

  const cancelAdd = () => {
    setRatePopoverVisible(false);
    setAmount('');
    setDateBegan(null);
    setType('Billing');
  };

  const billingDisabledDates = (current) => {
    if (billingRates[0]) {
      return (
        (current && current < moment(billingRates[0].date_began).endOf('day')) ||
        current >= moment().endOf('day')
      );
    }
  };

  const paymentDisabledDates = (current) => {
    if (paymentRates[0]) {
      return (
        (current && current < moment(paymentRates[0].date_began).endOf('day')) ||
        current >= moment().endOf('day')
      );
    }
  };

  const addRate = () => {
    const data = {
      type,
      user_id: employee.id,
      date_began: moment(dateBegan).utc().format(),
      amount: Number(amount.toFixed(2)),
      previousRateId:
        type === 'Billing'
          ? billingRates[0] && billingRates[0].id
          : paymentRates[0] && paymentRates[0].id
    };
    createRate(data);
    cancelAdd();
  };

  const anyBlank = !amount || !dateBegan || !type;

  return (
    <Popover
      trigger="click"
      visible={ratePopoverVisible}
      onVisibleChange={(visible) => setRatePopoverVisible(visible)}
      title="Add Rate"
      placement="bottomRight"
      content={
        <div>
          <UniversalRadioButton
            title="Type*"
            onChange={(e) => {
              setType(e.target.value);
              setDateBegan(null);
            }}
            radioValue={type}
            options={[
              { value: 'Billing', name: 'Billing' },
              { value: 'Payment', name: 'Payment' }
            ]}
          />
          <UniversalInputNumber
            title="Amount*"
            currency={true}
            onChange={setAmount}
            inputValue={amount}
          />

          <UniversalDatePicker
            title="Starting Date*"
            value={dateBegan && moment(dateBegan)}
            onChange={(date) => date && setDateBegan(date.format())}
            disabledDates={type === 'Billing' ? billingDisabledDates : paymentDisabledDates}
          />

          <ButtonRow>
            <SecondaryButton onClick={cancelAdd} margin="0px 10px 0px 0px">
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={addRate} disabled={anyBlank}>
              Add
            </PrimaryButton>
          </ButtonRow>
        </div>
      }
    >
      <PrimaryButton icon={<PlusOutlined />}>Add Rate</PrimaryButton>
    </Popover>
  );
};

export default AddRatePopover;
